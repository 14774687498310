import React from 'react';

// item => [{label: x, value: y}]
const BestItemsCard = ({ item, index }) => {
    console.log('BestItemsCard:', item);
  return (
    <div
      key={index}
      className="col-md-8 col-sm-6 col-12 d-flex justify-content-center"
      style={{ maxWidth: '200px' }} // Ensure consistent card size
    >
      <div
        className="d-flex justify-content-center align-items-center p-4"
        style={{
          border: '1px solid #D9D5EC',
          borderRadius: '3px',
         
          marginLeft: '20px',

        }}
      >
        <div className="card-body">
          {item.map((data, idx) => (
            <h5 key={idx} style={{ fontSize: '13px', color: '#6F6F6F' }}>
              {data.label}
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {' : ' + data.value}
              </span>
            </h5>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestItemsCard;
