import React from "react";
import { Card } from "antd";
const AramexInsightCard = ({ title, value, iconPath, color, fillColor }) => {
  return (
    <Card
      className="text-center rounded "
      style={{ backgroundColor: fillColor, borderColor: color, width: "350px" }}
    >
      <img src={iconPath} alt="icon" className="my-3 " />
      <h5 className=" m-0" style={{ fontSize: "40px", color }}>
        {value}
      </h5>
      <p style={{ fontSize: "20px", color }}>{title}</p>
    </Card>
  );
};

export default AramexInsightCard;
