import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { Space, Button, Dropdown, Menu,Table, Input, Checkbox } from 'antd';
import {
  Card,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {getAdsFacebook} from '../../store/facebookAds/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

function MetaAdsFacebook(props) {
  const dispatch = useDispatch();
  const { ads } = useSelector((state) => state.facebookAds);
  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(props.dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(props.dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: props.dateRange.selection1.key,
    },
  };

  useEffect(() => {
   
     
      dispatch(getAdsFacebook( formattedDateRange.selection1.startDate, formattedDateRange.selection1.endDate) )
   
  }, [ props.dateRange]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // Utility function to format numbers with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const columns = [
    {
        title: props.t('AD IMAGE'),
        dataIndex: 'image_url',
        key: 'image_url',
        align: 'center',
        width: 150,
        filteredValue: filteredInfo.image_url || null,
        // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        fixed: 'left',
        render: (text, record) => {
          return (
            <div className="d-flex align-items-center">
              <img
                src={text}
                alt="Ad Image"
                style={{ width: '100', height: '100px' }}
              />
            </div>
          );
        },

    },
   
    {
        title: props.t('AD NAME'),
        dataIndex: 'ad_name',
        key: 'ad_name',
        align: 'center',
        width: 100,
       
        filteredValue: filteredInfo.ad_name || null,
        onFilter: (value, record) => record.ad_name.includes(value),
        ...getColumnSearchProps('ad_name'),
        sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
       

        
    },
    {
      title: props.t('CAMPAIGN NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 100,
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
     
    },
    {
      title: props.t('SPEND'),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      width: 100,
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
     
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title:  props.t('REVENEUE'),
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
        width: 100,
      align: 'center',
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('CONVERSIONS'), 
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      width: 120,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
        width: 120,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('CLICKS'),
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      width: 100,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('REACH'),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    // {
    //   title: 'CTR',
    //   dataIndex: 'ctr',
    //   align: 'center',
    //   key: 'ctr',
    //   sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
    //   sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
    //   width: 100,
    //   render: (text, record) => {
    //     const floatValue = parseFloat(text);
    //     return <strong>{isNaN(floatValue) ? '-' : `%${floatValue.toFixed(2)}`}</strong>;
    //   },
     
    // },
  
    // {
    //   title: 'CPC',
    //   dataIndex: 'cpc',
    //   key: 'cpc',
    //   align: 'center',
    //   sorter: (a, b) => a.cpc - b.cpc,
    //   sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
    //   width: 100,
    //   render: (text, record) => {
    //     const floatValue = parseFloat(text);
    //     return <strong>{isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}</strong>;
    //   },
    // },
    // {
    //   title: props.t('landing PageView'),
    //   dataIndex: 'landing_page_views',
    //   align: 'center',
    //   key: 'landing_page_views',
    //   sorter: (a, b) => a.landing_page_views - b.landing_page_views,
    //   sortOrder:sortedInfo.columnKey === 'landing_page_views' ? sortedInfo.order : null,
    //       width: 100,
    //   render: (text) => <strong>{text}</strong>,
    // },
    // {
    //     title:props.t('post engagement'),
    //     dataIndex:'post_engagement',
    //     align:'center',
    //     key:'post_engagement',
    //     sorter:(a,b)=>a.post_engagement-b.post_engagement,
    //     sortOrder:sortedInfo.columnKey==='post_engagement'?sortedInfo.order:null,
    //     width: 100,
    //     render:(text)=><strong>{text}</strong>

    // },
    // {
    //     title:props.t('Post reaction'),
    //     dataIndex:'post_reaction',
    //     align:'center',
    //     key:'post_reaction',
    //     sorter:(a,b)=>a.post_reaction-b.post_reaction,
    //     sortOrder:sortedInfo.columnKey==='post_reaction'?sortedInfo.order:null,
    //     width: 100,
    //     render:(text)=><strong>{text}</strong>
    // },
    // { 
    //     title:props.t('Page engagement'),
    //     dataIndex:'page_engagement',
    //     align:'center',
    //     key:'page_engagement',
    //     sorter:(a,b)=>a.page_engagement-b.page_engagement,
    //     sortOrder:sortedInfo.columnKey==='page_engagement'?sortedInfo.order:null,
    //     width: 100,
    //     render:(text)=><strong>{text}</strong>
    // },
    // {
    //   title: props.t('Engagement Rate') ,
    //   dataIndex: 'engagement_rate',
    //   align: 'center',
    //   key: 'engagement_rate',
    //   sorter: (a, b) => ((a.post_engagement / a.impressions) * 100) - ((b.post_engagement / b.impressions) * 100),
    //   sortOrder: sortedInfo.columnKey === 'engagement_rate' ? sortedInfo.order : null,
    //   width: 120,
    //   render: (_, record) => {
    //     const engagementRate = (record.post_engagement / record.impressions) * 100;
    //     return <strong>{engagementRate ? engagementRate.toFixed(2) : '-'}</strong>;
    //   },
    // },
    {
      title: props.t('ROAS'),
      dataIndex: 'roas',
      align: 'center',
      key: 'roas',
      sorter: (a, b) => (a.action_values / a.spend) - (b.action_values / b.spend),
      sortOrder: sortedInfo.columnKey === 'roas' ? sortedInfo.order : null,
      width: 100,
      render: (_, record) => {
        const roas = record.action_values / record.spend;
        return <strong>{roas !== null ? roas.toFixed(2) : '-'}</strong>;
      },
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };
  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox
          checked={isAllSelected}
          style={{ marginRight: '8px' }}
        />
        <span style={{ color: 'black' }}>
           All
        </span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="mt-5">
     
     <Breadcrumbs
              image={FacebbokAdsIcon}
              title="Meta Ads Facebook"
            />
      
      <Card style={{ border: 'none' }}>
           
           
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className='custom-button filter-button' >{props.t("Select Metrics")} < DownOutlined/></Button>
                </Dropdown>
                <Button  className='custom-button filter-button' onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                <Button className='custom-button filter-button' onClick={clearFilters}>{props.t("Clear filters")}</Button>
                <Button className='custom-button filter-button'  onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                
              </Space>
              <div className='table-border'>
              <div className="d-flex align-items-center" style={{padding:"20px"}}>
                <div className="search-box" style={{ flex: 1, maxWidth: '523px', position: 'relative', backgroundColor: '#F4F2FF', borderRadius: '6px', padding: '10px 15px' }}>
                  <Input 
                    placeholder={props.t("Ad Name")}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ paddingLeft: '35px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                  />
                  <i className="bx bx-search-alt search-icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#7a7a7a' }} />
                </div>
                <Link to="/MetaAds" className="btn btn-primary btn-sm viewMetrics-button">
                  {props.t('View full metrics')}
                </Link>
              </div>
              <Table
                columns={filteredColumns}
                
                dataSource={ads}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                  style: { backgroundColor: '#f3f4f6', padding: '10px 15px', marginTop: '2px', borderRadius: '6px' }, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings

                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px' ,
                fontweight: '700',
                color: '#6E6893',
                  },
                }}
                // scroll={{ x: 900, y: 600}}

                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}
              />
              
              </div>
            
          </Card>
        
        
        
    </div>
  );
}

MetaAdsFacebook.propTypes = {
  t: PropTypes.any,
};

export default (withTranslation()(MetaAdsFacebook));
