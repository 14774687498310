import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  calculateGrowthPercentage,
  formatNumberWithSpaces,
  getExchangeRate,
} from "../GoogleAnalytics/common/HelperFunctions/utils";
import StoreOverviewCard from "./StoreOverviewCard";

function StoreOverview({ data }) {
  console.log("data", data);
  const [googleRevenueConversionRate, setGoogleRevenueConversionRate] =
    useState(1);
  const [facebookRevenueConversionRate, setFacebookRevenueConversionRate] =
    useState(1);
  const [googleSpendConversionRate, setGoogleSpendConversionRate] = useState(1);
  const [facebookSpendConversionRate, setFacebookSpendConversionRate] =
    useState(1);

  const googleRevenueCurrency =
    data?.GoogleMetrics?.[0]?.revenue_currency_code || "";
  const googleSpendCurrency =
    data?.GoogleMetrics?.[0]?.spend_currency_code || "";
  const facebookRevenueCurrency =
    data?.facebookMetrics?.[0]?.revenue_currency_code || "";
  const facebookSpendCurrency =
    data?.facebookMetrics?.[0]?.spend_currency_code || "";
  const storeCurrency = data?.store?.[0]?.currency || "";

  const googleCost = data?.GoogleMetrics?.[0]?.totalCost || 0;
  const facebookCost = data?.facebookMetrics?.[0]?.totalCost || 0;

  useEffect(() => {
    const fetchConversionRates = async () => {
      try {
        const storeExchangeRate = await getExchangeRate(storeCurrency);

        const googleRevRate = storeExchangeRate[googleRevenueCurrency];

        const facebookRevRate = storeExchangeRate[facebookRevenueCurrency];

        const googleSpendRate = storeExchangeRate[googleSpendCurrency];

        const facebookSpendRate = storeExchangeRate[facebookSpendCurrency];

        setGoogleRevenueConversionRate(googleRevRate);
        setFacebookRevenueConversionRate(facebookRevRate);
        setGoogleSpendConversionRate(googleSpendRate);
        setFacebookSpendConversionRate(facebookSpendRate);
      } catch (error) {
        console.error("Failed to fetch conversion rates", error);
      }
    };

    fetchConversionRates();
  }, [
    googleRevenueCurrency,
    facebookRevenueCurrency,
    googleSpendCurrency,
    facebookSpendCurrency,
    storeCurrency,
  ]);

  const Globalspend =
    googleCost / googleSpendConversionRate +
    facebookCost / facebookSpendConversionRate;

  const googleCostCompare = data?.GoogleMetrics?.[1]?.totalCost || 0;
  const facebookCostCompare = data?.facebookMetrics?.[1]?.totalCost || 0;
  const GlobalspendCompare =
    googleCostCompare / googleSpendConversionRate +
    facebookCostCompare / facebookSpendConversionRate;

  const googleRevenue = data?.GoogleMetrics?.[0]?.totalconversionValue || 0;
  const facebookRevenue = data?.facebookMetrics?.[0]?.totalconversionValue || 0;
  const globalRevenue =
    googleRevenue / googleRevenueConversionRate +
    facebookRevenue / facebookRevenueConversionRate;

  const googleRevenueCompare =
    data?.GoogleMetrics?.[1]?.totalconversionValue || 0;
  const facebookRevenueCompare =
    data?.facebookMetrics?.[1]?.totalconversionValue || 0;
  const globalRevenueCompare =
    googleRevenueCompare / googleRevenueConversionRate +
    facebookRevenueCompare / facebookRevenueConversionRate;

  const MER = Globalspend ? data?.store?.[0]?.total_sales / Globalspend : 0;
  const MERCompare = GlobalspendCompare
    ? data?.store?.[1]?.total_sales / GlobalspendCompare
    : 0;

  const cards = [
    {
      title: "Total Sales",
      data: data?.store?.[0]?.total_sales
        ? formatNumberWithSpaces(data?.store?.[0]?.total_sales?.toFixed(2)) +
          " " +
          storeCurrency
        : "0 " + storeCurrency,
      percentage: calculateGrowthPercentage(
        data?.store?.[0]?.total_sales,
        data?.store?.[1]?.total_sales
      ),
    },
    {
      title: "Average Order Value",
      data: formatNumberWithSpaces(data?.store?.[0]?.aov?.toFixed(2)),
      percentage: calculateGrowthPercentage(
        data?.store?.[0]?.aov,
        data?.store?.[1]?.aov
      ),
    },
    {
      title: "Total Orders",
      data: formatNumberWithSpaces(data?.store?.[0]?.total_orders),
      percentage: calculateGrowthPercentage(
        data?.store?.[0]?.total_orders,
        data?.store?.[1]?.total_orders
      ),
    },
    {
      title: "MER",
      data: MER.toFixed(2),
      percentage: calculateGrowthPercentage(MER, MERCompare),
    },
    {
      title: "Total Ads Spend",
      data:
        formatNumberWithSpaces(Globalspend.toFixed(2)) + " " + storeCurrency,
      percentage: calculateGrowthPercentage(Globalspend, GlobalspendCompare),
    },
    {
      title: "Total Ads Revenue",
      data:
        formatNumberWithSpaces(globalRevenue.toFixed(2)) + " " + storeCurrency,
      percentage: calculateGrowthPercentage(
        globalRevenue,
        globalRevenueCompare
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs title="Overall Store Overview" />
      <Col>
        <Row className="justify-content-center">
          {cards.map((card, index) => (
            <StoreOverviewCard
              key={index}
              title={card.title}
              data={card.data}
              percentage={card.percentage}
              deliveredOrders={data?.store?.[0]?.delivred_orders}
            />
          ))}
        </Row>
        
      </Col>
    </>
  );
}

StoreOverview.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withTranslation()(StoreOverview);
