import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchActiveAccounts, getOverviewReport } from '../../../queries/Reports/Overviewreport';
import urls from '../../../routes/apiUrls';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import {
  GET_OVERVIEW_REPORT,
  getOverviewDataSuccess,
  getOverviewDataFailure,
  GET_ACTIVE_ACCOUNTS_REQUEST,
  getActiveAccountsSuccess,
} from './action';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.report,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});
function* getOverview(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(ApploCLI.query, {
      query: getOverviewReport,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.generalreport) {
      yield put(getOverviewDataSuccess(data.generalreport));
    } else {
      yield put(getOverviewDataFailure('error'));
    }
  } catch (err) {
    yield put(getOverviewDataFailure(err));
  }
}

function* getActiveAccounts(action){
  try{const { data } = yield call(ApploCLI.query, {
      query: fetchActiveAccounts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.getActiveAccounts) {
      yield put(getActiveAccountsSuccess(data.getActiveAccounts));
    } 
  } catch (err) {
    yield put(getOverviewDataFailure(err));
  }
}

export default function* OverviewSaga() {
  yield takeLatest(GET_OVERVIEW_REPORT, getOverview);
  yield takeLatest(GET_ACTIVE_ACCOUNTS_REQUEST,getActiveAccounts)
}
