import React from "react"

import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';


function AllOrders(props){

  return (
    <React.Fragment>
   
    </React.Fragment>
  )
}
AllOrders.propTypes = {
  t: PropTypes.any
};
export default (withTranslation() (AllOrders));
//export default AllOrders
