import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import PrintStepper from './PrintStepper';
import BrandImage from './BrandImage';
import IntegrationsSelection from './IntegrationsSelection';
import Recommandation from './Recommandation';

const PrintModal = ({download}) => {

    const steps = [
      {
        title: 'Brand Image',
        content: BrandImage(),
      },
      {
        title: 'Integrations',
        content: IntegrationsSelection(),
      },
      {
        title: 'Recommandation',
        content: Recommandation(),
      },
    ];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      setCurrent(0);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Button type="primary" className="download-button" onClick={showModal}>
        Print
      </Button>
      <Modal
        centered
        width={{
          xs: '90%',
          sm: '80%',
          md: '70%',
          lg: '60%',
          xl: '50%',
          xxl: '40%',
        }}
        open={open}
        title="Print Report"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div style={{ marginTop: 24 }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  download();
                  message.success('Processing complete!');
                  handleOk();
                }}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>,
        ]}
      >
        <PrintStepper current={current} setCurrent={setCurrent} steps={steps} />
      </Modal>
    </>
  );
};

export default PrintModal;
