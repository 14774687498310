import gql from "graphql-tag";

const GetTrackingNumbers = gql`
  query {
    GetTrackingNumbers {
      id
    }
  }
`;
const GetTrackingStatus = gql`
  query {
    getTrackingStatus {
      id
      AccountNumber
      trackingNumber
    }
  }
`;

const getall = gql`
  query {
    getAramexData {
      id
      trackingNumber
      orderId
      state
      updateCode
    }
  }
`;
const checkSales = gql`
  query {
    get {
      id
      type
      isDeleted
      active
      UserName
      Password
      AccountNumber
      AccountPin
      AccountEntity
      AccountCountryCode
    }
  }
`;
const getAmarexSousAccount = gql`
  query {
    getAramexShops {
      id
      AccountNumber
      AccountPin
      AccountEntity
      AccountCountryCode
      UserName
      Password
      active
    }
  }
`;
const deleteAramexIntegration = gql`
  mutation {
    deleteAramexAccount {
      isDeleted
    }
  }
`;
const toggleAramexShop = gql`
  mutation ($AccountNumber: String!) {
    toggelAramexAccount(AccountNumber: $AccountNumber) {
      active
    }
  }
`;

const addingAramexAccount = gql`
  mutation (
    $UserName: String!
    $Password: String!
    $AccountNumber: String!
    $AccountPin: String!
    $AccountEntity: String!
    $AccountCountryCode: String!
  ) {
    addAramexAccount(
      UserName: $UserName
      Password: $Password
      AccountNumber: $AccountNumber
      AccountPin: $AccountPin
      AccountEntity: $AccountEntity
      AccountCountryCode: $AccountCountryCode
    ) {
      UserName
      Password
      AccountNumber
      AccountPin
      AccountEntity
      AccountCountryCode
    }
  }
`;
export {
  addingAramexAccount,
  checkSales,
  getAmarexSousAccount,
  toggleAramexShop,
  deleteAramexIntegration,
  getall,
  GetTrackingNumbers,
  GetTrackingStatus,
};
