import React, { useEffect, useState } from "react";
import { FloatButton, Spin } from "antd";
import { Row, Col, Container } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import FacebookAdsOverview from "./FacebookAdsOverview";
import GoogleAdsOverview from "./GoogleAdsOverview";
import FacebookpageOverview from "./FacebookpageOverview";
import StoreOverview from "./StoreOverview";
import {
  getComparedOverviewDataRequest,
  getOverviewDataRequest,
} from "../../store/reports/overview/action";
import GenralOverviewCard from "./genralOverviewCard";
import MetaAdsFacebook from "./Ads-creative-meta";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import InstagrampageOverview from "./InstagrampageOverview";
import { formatDate } from "../GoogleAnalytics/common/HelperFunctions/utils";
import { format } from "date-fns";
import PrintModal from "../../components/Common/PrintModel/PrintModal";
import ActiveAccountsModal from "./ActiveAccountsModal";
import {  QuestionCircleOutlined } from "@ant-design/icons";
function OverviewDashboard(props) {
  const dispatch = useDispatch();
  const { integrations, recommandation, image } = useSelector(
    (state) => state.printReducer
  );
  const { data, loading } = useSelector((state) => state.overview);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const getIntegrationByTitle = (title) => {
    return integrations.find((integration) => integration.title === title);
  };

  const [cards, setCards] = useState([
    {
      id: 1,
      component: getIntegrationByTitle("store").checked ? (
        <StoreOverview data={data} />
      ) : null,
    },
    {
      id: 2,
      component: getIntegrationByTitle("store").checked ? (
        <GenralOverviewCard data={data} />
      ) : null,
    },
    {
      id: 3,
      component: getIntegrationByTitle("Meta Ads").checked ? (
        <FacebookAdsOverview facebook={data.facebookMetrics} />
      ) : null,
    },
    {
      id: 4,
      component: getIntegrationByTitle("Meta Ads").checked ? (
        <MetaAdsFacebook dateRange={dateRange} />
      ) : null,
    },
    {
      id: 5,
      component: getIntegrationByTitle("Facebook Social Media").checked ? (
        <FacebookpageOverview dateRange={dateRange} />
      ) : null,
    },
    {
      id: 6,
      component: getIntegrationByTitle("Google Ads").checked ? (
        <GoogleAdsOverview google={data.GoogleMetrics} dateRange={dateRange} />
      ) : null,
    },
    {
      id: 7,
      component: getIntegrationByTitle("Instagram Social Media").checked ? (
        <InstagrampageOverview dateRange={dateRange} />
      ) : null,
    },
  ]);
  useEffect(() => {
    setCards([
      {
        id: 1,
        component: getIntegrationByTitle("store").checked ? (
          <StoreOverview data={data} />
        ) : null,
      },
      {
        id: 2,
        component: getIntegrationByTitle("store").checked ? (
          <GenralOverviewCard data={data} />
        ) : null,
      },
      {
        id: 3,
        component: getIntegrationByTitle("Meta Ads").checked ? (
          <FacebookAdsOverview facebook={data.facebookMetrics} />
        ) : null,
      },
      {
        id: 4,
        component: getIntegrationByTitle("Meta Ads").checked ? (
          <MetaAdsFacebook dateRange={dateRange} />
        ) : null,
      },
      {
        id: 5,
        component: getIntegrationByTitle("Facebook Social Media").checked ? (
          <FacebookpageOverview dateRange={dateRange} />
        ) : null,
      },
      {
        id: 6,
        component: getIntegrationByTitle("Google Ads").checked ? (
          <GoogleAdsOverview
            google={data.GoogleMetrics}
            dateRange={dateRange}
          />
        ) : null,
      },
      {
        id: 7,
        component: getIntegrationByTitle("Instagram Social Media").checked ? (
          <InstagrampageOverview dateRange={dateRange} />
        ) : null,
      },
    ]);
  }, [data, dateRange, integrations]);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        dispatch(
          getComparedOverviewDataRequest(
            formatDate(dateRange.selection1.startDate),
            formatDate(dateRange.selection1.endDate),
            formatDate(dateRange.selection2.startDate),
            formatDate(dateRange.selection2.endDate)
          )
        );
      } else
        dispatch(
          getOverviewDataRequest(
            formatDate(dateRange.selection1.startDate),
            formatDate(dateRange.selection1.endDate)
          )
        );
    }
  }, [dateRange]);

  const [downloadloading, setdownloadloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const exportToPDF = async () => {
    try {
      setdownloadloading(true);

      const elementsToHide = document.querySelectorAll(
        ".ant-pagination, .table-pagination, .download-button, .viewMetrics-button, .filter-button"
      );
      elementsToHide.forEach((el) => {
        el.style.display = "none";
      });

      const capture = document.querySelector(".print");
      if (!capture) {
        console.error("The .print element is not found!");
        return;
      }

      const canvas = await html2canvas(capture, {
        useCORS: true,
        scale: 1,
      });

      const imgData = canvas.toDataURL("image/png", 0.8);

      const pxToMm = (px) => px * 0.264583;
      const inputWidthInMm = pxToMm(canvas.width);
      const inputHeightInMm = pxToMm(canvas.height);

      let recommendationTextHeightInMm = 0;
      const leftMargin = 20;
      const rightMargin = 20;

      if (recommandation && recommandation.trim() !== "") {
        const tempDoc = new jsPDF({
          unit: "mm",
          format: "a4",
        });

        tempDoc.setFontSize(16);

        const maxTextWidthInMm = inputWidthInMm - (leftMargin + rightMargin);

        const textLines = tempDoc.splitTextToSize(
          recommandation,
          maxTextWidthInMm
        );

        const lineHeight = 7;

        const headerSpace = 10;
        const gapBetween = 5;

        recommendationTextHeightInMm =
          textLines.length * lineHeight + headerSpace + gapBetween;

        const extraBottomMargin = 10;
        recommendationTextHeightInMm += extraBottomMargin;
      }

      let finalPageHeightInMm = inputHeightInMm;
      if (recommendationTextHeightInMm > 0) {
        finalPageHeightInMm += recommendationTextHeightInMm;
      }

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [inputWidthInMm, finalPageHeightInMm],
      });

      const padding = 10;
      const usableWidth = inputWidthInMm - padding * 2;
      const usableHeight = inputHeightInMm - padding * 2;

      doc.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        usableWidth,
        usableHeight,
        "",
        "SLOW"
      );

      const dateRange = JSON.parse(localStorage.getItem("dateRange"));
      if (
        dateRange &&
        dateRange.selection1 &&
        dateRange.selection1.startDate &&
        dateRange.selection1.endDate
      ) {
        const startDate = format(
          new Date(dateRange.selection1.startDate),
          "MMM dd, yyyy"
        );
        const endDate = format(
          new Date(dateRange.selection1.endDate),
          "MMM dd, yyyy"
        );
        const dateRangeText = `${startDate} - ${endDate}`;

        doc.setFontSize(9);
        doc.setTextColor(0, 0, 0);

        doc.text(dateRangeText, inputWidthInMm - 5 - padding, padding + 5, {
          align: "right",
        });
      } else {
        console.warn("Date range is not valid or missing in localStorage.");
      }
      const imageWidth = 60;
      const imageHeight = 25;
      const x = (inputWidthInMm - imageWidth) / 2;
      const y = 10;

      doc.addImage(image, "PNG", x, y, imageWidth, imageHeight);
      if (recommandation && recommandation.trim() !== "") {
        let currentY = padding + usableHeight;

        const gapBeforeRec = 5;
        currentY += gapBeforeRec;
        doc.setFont("Dm Sans", "normal");
        doc.setFontSize(18);
        doc.text("Recommandation:", leftMargin, currentY);
        currentY += 10;

        doc.setFontSize(16);
        const maxTextWidthInMm = inputWidthInMm - (leftMargin + rightMargin);

        const textLines = doc.splitTextToSize(recommandation, maxTextWidthInMm);

        doc.text(textLines, leftMargin, currentY, { lineHeightFactor: 1.2 });
      }

      doc.save("Rapport-Overview.pdf");
    } catch (error) {
      console.error("Error exporting to PDF:", error);
    } finally {
      const elementsToRestore = document.querySelectorAll(
        ".ant-pagination, .table-pagination, .download-button, .viewMetrics-button, .filter-button"
      );
      elementsToRestore.forEach((el) => {
        el.style.display = "";
      });
      setdownloadloading(false);
    }
  };

  const loadingSection = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Spin size="large" />
    </div>
  );

  return (
    <React.Fragment>
      <div className="page-content print">
        <title>
          {props.t("Overview Dashboard")} | LiveMetrics - E-com SaaS
        </title>
        <Container fluid className="px-4 py-0">
          {loading ? (
            loadingSection()
          ) : (
            <Row className="mb-4">
              <Col lg={12}>
                {cards.map(
                  (card, index) =>
                    card.component && ( // Check if the component exists before rendering
                      <div key={card.id}>{card.component}</div>
                    )
                )}
              </Col>
            </Row>
          )}
          <PrintModal download={exportToPDF} />
          <FloatButton
            icon={<QuestionCircleOutlined />}
            type="primary"
            onClick={() => setIsModalOpen(true)}
          />
          <ActiveAccountsModal
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
OverviewDashboard.propTypes = {
  t: PropTypes.any,
};
export default withTranslation()(OverviewDashboard);
