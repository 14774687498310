import PropTypes from "prop-types"
import React from "react"
//redux
import { useDispatch } from "react-redux"

const Logout = () => {
  const dispatch = useDispatch()
  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default (Logout)
