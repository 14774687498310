import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { logoMap } from '../../assets/integrations_images/logoMap';
import Tooltip from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/Tooltip';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const GeneralOverviewHorizontalCard = (props) => {
  return (
    <Col>
      <Card
        className="card-h-100"
        style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
      >
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <Tooltip
              displayed={
                <span
                  className="my-0 text-primary"
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#0044BD',
                  }}
                >
                  {props.t(props.item.title)}
                </span>
              }
              tooltip={tooltipMap[props.item.title]?.tooltip}
              formule={tooltipMap[props.item.title]?.formula}
            />
          </div>
          <Row className="mt-2 align-items-center">
            <Col xs={12} className="mt-3">
              <h3
                style={{
                  fontWeight: '700',
                  color: '#000000',
                  fontSize: '24px',
                }}
              >
                {props.item.data} 
              </h3>
            </Col>
            <Col xs={12} className="d-flex justify-content-between align-items-center" style={{ width: '50%' }}>
              <div className="d-flex align-items-center">
                <img
                  className="img-intgration-icon me-2"
                  src={logoMap['Facebook Ads']}
                  alt="Facebook Ads"
                  style={{ width: '15px', height: '15px' }}
                />
                <span
                  style={{
                    fontWeight: '500',
                    color: '#000000',
                    fontSize: '16px',
                  }}
                >
                  {props.item.meta} 
                </span>
              </div>
              <div className="d-flex align-items-center">
                <img
                  className="img-intgration-icon me-2"
                  src={logoMap['Google Ads']}
                  alt="Google Ads"
                  style={{ width: '15px', height: '15px' }}
                />
                <span
                  style={{
                    fontWeight: '400',
                    color: '#000000',
                    fontSize: '16px',
                  }}
                >
                  {props.item.google}
                </span>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GeneralOverviewHorizontalCard;
