import PropTypes from 'prop-types';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { userRoutes, authRoutes } from './routes/allRoutes';
import AuthExpirationMiddleware from './routes/middleware/Authmiddleware';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import store from './store/index';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import urls from './routes/apiUrls';
import Error404 from './pages/Utility/Error404';
import { google } from './config';
import { GoogleOAuthProvider } from '@react-oauth/google';

const client = new ApolloClient({
  link: new HttpLink({
    uri: urls.login,
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <GoogleOAuthProvider clientId={google.GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Routes>
            {authRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={<AuthExpirationMiddleware layout={NonAuthLayout} element={route.element} />}
              />
            ))}
            {userRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={<AuthExpirationMiddleware layout={VerticalLayout} element={route.element} isAuthProtected />}
              />
            ))}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </ApolloProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
