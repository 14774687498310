import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getGAAffiliationsFailure,
  getGAAffiliationsSuccess,
  getGAAudienceFailure,
  getGAAudienceSuccess,
  getGAPlatformFailure,
  getGAPlatformSuccess,
  getGAPurchaseJourneyFailure,
  getGAPurchaseJourneySuccess,
  getGATrafficAcquisitionFailure,
  getGATrafficAcquisitionSuccess,
  getGAUsersFailure,
  getGAUsersSuccess,
  getGoogleAnalyticsAccountsFailure,
  getGoogleAnalyticsAccountsSuccess,
  getGoogleAnalyticsProductsFailure,
  getGoogleAnalyticsProductsSuccess,
  integrateGoogleAnalyticsFailure,
  integrateGoogleAnalyticsSuccess,
} from './actions';
import {
  GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
  GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
  GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
  GET_GOOGLE_ANALYTICS_USERS_REQUEST,
  INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
} from './actionsTypes';
import {
  getAccounts,
  getGAAudience,
  getGAEcommerceAffiliation,
  getGAPlatform,
  getGAUsers,
  getSoldProducts,
  integrate,
  getGATrafficAcquisition,
  getGAPurchaseJourney,
} from '../../queries/GoogleAnalytics/Queries';
import urls from '../../routes/apiUrls';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const googleAnalyticsApolloCli = new ApolloClient({
  link: new HttpLink({
    uri: urls.analytics,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});
//INTEGRATE GOOGLE ANALYTICS
function* integrateGoogleAnalytics(action) {
  try {
    const { data } = yield call(googleAnalyticsApolloCli.mutate, {
      mutation: integrate,
      variables: {
        code: action.payload.code,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    console.log('integrate googele analytics code', data);
    if (data.integration === null) {
      yield put(integrateGoogleAnalyticsSuccess(data.integration));
      console.log('we did it ');
    }
  } catch (error) {
    yield put(integrateGoogleAnalyticsFailure(error.message));
  }
}

function* getGoogleAnalyticsAccounts(action) {
  try {
    const { data } = yield call(googleAnalyticsApolloCli.mutate, {
      mutation: getAccounts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    console.log(' google analytics accounts', data);
    if (data.accounts) {
      yield put(getGoogleAnalyticsAccountsSuccess(data.accounts));
    }
  } catch (error) {
    yield put(getGoogleAnalyticsAccountsFailure(error.message));
  }
}

function* getGoogleAnalyticsProducts(action) {
  try {
    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getSoldProducts,
      variables: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    console.log('analytics products ', data);
    if (data.gaSoldProducts) {
      yield put(getGoogleAnalyticsProductsSuccess(data.gaSoldProducts));
    }
  } catch (error) {
    yield put(getGoogleAnalyticsProductsFailure(error.message));
  }
}
function* getGoogleAnalyticsUsers(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }

    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGAUsers,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (data.gausers) {
      yield put(getGAUsersSuccess(data.gausers));
    }
  } catch (error) {
    yield put(getGAUsersFailure(error.message));
  }
}

function* getGoogleAnalyticsPlatform(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }

    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGAPlatform,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.gaPlatform) {
      yield put(getGAPlatformSuccess(data.gaPlatform));
    }
  } catch (error) {
    yield put(getGAPlatformFailure(error.message));
  }
}

function* getGoogleAnalyticsAudience(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGAAudience,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.gaaudiences) {
      yield put(getGAAudienceSuccess(data.gaaudiences));
    }
  } catch (error) {
    yield put(getGAAudienceFailure(error.message));
  }
}

function* getGoogleAnalyticsAffiliations(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }

    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGAEcommerceAffiliation,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.gaecommerceaffiliations) {
      yield put(getGAAffiliationsSuccess(data.gaecommerceaffiliations));
    }
  } catch (error) {
    yield put(getGAAffiliationsFailure(error.message));
  }
}

function* getGoogleAnalyticsTrafficAcquisition(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGATrafficAcquisition,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.gaTrafficAcquisition) {
      yield put(getGATrafficAcquisitionSuccess(data.gaTrafficAcquisition));
    }
  } catch (error) {
    yield put(getGATrafficAcquisitionFailure(error.message));
  }
}

function* getGoogleAnalyticsPurchaseJourney(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }

    const { data } = yield call(googleAnalyticsApolloCli.query, {
      query: getGAPurchaseJourney,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.gaPurchaseJourney) {
      yield put(getGAPurchaseJourneySuccess(data.gaPurchaseJourney));
    }
  } catch (error) {
    yield put(getGAPurchaseJourneyFailure(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(INTEGRATE_GOOGLE_ANALYTICS_REQUEST, integrateGoogleAnalytics);
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST,
    getGoogleAnalyticsProducts
  );
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
    getGoogleAnalyticsPlatform
  );
  yield takeEvery(GET_GOOGLE_ANALYTICS_USERS_REQUEST, getGoogleAnalyticsUsers);
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST,
    getGoogleAnalyticsAccounts
  );
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
    getGoogleAnalyticsAudience
  );
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
    getGoogleAnalyticsAffiliations
  );
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
    getGoogleAnalyticsTrafficAcquisition
  );
  yield takeEvery(
    GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
    getGoogleAnalyticsPurchaseJourney
  );
}
