import GoogleAdsIcon from './GoogleAdsLogo.svg';
import FacebookAdsIcon from './MetaLogo.svg';
import GoogleAnalyticsIcon from './googleAnalytics.svg';
import FbSocialMediaIcon from './facebook.svg';
import InstagramLogo from './insta.svg';
import MagentoIntegrationLogo from './magento-1-1-cropped.png';
import MagentoLogo from './Magento.png';
import AramexLogo from './aramex.png';
import PrestaShopLogo from '../sidebar_icons/presta.svg';
import AramexS from './aramex_small_logo.svg';
export const logoMap = {
  "Google Ads": GoogleAdsIcon,
  "Facebook Ads": FacebookAdsIcon,
  "Google Analytics": GoogleAnalyticsIcon,
  "Facebook Social Media": FbSocialMediaIcon,
  "Instagram Social Media": InstagramLogo,
  "Magento Integration": MagentoIntegrationLogo,
  "Magento": MagentoLogo,
  "Aramex Integration": AramexLogo,
  "Aramex": AramexS,
  "PrestaShop": PrestaShopLogo,
};
