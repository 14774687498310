export const FETCH_MAGENTO_STORE_REQUEST = 'FETCH_MAGENTO_STORE_REQUEST';
export const FETCH_MAGENTO_STORE_SUCCESS = 'FETCH_MAGENTO_STORE_SUCCESS';
export const FETCH_MAGENTO_STORE_FAILURE = 'FETCH_MAGENTO_STORE_FAILURE';

//delete integration magento
export const DELETE_INTEGRATION_MAGENTO_REQUEST =
  'DELETE_INTEGRATION_MAGENTO_REQUEST';
export const DELETE_INTEGRATION_MAGENTO_SUCCESS =
  'DELETE_INTEGRATION_MAGENTO_SUCCESS';
export const DELETE_INTEGRATION_MAGENTO_FAILURE =
  'DELETE_INTEGRATION_MAGENTO_FAILURE';

// integration magento
export const INTEGRATION_MAGENTO_REQUEST = 'INTEGRATION_MAGENTO_REQUEST';
export const INTEGRATION_MAGENTO_SUCCESS = 'INTEGRATION_MAGENTO_SUCCESS';
export const INTEGRATION_MAGENTO_FAILURE = 'INTEGRATION_MAGENTO_FAILURE';

// check third step magento
export const CHECK_THIRD_STEP_MAGENTO_REQUEST =
  'CHECK_THIRD_STEP_MAGENTO_REQUEST';
export const CHECK_THIRD_STEP_MAGENTO_SUCCESS =
  'CHECK_THIRD_STEP_MAGENTO_SUCCESS';
export const CHECK_THIRD_STEP_MAGENTO_FAILURE =
  'CHECK_THIRD_STEP_MAGENTO_FAILURE';

export const GET_TOTAL_PRODUCTS_COUNT_REQUEST =
  'GET_TOTAL_PRODUCTS_COUNT_REQUEST';
export const GET_TOTAL_PRODUCTS_COUNT_SUCCESS =
  'GET_TOTAL_PRODUCTS_COUNT_SUCCESS';
export const GET_TOTAL_PRODUCTS_COUNT_FAILURE =
  'GET_TOTAL_PRODUCTS_COUNT_FAILURE';

export const FETCH_MAGENTO_PRODUCTS_REQUEST = 'FETCH_MAGENTO_PRODUCTS_REQUEST';
export const FETCH_MAGENTO_PRODUCTS_SUCCESS = 'FETCH_MAGENTO_PRODUCTS_SUCCESS';
export const FETCH_MAGENTO_PRODUCTS_FAILURE = 'FETCH_MAGENTO_PRODUCTS_FAILURE';

export const FETCH_MAGENTO_ORDERS_REQUEST = 'FETCH_MAGENTO_ORDERS_REQUEST';
export const FETCH_MAGENTO_ORDERS_SUCCESS = 'FETCH_MAGENTO_ORDERS_SUCCESS';
export const FETCH_MAGENTO_ORDERS_FAILURE = 'FETCH_MAGENTO_ORDERS_FAILURE';

export const FETCH_MAGENTO_BEST_PRODUCTS_REQUEST =
  'FETCH_MAGENTO_BEST_PRODUCTS_REQUEST';
export const FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS =
  'FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS';
export const FETCH_MAGENTO_BEST_PRODUCTS_FAILURE =
  'FETCH_MAGENTO_BEST_PRODUCTS_FAILURE';
