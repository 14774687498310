
export const SET_IMAGE = 'SET_IMAGE';
export const SET_RECOMMANDATION = 'SET_RECOMMANDATION';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
// Define action creators
export const setImage = (image) => ({
  type: SET_IMAGE,
  payload: image,
});

export const setRecommandation = (recommandation) => ({
  type: SET_RECOMMANDATION,
  payload: recommandation,
});

export const setIntegrations = (integrations) => ({
  type: SET_INTEGRATIONS,
  payload: integrations,
});
