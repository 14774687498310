import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getMetricsByCampaignsPerDate } from '../../store/googleAds/actions';
import {
  calculateGrowthPercentage,
  formatNumberWithSpaces,
  getExchangeRateDuo,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCompaignTable from './GoogleAdsCompaignTable';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomisableMetricCards from './CustomisableMetricCards';
 import CampaignTable from '../GoogleDashboard/campaignTable';


const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;

const GoogleAdsOverview = (props, t) => {
  // format number with spaces

  const dispatch = useDispatch();
  const formattedDateRange = {
    selection1: {
      startDate: format(
        new Date(props.dateRange.selection1.startDate),
        'yyyy-MM-dd'
      ),
      endDate: format(
        new Date(props.dateRange.selection1.endDate),
        'yyyy-MM-dd'
      ),
      key: props.dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getMetricsByCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [props.dateRange]);

  const [conversionRate, setConversionRate] = useState(1);
  useEffect(() => {
    const fetchConversionRates = async () => {
      const rate = await getExchangeRateDuo(
        props?.google?.[0]?.spend_currency_code,
        props?.google?.[0]?.revenue_currency_code
      );
      setConversionRate(rate);
    };
    if (
      props?.google?.[0]?.spend_currency_code &&
      props?.google?.[0]?.revenue_currency_code
    )
      fetchConversionRates();
  }, [
    props?.google?.[0]?.spend_currency_code,
    props?.google?.[0]?.revenue_currency_code,
  ]);
  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value:
        props.google &&
        props.google?.[0]?.totalCost != null
          ? `${
              props?.google?.[0]?.spend_currency_code
            } ${formatNumberWithSpaces(
              Number(props?.google?.[0]?.totalCost).toFixed(2)
            )}`
          : `${props?.google?.[0]?.spend_currency_code} 0`,
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalCost,
        props?.google?.[1]?.totalCost
      ),
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props?.google?.[0]?.totalconversionValue != null
          ? `${
              props.google?.[0]?.revenue_currency_code
            } ${formatNumberWithSpaces(
              Number(props.google?.[0]?.totalconversionValue).toFixed(2)
            )}`
          : `${props.google?.[0]?.revenue_currency_code} 0`,
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalconversionValue,
        props?.google?.[1]?.totalconversionValue
      ),
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalConversions != null
          ? Number(props.google[0].totalConversions).toFixed(2)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalConversions,
        props?.google?.[1]?.totalConversions
      ),
    },
    {
      key: 'CPA',
      label: 'CPA',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalCPA != null
          ? `$${formatNumberWithSpaces(
              Number(props.google[0].totalCPA).toFixed(2)
            )}`
          : '$0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalCPA,
        props?.google?.[1]?.totalCPA
      ),
    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalImpressions != null
          ? formatNumberWithSpaces(props.google[0].totalImpressions)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalImpressions,
        props?.google?.[1]?.totalImpressions
      ),
    },
    {
      key: 'Interactions',
      label: 'Interactions',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalinteractions != null
          ? formatNumberWithSpaces(props.google[0].totalinteractions)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalinteractions,
        props?.google?.[1]?.totalinteractions
      ),
    },
    {
      key: 'Clicks',
      label: 'Clicks',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalClicks != null
          ? formatNumberWithSpaces(props.google[0].totalClicks)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalClicks,
        props?.google?.[1]?.totalClicks
      ),
    },
    {
      key: 'CTR',
      label: 'CTR',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalctr != null
          ? `${Number(props.google[0].totalctr).toFixed(2)}%`
          : '0%',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalctr,
        props?.google?.[1]?.totalctr
      ),
    },
    {
      key: 'CPC',
      label: 'CPC',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalCPC != null
          ? `$${formatNumberWithSpaces(
              Number(props.google[0].totalCPC).toFixed(2)
            )}`
          : '$0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalCPC,
        props?.google?.[1]?.totalCPC
      ),
    },
    {
      key: 'AOV',
      label: 'AOV',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalAOV != null
          ? `$${formatNumberWithSpaces(
              Number(props.google[0].totalAOV).toFixed(2)
            )}`
          : '$0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalAOV,
        props?.google?.[1]?.totalAOV
      ),
    },
    {
      key: 'CR',
      label: 'CR',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalCVR != null
          ? `${Number(props.google[0].totalCVR).toFixed(2)}%`
          : '0%',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalCVR,
        props?.google?.[1]?.totalCVR
      ),
    },
    {
      key: 'ROAS',
      label: 'ROAS',
      value:
        props?.google &&
        props?.google.length > 0 &&
        props.google[0]?.totalROAS != null
          ? `x ${Number(props.google[0].totalROAS).toFixed(2)}`
          : '0',
      percentage: calculateGrowthPercentage(
        props?.google?.[0]?.totalROAS,
        props?.google?.[1]?.totalROAS
      ),
    },
  ];

  return (
    <div className="mt-5">
      <Col xl={12}>
        <Breadcrumbs image={GoogleAdsIcon} title="Google Ads performance" />

        <CustomisableMetricCards metricsOption={optionGroup} />
        <Row>
          <CampaignTable overview={true} />
        </Row>
      </Col>
    </div>
  );
};

GoogleAdsOverview.propTypes = {
  t: PropTypes.any,
};

export default (withTranslation()(GoogleAdsOverview));
