export const    GET_OVERVIEW_REPORT = 'GET_OVERVIEW_REPORT';
export const   GET_OVERVIEW_REPORT_SUCCESS = 'GET_OVERVIEW_REPORT_SUCCESS';
export const   GET_OVERVIEW_REPORT_FAILURE = 'GET_OVERVIEW_REPORT_FAILURE';
export const  GET_ACTIVE_ACCOUNTS_REQUEST = 'GET_ACTIVE_ACCOUNTS_REQUEST';
export const  GET_ACTIVE_ACCOUNTS_SUCCESS = 'GET_ACTIVE_ACCOUNTS_SUCCESS';
export const  GET_ACTIVE_ACCOUNTS_FAILRE = 'GET_ACTIVE_ACCOUNTS_FAILRE';

export const getOverviewDataRequest = (startDate,endDate) => ({
    type: GET_OVERVIEW_REPORT,
    payload: {startDate,endDate},
    
})

export const getComparedOverviewDataRequest = (startDate, endDate,compareStartDate,compareEndDate) => ({
  type: GET_OVERVIEW_REPORT,
  payload: { startDate, endDate ,compareStartDate,compareEndDate},
});

export const getOverviewDataSuccess = (data) => ({
    type: GET_OVERVIEW_REPORT_SUCCESS,
    payload: {data}
})

export const getOverviewDataFailure = (error) => ({
    type: GET_OVERVIEW_REPORT_FAILURE,
    payload: error,
})

export const getActiveAccountsRequest = () => ({
  type: GET_ACTIVE_ACCOUNTS_REQUEST,
});

export const getActiveAccountsSuccess = (data) => ({
  type: GET_ACTIVE_ACCOUNTS_SUCCESS,
  payload: { data },
});
export const getActiveAccountsFailure = (error) => ({
  type: GET_ACTIVE_ACCOUNTS_FAILRE,
  payload: error,
});