import gql from 'graphql-tag';

const generateUrl = gql`
  query {
    url {
      url
    }
  }
`;
const getGooglePlatform = gql`
  query {
    get {
      id
      name
      google_id
      type
      active
    }
  }
`;
const getAds = gql`
  query {
    getads {
      ad_group_id
      ad_group_name
      ad_id
      status
      impressions
      date
      clicks
      cost
      ctr
      conversions
      conversions_value
      interactions
      average_cost
      average_cpc
      campaign_id
      campaign_name
    }
  }
`;
const getcmetrcisbyCompaignperspecifcdate = gql`
  query ($startDate: String!, $endDate: String!) {
    getmetrcisofcampaignPerspecifdate(
      startDate: $startDate
      endDate: $endDate
    ) {
      campaign_id
      campaign_name
      status
      metrics {
        ctr
        cpc
        cpa
        totalClicks
        totalCost
        totalConversions
        totalImpressions
        totalInteractions
        totalNewSessions
        totalSearchImpressionShare
        totalConversionsValue
        totalAverageCost
        totalAverageCpc
        averageCtr
        ROAs
        conversionRate
        AOV
      }
    }
  }
`;
const getcmetrcisbyCompaign = gql`
  query {
    getmetrcisbycampaign {
      campaign_id
      campaign_name
      status
      metrics {
        totalClicks
        totalCost
        totalConversions
        totalImpressions
        totalInteractions
        totalNewSessions
        totalSearchImpressionShare
        totalConversionsValue
        totalAverageCost
        totalAverageCpc
        averageCtr
        ROAs
        conversionRate
        AOV
        ctr
        cpc
        cpa
      }
    }
  }
`;
const getAccounts = gql`
  query {
    accounts {
      id
      customer_id
      customer_name
      customer_currency_code
      revenue_currency_code
      active
    }
  }
`;

const getGADSCurrency = gql`
  query {
    getCurrency {
      spend
      revenue
    }
  }
`;

const integrate = gql`
  mutation ($code: String!) {
    integration(code: $code) {
       id
      customer_id
      customer_name
      customer_currency_code
      revenue_currency_code
      active
    }
  }
`;

const addCurrencyRequest = gql`
  mutation ($currency: String!) {
    addCurrency(currency: $currency) {
      revenue
    }
  }
`;

const toggleGoogle = gql`
  mutation ($adAccount: String!) {
    toggle(adAccount: $adAccount) {
      active
      revenue_currency_code
      customer_currency_code
    }
  }
`;
const getkeywords = gql`
  query {
    getkeywords {
      campaign_name
      keyword_text
      conversions
      cost
      clicks
      impressions
      conversions_value
    }
  }
`;
const deleteGoogle = gql`
  mutation {
    delete {
      isDeleted
    }
  }
`;
export {
  generateUrl,
  getGooglePlatform,
  integrate,
  getAccounts,
  toggleGoogle,
  deleteGoogle,
  getcmetrcisbyCompaign,
  getAds,
  getcmetrcisbyCompaignperspecifcdate,
  getkeywords,
  addCurrencyRequest,
  getGADSCurrency,
};
