import { Button, Space, Table, Menu, Dropdown, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const InstagramTable = ({
  columns,
  data,
  setSortedInfo,
  overview = true,
  pageSize = 5,
  filter = true,
  loading,
}) => {
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize, // Default page size
    pageSizeOptions: ["5", "10", "20"],
  });

  // All column keys for selection logic.
  const allColumnKeys = columns.map((column) => column.key);
  const [selectedColumns, setSelectedColumns] = useState(allColumnKeys);

  // Filter states for media types.
  const [statusFilter, setStatusFilter] = useState({
    IMAGE: false,
    VIDEO: false,
    CAROUSEL_ALBUM: false,
  });

  // Separate fixed and non-fixed columns.
  const fixedColumns = columns.filter((column) => column.fixed);
  const nonFixedColumns = columns.filter((column) => !column.fixed);

  // First, filter non-fixed columns based on selected column keys.
  let filteredNonFixedColumns = nonFixedColumns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  // Then, if any media type filter is active, further filter columns based on their "category" property.
  if (statusFilter.IMAGE || statusFilter.VIDEO || statusFilter.CAROUSEL_ALBUM) {
    filteredNonFixedColumns = filteredNonFixedColumns.filter((column) => {
      if (Array.isArray(column.category)) {
        return Object.keys(statusFilter).some(
          (status) => statusFilter[status] && column.category.includes(status)
        );
      }
      return true;
    });
  }

  // Optionally, always include the last column (e.g., actions column) if not already included.
  const lastColumn = columns[columns.length - 1];
  const isLastColumnIncluded = fixedColumns
    .concat(filteredNonFixedColumns)
    .some((col) => col.key === lastColumn.key);
  const filteredColumns = isLastColumnIncluded
    ? [...fixedColumns, ...filteredNonFixedColumns]
    : [...fixedColumns, ...filteredNonFixedColumns, lastColumn];

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearFilters = () => {
    setFilteredData(data);
    setStatusFilter({ IMAGE: false, VIDEO: false, CAROUSEL_ALBUM: false });
  };

  const clearAll = () => {
    setSortedInfo({});
    setSelectedColumns(allColumnKeys);
    clearFilters();
  };

  const setimpressionsSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "impressions",
    });
  };

  const handleMenuClick = (clickedKey) => {
    let updatedSelectedColumns = [];
    if (selectedColumns.includes(clickedKey)) {
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    } else {
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    }
    setSelectedColumns(updatedSelectedColumns);
  };

  const handleSelectAll = () => {
    if (selectedColumns.length === allColumnKeys.length) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(allColumnKeys);
    }
  };

  const isAllSelected = selectedColumns.length === allColumnKeys.length;

  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox checked={isAllSelected} style={{ marginRight: "8px" }} />
        <span style={{ color: "black" }}>All</span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: "8px" }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: "bold" } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const updatedFilter = { ...statusFilter, [name]: checked };
    setStatusFilter(updatedFilter);

    // Filter the data rows based on selected media types.
    const selectedStatuses = Object.keys(updatedFilter).filter(
      (key) => updatedFilter[key]
    );
    const filteredDataByStatus = data.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.media_type)
        : true
    );
    setFilteredData(filteredDataByStatus);
  };

  const Toolbar = ({ marginBottom = "16px" }) => (
    <Space style={{ marginBottom }}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className="custom-button filter-button">
          {i18next.t("Select Metrics")} <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        className="custom-button filter-button"
        onClick={setimpressionsSort}
      >
        {i18next.t("Sort Impressions")}
      </Button>
      <Button className="custom-button filter-button" onClick={clearAll}>
        {i18next.t("Clear filters and sorters")}
      </Button>
    </Space>
  );

  const menufilter = (
    <Menu>
      <Menu.Item key="IMAGE">
        <Checkbox
          name="IMAGE"
          checked={statusFilter.IMAGE}
          onChange={handleCheckboxChange}
        >
          IMAGE
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="VIDEO">
        <Checkbox
          name="VIDEO"
          checked={statusFilter.VIDEO}
          onChange={handleCheckboxChange}
        >
          VIDEO
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="CAROUSEL_ALBUM">
        <Checkbox
          name="CAROUSEL_ALBUM"
          checked={statusFilter.CAROUSEL_ALBUM}
          onChange={handleCheckboxChange}
        >
          CAROUSEL ALBUM
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {filter && <Toolbar />}
      <div className="table-border">
        <div className="d-flex align-items-center" style={{ padding: "20px" }}>
          <Dropdown overlay={menufilter} trigger={["click"]}>
            {filter ? (
              <Button
                className="custom-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "22px",
                  backgroundColor: "inherit",
                  color: "#25213B",
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: "bold",
                  borderColor: "#D9D5EC",
                }}
              >
                <FeatherIcon
                  icon="filter"
                  size="26"
                  style={{ marginRight: "8px" }}
                  fill="#8B83BA"
                  color="#8B83BA"
                />
                {i18next.t("Filter")}
              </Button>
            ) : (
              <Toolbar marginBottom="0px" />
            )}
          </Dropdown>

          {overview && (
            <Link
              to="/InstagramDashboard"
              className="btn btn-primary btn-sm viewMetrics-button"
            >
              {i18next.t("View full metrics")}
            </Link>
          )}
        </div>
        <Table
          loading={loading}
          columns={filteredColumns}
          dataSource={filteredData}
          onChange={handleChange}
          pagination={{
            ...paginationOptions,
            onChange: handleChange,
            showSizeChanger: true,
            style: {
              backgroundColor: "#F4F2FF",
              padding: "10px 15px",
              marginTop: "2px",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "700",
              color: "#6E6893",
            },
          }}
        />
      </div>
    </div>
  );
};

export default InstagramTable;
