import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchProductsSuccess,
  fetchProductsFailure,
  fetchRefundsSuccess,
  fetchRefundsFailure,
  deleteIntegrationPrestaShopSuccess,
  deleteIntegrationPrestaShopFailure,
  integrationPrestaShopSuccess,
  integrationPrestaShopFailure,
  fetchStoresSuccess,
  fetchStoresFailure,
  fetchStoreSuccess,
  fetchStoreFailure,
  checkThirdStepPrestaShopSuccess,
  checkThirdStepPrestaShopFailure,
  fetchPrestaShopDataSuccess,
  fetchPrestaShopDataFailure,
} from './actions';
import Swal from 'sweetalert2';
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_REFUNDS_REQUEST,
  DELETE_INTEGRATION_PRESTASHOP_REQUEST,
  INTEGRATION_PRESTASHOP_REQUEST,
  FETCH_STORES_REQUEST,
  FETCH_STORE_REQUEST,
  CHECK_THIRD_STEP_PRESTASHOP_REQUEST,
  FETCH_PRESTASHOP_DATA_REQUEST,
} from './actionsTypes';
import {
  getPrestashopStore,
  integratePresta,
  deleteIntegration,
  getPrestashopStores,
} from '../../queries/PrestaShop/queries';
import { getData } from '../../queries/Dashboard/prestaDashboard';
import { getProducts } from '../../queries/ProductsQueries/queries';
import { checkSales } from '../../queries/Shopify/queries';
import { getRefunds } from '../../queries/PrestaShop/refunds';
import { successAlert } from '../../pages/Alerts/NotLinkedAccountAlert';
import urls from '../../routes/apiUrls';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const AppoloCliCheckSales = new ApolloClient({
  link: new HttpLink({
    uri: urls.shopify,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const products = new ApolloClient({
  link: new HttpLink({
    uri: urls.product,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const refunds = new ApolloClient({
  link: new HttpLink({
    uri: urls.prestarefund,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.prestashop,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const ApploCLIPRESTA = new ApolloClient({
  link: new HttpLink({
    uri: urls.prestadashboard,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function* fetchProducts(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(products.query, {
      query: getProducts,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    localStorage.setItem('products', JSON.stringify(data.all));
    yield put(fetchProductsSuccess(data.all));
  } catch (error) {
    yield put(fetchProductsFailure(error.message));
  }
}

function* fetchRefunds(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(refunds.query, {
      query: getRefunds,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    localStorage.setItem('refunds', JSON.stringify(data.all));
    yield put(fetchRefundsSuccess(data.all));
  } catch (error) {
    yield put(fetchRefundsFailure(error.message));
  }
}

function* deleteIntegrationPrestaShop() {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: deleteIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    yield put(deleteIntegrationPrestaShopSuccess('deleted done successfully'));
    successAlert();
  } catch (error) {
    yield put(deleteIntegrationPrestaShopFailure(error.message));
  }
}

function* integrationPrestaShop(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: integratePresta,
      variables: {
        api: action.payload.api,
        domain: action.payload.domain,
        protocol: action.payload.protocol,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
    });
    if (data.integration) {
      yield put(integrationPrestaShopSuccess(data.integration));
      window.location.replace('/integrations');
    } else {
      yield put(integrationPrestaShopFailure('data  of store is not found'));
    }
  } catch (error) {
    yield put(integrationPrestaShopFailure(error.message));
  }
}

function* fetchAllPrestaStores(action) {
  try {
    const { data } = yield call(ApploCLI.query, {
      query: getPrestashopStores,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
    });
    if (data.getStores && data.getStores.length > 0) {
      yield put(fetchStoresSuccess(data.getStores));
    } else {
      yield put(fetchStoresFailure('data  of store is not found'));
    }
  } catch (error) {
    yield put(fetchStoresFailure(error.message));
  }
}
function* fetchStore(action) {
  try {
    const { data } = yield call(ApploCLI.query, {
      query: getPrestashopStore,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    if (data.get) {
      yield put(fetchStoreSuccess(data.get));
      if (data.get.connectionstate === false) {
        Swal.fire({
          title: 'oops',
          text: 'please check your check your Prestashop credentials or contact  our support team ',
          icon: 'warning',
          showCancelButton: false,
        }).then(function (e) {
          window.location.replace('/integrations');
        });
      } else {
      }
    } else {
      yield put(fetchStoreFailure('data  of  single store is not found'));
    }
  } catch (error) {
    yield put(fetchStoreFailure(error.message));
  }
}

function* checkThirdStepPrestaShop(action) {
  try {
    const { data } = yield call(AppoloCliCheckSales.query, {
      query: checkSales,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    if (data.getIntegration) {
      if (data.getIntegration.type != 6) {
        yield put(checkThirdStepPrestaShopSuccess());
      }
    }
  } catch (error) {
    yield put(checkThirdStepPrestaShopFailure(error.message));
  }
}

function* fetchPrestaShopData(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(ApploCLIPRESTA.query, {
      query: getData,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refetch data
    });
    if (data) {
      // console.log("helooo",data.get);
      yield put(fetchPrestaShopDataSuccess(data.get));
    } else {
      yield put(fetchPrestaShopDataFailure('err'));
    }
  } catch (error) {
    yield put(fetchPrestaShopDataFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_PRODUCTS_REQUEST, fetchProducts);
  yield takeEvery(FETCH_REFUNDS_REQUEST, fetchRefunds);
  yield takeEvery(
    DELETE_INTEGRATION_PRESTASHOP_REQUEST,
    deleteIntegrationPrestaShop
  );
  yield takeEvery(INTEGRATION_PRESTASHOP_REQUEST, integrationPrestaShop);
  yield takeEvery(FETCH_STORES_REQUEST, fetchAllPrestaStores);
  yield takeEvery(FETCH_STORE_REQUEST, fetchStore);
  yield takeEvery(
    CHECK_THIRD_STEP_PRESTASHOP_REQUEST,
    checkThirdStepPrestaShop
  );
  yield takeEvery(FETCH_PRESTASHOP_DATA_REQUEST, fetchPrestaShopData);
}
