import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {  Link } from 'react-router-dom';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import FeatherIcon from 'feather-icons-react';
import {
  getCompareFacebookPageDataPerDate,
  getFacebookPageDataPerDate,
  getFacebookPagePostData,
} from '../../store/facebookPage/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  formatDate,
  formatNumberWithSpaces,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FbSocialMediaCards from '../facebookSocialMediaDashboard/fbSocialMediaCards';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/facebook.svg`;

const FacebookPageOverview = ({ t, dateRange }) => {
  const dispatch = useDispatch();
  const { facebookPageData, facebookPagePostData } = useSelector(
    (state) => state.facebookPage
  );
  const [likes_numbre, setLikes_numbre] = useState([]);
  const [compareLikes_numbre, setCompareLikes_numbre] = useState([]);
  const [post_impressions, setpost_impressions] = useState([]);
  const [comparePost_impressions, setComparePost_impressions] = useState([]);
  const [post_engagements, setpost_engagements] = useState([]);
  const [comparePost_engagements, setComparePost_engagements] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [compareFollowers, setCompareFollowers] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5,
    pageSizeOptions: ['5', '10', '20'],
  });

  useEffect(() => {
    if (
      dateRange.selection1.startDate &&
      dateRange.selection1.endDate
    ) {
      const startDate = formatDate(dateRange.selection1.startDate);
      const endDate = formatDate(dateRange.selection1.endDate);
      if (
        dateRange.selection2.startDate &&
        dateRange.selection2.endDate
      ) {
        const compareStartDate = formatDate(
          dateRange.selection2.startDate
        );
        const compareEndDate = formatDate(dateRange.selection2.endDate);
        dispatch(
          getCompareFacebookPageDataPerDate(
            startDate,
            endDate,
            compareStartDate,
            compareEndDate
          )
        );
      } else {
        dispatch(getFacebookPageDataPerDate(startDate, endDate));
      }
    }
  }, [dateRange, dispatch]);

  useEffect(() => {
    if (facebookPageData && facebookPageData.length > 0) {
      let T_post_engagements = [];
      let T_post_impressions = [];
      let T_Likes_number = [];
      let T_Followers = [];

      facebookPageData[0].forEach((item) => {
        T_Followers.push(item.followers);
        T_Likes_number.push(item.likes_numbre);
        T_post_engagements.push(item.post_engagements);
        T_post_impressions.push(item.post_impressions);
      });

      setLikes_numbre(T_Likes_number);
      setFollowers(T_Followers);
      setpost_engagements(T_post_engagements);
      setpost_impressions(T_post_impressions);

      let C_post_engagements = [];
      let C_post_impressions = [];
      let C_Likes_number = [];
      let C_Followers = [];
      if (facebookPageData[1] && facebookPageData.length > 1) {
        facebookPageData[1].forEach((item) => {
          C_Followers.push(item.followers);
          C_Likes_number.push(item.likes_numbre);
          C_post_engagements.push(item.post_engagements);
          C_post_impressions.push(item.post_impressions);
        });
      }
      setCompareFollowers(C_Followers);
      setCompareLikes_numbre(C_Likes_number);
      setComparePost_engagements(C_post_engagements);
      setComparePost_impressions(C_post_impressions);
    }
  }, [facebookPageData]);

  useEffect(() => {
    dispatch(getFacebookPagePostData());
  }, [dispatch]);

  const metrics = [
    {
      name: 'Likes',
      data: likes_numbre,
      compareData: compareLikes_numbre,
    },
    {
      name: 'Followers',
      data: followers,
      compareData: compareFollowers,
    },
    {
      name: 'Post Engagements',
      data: post_engagements,
      compareData: comparePost_engagements,
    },
    {
      name: 'Post Impressions',
      data: post_impressions,
      compareData: comparePost_impressions,
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearAll = () => {
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'post_impressions',
    });
  };

  const columns = [
    {
      title: t('POST IMAGE'),
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{'There is Non-image'}</span>
            </a>
          );
        } else {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={text}
                alt={` ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      },
    },
    {
      title: t('POST CLICKS'),
      dataIndex: 'post_clicks',
      align: 'center',
      key: 'post_clicks',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder:
        sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: t('POST LIKES'),
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder:
        sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: t('POST REACTIONS'),
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder:
        sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: t('POST IMPRESSIONS'),
      dataIndex: 'post_impressions',
      align: 'center',
      key: 'post_impressions',
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder:
        sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: t('SHARES'),
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: t('CREATED AT'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong className="table-cell-text">{formattedDate}</strong>;
      },
      align: 'center',
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);
    let updatedSelectedColumns = [];

    if (index === -1) {
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(columns.map((column) => column.key));
    }
  };

  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox checked={isAllSelected} style={{ marginRight: '8px' }} />
        <span style={{ color: 'black' }}>All</span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const colors = [
    {
      lineColor: '#4A3AFF',
      fillColor: [
        {
          offset: 0,
          color: '#93AAFD',
        },
        {
          offset: 0.47,
          color: '#C6D2FD',
        },
        {
          offset: 1,
          color: '#E5EAFC4F',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },
        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  return (
    <div className="mt-5">
      <Col xl={12}>
        <Breadcrumbs image={FacebbokAdsIcon} title={t("Facebook Social Page")} />

        <Row className="justify-content-center">
          <FbSocialMediaCards secCards={metrics} colors={colors} />
        </Row>

        <Row>
          <Card style={{ border: 'none', marginTop: '50px' }}>
            <Breadcrumbs image={FacebbokAdsIcon} title="Facebook Page posts" />
            <Space style={{ marginBottom: 16 }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="custom-button filter-button">
                  {t('Select Metrics')}
                </Button>
              </Dropdown>
              <Button className="custom-button filter-button" onClick={setAgeSort}>
                {t('Sort Impressions')}
              </Button>
              <Button className="custom-button filter-button" onClick={clearAll}>
                {t('Clear filters and sorters')}
              </Button>
            </Space>
            <div className="table-border">
              <div
                className="d-flex align-items-center"
                style={{ padding: '20px' }}
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button
                    className="custom-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '22px',
                    }}
                  >
                    <FeatherIcon
                      icon="filter"
                      size="16"
                      style={{ marginRight: '8px' }}
                    />
                    {t('Filter')}
                  </Button>
                </Dropdown>

                <Link
                  to="/fb-social-media-dashboard"
                  className="btn btn-primary btn-sm viewMetrics-button"
                >
                  {t('View full metrics')}
                </Link>
              </div>
              <Table
                columns={filteredColumns}
                dataSource={facebookPagePostData || []}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px',
                    fontweight: '700',
                    color: '#6E6893',
                  },
                }}
                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}
              />
            </div>
          </Card>
        </Row>
      </Col>
    </div>
  );
};

FacebookPageOverview.propTypes = {
  t: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
};

export default (withTranslation()(FacebookPageOverview));