import React, { useEffect, useState } from "react";
import { Button, Spin, App } from "antd";
import { FileSearchOutlined, EditOutlined, StopOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessions, clearMessages, deleteSession } from "../../store/AdvisorState/actions";
import { useNavigate  } from "react-router-dom";
import { Conversations } from "@ant-design/x";
import SimpleBar from "simplebar-react";

const SideBarAdvisorContent = () => {
  const dispatch = useDispatch();
  const navigte = useNavigate (); // For navigation
  const { message } = App.useApp();
  const { sessions, loading } = useSelector((state) => state.AdvisorReducer);
  const [content, setContent] = useState([]);
const items = sessions.map((session) => ({
  key: session.session_id,
  label: session.session_name,
  group: (() => {
    const sessionDate = new Date(session.started_at);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    if (sessionDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (sessionDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else if (sessionDate >= last7Days) {
      return "Last 7 Days";
    } else {
      return "Older";
    }
  })()
}));



  
  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch]);
  
  const menuConfig = (sessions) => ({
    items: [
      {
        label: "Delete",
        key: "delete",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => deleteSessionHandler(sessions.key),
      },
    ],
  });

  const deleteSessionHandler = (sessionId) => {
    dispatch(deleteSession(sessionId));
  };

  const handleSessionClick = (key) => {
    navigte(`/advisor/${key}`);
  };

  return (
    <SimpleBar className="p-3" style={{ maxHeight: "100%" }}>
      <Button
        onClick={() => {
          navigte("/advisor");
          dispatch(clearMessages());
        }}
        className="mb-5"
        type="default"
        icon={<FileSearchOutlined />}
        block
      >
        Create New Chat
      </Button>

      <Spin spinning={loading} tip="Loading Sessions...">
        <Conversations
          menu={menuConfig}
          items={items}
          onActiveChange={handleSessionClick}
          groupable
        />
      </Spin>
    </SimpleBar>
  );
};

export default SideBarAdvisorContent;
