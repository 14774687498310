import React, { useState, useEffect } from 'react';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import { getOders } from '../../queries/OrdersQueries/queries';
import urls from '../../routes/apiUrls';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import { format } from 'date-fns';
import i18next from 'i18next';
import { DownOutlined } from '@ant-design/icons';
import { hexToRgb } from '../GoogleAnalytics/common/HelperFunctions/utils';

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.orders, // Ensure this points to your GraphQL endpoint
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function PrestaShopOrders(props) {
  const [orders, setOrders] = useState([]);
  const [loadingState, setLoadingState] = useState(true);

  const formattedDateRange = {
    selection1: {
      startDate: format(
        new Date(props.dateRange.selection1.startDate),
        'yyyy-MM-dd'
      ),
      endDate: format(
        new Date(props.dateRange.selection1.endDate),
        'yyyy-MM-dd'
      ),
      key: props.dateRange.selection1.key,
    },
  };

  useEffect(() => {
    let isSubscribed = true;
    setLoadingState(true); // Start loading spinner while data is being fetched

    ApploCLI.query({
      query: getOders,
      variables: {
        startDate: formattedDateRange.selection1.startDate,
        endDate: formattedDateRange.selection1.endDate,
      },

      fetchPolicy: 'no-cache',
    }).then((data) => {
      if (isSubscribed) {
        if (data && data.data && data.data.all && data.data.all.length > 0) {
          const ordersData = data.data.all.map((element) => ({
            order: element.order,
          }));
          setOrders(ordersData);
          setFilteredData(ordersData); // Initialize filteredData with all the orders
        }
        setLoadingState(false); // Stop the loading spinner when data is fetched
      }
    });

    return () => (isSubscribed = false);
  }, [props.dateRange]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'total',
    });
  };

  const uniqueStatusValues = Array.from(
    new Set(orders.map((order) => order.order.status))
  );

  const statusFilters = uniqueStatusValues.map((statusValue) => ({
    text: statusValue,
    value: statusValue,
  }));

 
  const columns = [
    {
      title: i18next.t('ORDER ID'),
      dataIndex: 'id',
      align: 'center',
      key: 'orderId',
      filteredValue: filteredInfo.id || null,
      // onFilter: (value, record) => record.order.id).includes(value),
      // ...getColumnSearchProps('id'),
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.order.id}</strong>
      ),
    },

    {
      title: i18next.t('SUBTOTAL'),
      dataIndex: 'subtotal',
      align: 'center',
      key: 'subtotal',
      sorter: (a, b) => a.order.subtotal - b.order.subtotal,
      sortOrder: sortedInfo.columnKey === 'subtotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.order.subtotal}</strong>
      ),
    },
    {
      title: i18next.t('SHIPPING COST'),
      dataIndex: 'shipping_cost',
      align: 'center',
      key: 'shipping_cost',
      sorter: (a, b) => a.order.shipping_cost - b.order.shipping_cost,
      sortOrder:
        sortedInfo.columnKey === 'shipping_cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.order.shipping_cost}
        </strong>
      ),
    },
    {
      title: i18next.t('TOTAL'),
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      sorter: (a, b) => a.order.total - b.order.total,
      sortOrder: sortedInfo.columnKey === 'total' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.order.total}</strong>
      ),
    },

    {
      title: i18next.t('STATUS'),
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: (text, record) => {
        

        return (
          <span
           
            style={{
              color: record.order.color,
              fontWeight: '500',
              fontSize: '13px',
              backgroundColor: `rgba(${hexToRgb(record.order.color)}, 0.3)`,
              borderRadius: '12px',
              padding: '4px 15px 4px 15px',
              alignItems: 'center',
               display: 'inline-flex',
              FontFamily: 'DM Sans',
              maxWidth: 'fit-content',
            }}
          >
            <span
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '50%',
                backgroundColor: record.order.color || 'black',
                marginRight: '6px',
              }}
            ></span>

            {record.order.status}
          </span>
        );
      },
      ellipsis: true,
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };
  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox checked={isAllSelected} style={{ marginRight: '8px' }} />
        <span style={{ color: 'black' }}>All</span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const transformedOrders = orders.map((element, index) => ({
    order: element.order,
  }));

  // new filter
  const uniqueStatuses = Array.from(
    new Set(transformedOrders.map((order) => order.order.status))
  );
  const initialStatusFilter = uniqueStatuses.reduce((acc, status) => {
    acc[status] = true;
    return acc;
  }, {});

  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [filteredData, setFilteredData] = useState(transformedOrders);

  // new filter
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };

    setStatusFilter(updatedFilter);

    // Get selected statuses based on checked checkboxes
    const selectedStatuses = Object.keys(updatedFilter).filter(
      (key) => updatedFilter[key]
    );

    // Filter transformedOrders based on the selected statuses
    const filtered = transformedOrders.filter(
      (item) =>
        selectedStatuses.length > 0
          ? selectedStatuses.includes(item.order.status) // Compare with nested `order.status`
          : true // If no statuses are selected, show all
    );

    setFilteredData(filtered);
  };

  // Dynamically create the menu with checkboxes based on unique statuses
  const menufilter = (
    <Menu>
      {uniqueStatuses.map((status) => (
        <Menu.Item key={status}>
          <Checkbox
            name={status}
            checked={statusFilter[status] || false} // Default to true initially
            onChange={handleCheckboxChange}
          >
            {status}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <React.Fragment>
      <div className="col-xl-12 mt-4 mb-4">
        <div>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.75662 6.49965H6.92222V8.21859C6.92222 8.5182 7.16511 8.76109 7.46472 8.76109C7.76433 8.76109 8.00721 8.5182 8.00721 8.21859V6.49965H13.689V8.21859C13.689 8.5182 13.9319 8.76109 14.2315 8.76109C14.5311 8.76109 14.774 8.5182 14.774 8.21859V6.49965H17.9396C18.2913 6.49965 18.5826 6.76549 18.6148 7.11567L19.1252 12.6678C18.8837 12.6428 18.6388 12.6297 18.3907 12.6297C14.5141 12.6297 11.3714 15.7724 11.3714 19.649C11.3714 20.6025 11.5619 21.5113 11.9062 22.3401H3.13765C2.75064 22.3401 2.41747 22.1931 2.15665 21.9072C1.89579 21.6213 1.77991 21.2761 1.8153 20.8906L3.08138 7.11567C3.11358 6.76549 3.40491 6.49965 3.75662 6.49965ZM6.92222 6.41032V4.47637C6.92222 3.3962 7.36371 2.4146 8.07501 1.70331C8.7863 0.992014 9.7679 0.550522 10.8481 0.550522C11.9282 0.550522 12.9098 0.992014 13.6211 1.70331C14.3324 2.4146 14.7739 3.39625 14.7739 4.47637V6.41032H13.689V4.47637C13.689 3.69571 13.3692 2.98553 12.854 2.47041C12.3389 1.95523 11.6288 1.63546 10.8481 1.63546C10.0674 1.63546 9.35728 1.95523 8.84211 2.47041C8.32693 2.98553 8.00716 3.69566 8.00716 4.47637V6.41032H6.92222ZM18.3907 13.8485C15.1871 13.8485 12.5901 16.4455 12.5901 19.649C12.5901 22.8525 15.1871 25.4495 18.3907 25.4495C21.5942 25.4495 24.1912 22.8525 24.1912 19.649C24.1912 16.4455 21.5942 13.8485 18.3907 13.8485ZM15.5553 19.9924L17.2907 21.5861C17.5339 21.8099 17.9103 21.798 18.139 21.5622L21.2356 18.5974C21.4774 18.3647 21.4849 17.9799 21.2522 17.738C21.0196 17.4962 20.6348 17.4887 20.3929 17.7214L17.6942 20.3053L16.379 19.0974C16.1318 18.87 15.747 18.886 15.5196 19.1331C15.2922 19.3802 15.3082 19.765 15.5553 19.9924Z"
              fill="#04044C"
            />
          </svg>
          <span style={{ fontSize: '15px', fontWeight: '700' }}>
            {' '}
            {i18next.t('Orders')}
          </span>
        </div>

        <div className="table-border" style={{ marginTop: '15px' }}>
          <>
            <Space
              style={{
                marginBottom: 16,
                marginTop: 16,
              }}
            >
              <Dropdown overlay={menufilter} trigger={['click']}>
                <Button
                  icon={<FeatherIcon icon="filter" size="16" fill="#8B83BA" />}
                  lassName="custom-button"
                  style={{
                    marginRight: '10px',
                    height: '37px',
                    fontSize: '17px',
                    marginLeft: '15px',
                    alignItems: 'center',
                  }}
                >
                  {i18next.t('Filter')}
                </Button>
              </Dropdown>

              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="custom-button">
                  {i18next.t('Select Columns')}
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Button className="custom-button" onClick={setAgeSort}>
                {i18next.t('Sort by Total')}
              </Button>
              <Button className="custom-button" onClick={clearFilters}>
                {i18next.t('Clear filters')}
              </Button>
              <Button className="custom-button" onClick={clearAll}>
                {i18next.t('Clear filters and sorters')}
              </Button>
            </Space>

            <Table
              columns={filteredColumns}
              dataSource={filteredData} // Use filteredData for the table
              onChange={handleChange}
              loading={loadingState}
              pagination={{
                ...paginationOptions,
                showSizeChanger: true,
                style: {
                  backgroundColor: '#F4F2FF',
                  padding: '10px 15px',
                  marginTop: '2px',
                  borderRadius: '6px',
                  fontsize: '14px',
                  fontweight: '700',
                  color: '#6E6893',
                },
              }}
              headerCellStyle={{
                backgroundColor: '#FF0000', // Change this to your desired color
                color: '#333333', // Text color
              }}
            />
          </>
        </div>
      </div>
    </React.Fragment>
  );
}
export default PrestaShopOrders;