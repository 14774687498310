import React, { useEffect, useState } from "react";
import { Button, Container, Input, Row, Col } from "reactstrap";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage, fetchSessions } from "../../store/AdvisorState/actions";
import liveAdvisor from "../../assets/images/liveAdvisor.png";
import { useNavigate } from "react-router-dom";
import { Sender } from '@ant-design/x';
import { Prompts } from '@ant-design/x';
import {
  BulbOutlined,
  InfoCircleOutlined,
  RocketOutlined,
  SmileOutlined,
  WarningOutlined,
} from '@ant-design/icons';

const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

const Chat = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const {botTyping} = useSelector((state) => state.AdvisorReducer);
  const history = useNavigate();

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch]);

  const handleSendMessage = () => {
    if (message.trim()) {
      dispatch(sendMessage(message, "default_session", history));
      setMessage("");
    }
  };

  const itemsGoogle = [
    {
      key: '1',
      icon: (
        <BulbOutlined
          style={{
            color: '#FFD700',
          }}
        />
      ),
      label: 'Ignite Your Creativity',
      description: 'What is Google Ads, and how does it work?',
    },
    {
      key: '2',
      icon: (
        <InfoCircleOutlined
          style={{
            color: '#1890FF',
          }}
        />
      ),
      label: 'Uncover Background Marketing',
      description: 'How much does it cost to run a Google Ads campaign?',
    }
  ];

   const itemsmeta = [

    {
      key: '1',
      icon: (
        <RocketOutlined
          style={{
            color: '#722ED1',
          }}
        />
      ),
      label: 'Efficiency Boost Battle',
      description: 'How do I create an effective Meta Ads campaign?',
    },
    {
      key: '2',
      icon: (
        <SmileOutlined
          style={{
            color: '#52C41A',
          }}
        />
      ),
      label: 'Efficiency Boost Battle',
      description: 'How do I choose the right audience for my Meta Ads?',
    },
   ]


  return (
    <div className="page-content">
      <Container
        fluid
        className="d-flex flex-column justify-content-center"
        style={{ height: "65vh", overflow: "hidden", width: "65%" }}
      >
        {/* Loading Indicator */}
        {botTyping ? (
         <Row>
         <Col className="text-center my-3">
           <img src={liveAdvisor} alt="LiveAdvisor" style={{ width: "200px" }} />
         </Col>
       </Row>
        ) : (
          <>
            <Row>
              <Col className="text-center my-3">
                <img src={liveAdvisor} alt="LiveAdvisor" style={{ width: "200px" }} />
              </Col>
            </Row>
            <Row className="justify-content-center">
  {/* Google Ads Section */}
  <Col md="6" lg="5" className="text-center">
    <div className="mb-4">
      <img src={GoogleAdsIcon} alt="Google Ads" style={{ width: "40px", marginBottom: "10px" }} />
      <h5>Google Ads</h5>
    </div>
    <div className="d-flex flex-wrap w-100 gap-2">
      {itemsGoogle.map((item, index) => (
        <div key={index} className="w-100">
          <Prompts
          wrap
            items={[item]}
            onItemClick={(info) => setMessage(info.data.description)} // Fills Sender with description
           
          />
        </div>
      ))}
    </div>
  </Col>

  {/* Facebook Ads Section */}
  <Col md="6" lg="5" className="text-center">
    <div className="mb-4">
      <img src={FacebookAdsIcon} alt="Facebook Ads" style={{ width: "40px", marginBottom: "10px" }} />
      <h5>Facebook Ads</h5>
    </div>
    <div className="d-flex flex-wrap w-100 gap-2">
      {itemsmeta.map((item, index) => (
        <div key={index} className="w-100">
          <Prompts
          wrap
            items={[item]}
            onItemClick={(info) => setMessage(info.data.description)} // Fills Sender with description
        
          />
        </div>
      ))}
    </div>
  </Col>
</Row>  

          </>
        )}
              <Row className="mt-3">
              <Col>
      <div
        className="d-flex flex-column align-items-center" style={{
          backgroundColor: "#f2f2fc",
          borderRadius: "15px",
        }}>
            
<Sender
  loading={botTyping}
  value={message}
  placeholder="Type message"
  onChange={(val) => {
    setMessage(val);
  }}
  onSubmit={() => handleSendMessage()}
/>

       
      </div>
    {/* Buttons under the input */}
    <div
          className="d-flex justify-content-center mt-3"
          style={{ gap: "10px", flexWrap: "wrap" }}
        >
          {["CPM", "CPC", "ROAS", "CTR", "ROI"].map((item) => (
            <Button
              key={item}
              className="mx-1"
              style={{
                border: "1px solid #6E6893",
                borderRadius: "20px",
                padding: "0px 25px",
                background: "white",
                color: "#5a5a5a",
                fontWeight: "500",
                transition: "all 0.3s ease-in-out",
              }}
              onClick={() => setMessage(item)}
              onMouseEnter={(e) => (e.target.style.background = "#f2f2fc")}
              onMouseLeave={(e) => (e.target.style.background = "#fff")}
            >
              {item}
            </Button>
          ))}
        </div>
    </Col>
      </Row>
      </Container>
    </div>
  );
};

export default Chat;
