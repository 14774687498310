import React from 'react';
import {  Steps, theme } from 'antd';


const PrintStepper = ({current ,setCurrent,steps}) => {
  const { token } = theme.useToken();

  

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
     
    </>
  );
};

export default PrintStepper
