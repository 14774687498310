import React from "react";
import { logoMap } from "../../../assets/integrations_images/logoMap";
import AramexInsightCard from "./AramexInsightCard";

const AramexInsights = ({ data }) => {
  return (
    <>
      <div className="d-flex justify-content-start align-content-center gap-2 px-3 pt-3">
        <img
          src={logoMap["Aramex Integration"]}
          alt="Aramex"
          width={100}
          height={20}
        />
        <p>Tracking Order Status</p>
      </div>
      <div className="d-flex justify-content-center flex-wrap gap-4">
        {data.map((card, index) => (
          <AramexInsightCard key={index} {...card} />
        ))}
      </div>
    </>
  );
};

export default AramexInsights;
