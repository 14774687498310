import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveAccountsRequest } from "../../store/reports/overview/action";
import { getIntegrationIcon } from "../GoogleAnalytics/common/HelperFunctions/utils";
import integrations from "../../common/IntergrationsTypes";

const ActiveAccountsModal = ({ isModalOpen, setIsModalOpen }) => {
  const { activeAccounts, activeAccountsLoading } = useSelector(
    (state) => state.overview
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveAccountsRequest());
  }, [dispatch]);

  const handleOk = () => {
    console.log("print is");
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Active Accounts"
      open={isModalOpen}
      onCancel={handleOk}
      footer={[
        <Button key="close" type="primary" onClick={handleOk}>
          Close
        </Button>
      ]}
      loading={activeAccountsLoading}
    >
      {Object.entries(integrations).map(([integrationName, integrationId]) => {
        const activeAccount = activeAccounts?.find(
          (account) => account.type === integrationId
        );

        const textStyle = activeAccount
          ? { fontSize: "15px", fontWeight: "400", color: "#000" }
          : { fontSize: "15px", fontWeight: "400", color: "#9291A5" };

        return (
          <div
            key={integrationId}
            className="d-flex align-items-center gap-2 mt-3"
          >
            <img
              src={getIntegrationIcon(integrationId)}
              alt={integrationName}
              style={{ width: 24, height: 24 }}
            />
            <span style={textStyle}>
              {activeAccount
                ? activeAccount.accountDetails.name
                : "No Account Integrated"}
            </span>
          </div>
        );
      })}
    </Modal>
  );
};

export default ActiveAccountsModal;
