import { List, Switch } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIntegrations } from '../../../store/printState/actions';

const IntegrationsSelection = () => {
  
  const { integrations } = useSelector((state) => state.printReducer);

  const dispatch = useDispatch();

  const onChange = (index, checked) => {
    console.log(`switch new ${index} to ${checked}`);
    const updatedIntegrations = integrations.map((integration, i) =>
      i === index ? { ...integration, checked } : integration
    );
    dispatch(setIntegrations(updatedIntegrations));
  };
  

  return (
    <List
      header={<div>Choose The Integrations You Want To Print</div>}
      bordered
      dataSource={integrations}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <div>{item.title}</div>
          <Switch
            checked={item.checked}
            onChange={(checked) => onChange(index, checked)}
          />
        </List.Item>
      )}
    />
  );
};

export default IntegrationsSelection;
