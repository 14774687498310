import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import  {Exchange_Rate_Api} from '../../../../config';
import integrations from '../../../../common/IntergrationsTypes';
import { logoMap } from '../../../../assets/integrations_images/logoMap';

export const formatNumberWithSpaces = (value) => {
  if (value !== undefined && value !== null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return '0';
};

export const getTotal = (array) => {
  return array.length > 0
    ? array.map(Number).reduce((x, y) => {
        return x + y;
      })
    : 0;
};
export const getTotalFixed = (array) => {
  return array.length > 0
    ? array
        .map(Number)
        .reduce((x, y) => {
          return x + y;
        })
        .toFixed(2)
    : '0.00';
};

export const formatDuration = (seconds) => {
  const h = Math.floor(seconds / 3600) || 0;
  const m = Math.floor((seconds % 3600) / 60) || 0;
  const s = Math.floor(seconds % 60) || 0;
  if (m === 0 && h === 0) {
    return `${s}s`;
  } else if (h === 0) {
    return `${m}m ${s}s`;
  } else return `${h}h ${m}m ${s}s`;
};

export const formatToPercentage = (value) => {
  const percentage = Number(value) * 100;
  return percentage.toFixed(1) + '%';
};

export const formatDate = (date) => {
  if (!date) return null;
  const dateString = date.toLocaleString('fr-FR');
  const matchedDate = datePattern.exec(dateString);

  if (!matchedDate) return null;

  return `${matchedDate[3]}-${matchedDate[2]}-${matchedDate[1]}`;
};

export const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

//export to pdf a report
export const exportToPDF = async (pdfTitle) => {
  try {
    const capture = document.querySelector('.print');
    const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
    const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
    const doc = new jsPDF('p', 'mm', 'a4');
    const componentWidth = doc.internal.pageSize.getWidth();
    const componentHeight = doc.internal.pageSize.getHeight();
    const padding = 10; // Adjust this value to set the padding in millimeters
    // Add padding to X and Y coordinates
    doc.addImage(
      imgData,
      'PNG',
      1 + padding,
      padding,
      componentWidth - 2 * padding,
      componentHeight - 2 * padding
    );
    // // Customize the appearance of the PDF
    // doc.setLineWidth(0.5); // Set line width for borders
    // doc.setDrawColor(103, 137, 242); // Set border color to black
    // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
    doc.save(pdfTitle);
  } catch (error) {
    console.error('Error exporting to PDF:', error);
  }
};

export const calculateRententionRate = (array) => {
  const retentionRate = [];
  if (array && array.length > 0) {
    retentionRate[0] = 100;
    for (let i = 1; i < array.length; i++) {
      retentionRate[i] = parseFloat(
        ((array[i] / array[i - 1]) * 100).toFixed(2)
      );
    }
  }
  return retentionRate;
};

export function calculateGrowthPercentage(newValue, oldValue) {
  if (oldValue === 0 || !oldValue) {
    // Handle division by zero: If old value is 0, the growth is undefined or 100% if the new value is greater than 0
    return '-';
  }

  return (((newValue - oldValue) / oldValue) * 100).toFixed(2);
}

export const formattedDated = (realDate) => {
  const date = new Date(realDate);
  const formattedDate = date.toLocaleDateString('en', {
    month: 'short',
    day: '2-digit',
  });
  return formattedDate;
};


export const getExchangeRate = async (currency) => {
  const retryDelay = 1000; // 1 second delay before retrying

  try {
    if (currency.length > 0) {
      try {
        const response = await axios.get(`${Exchange_Rate_Api.first}/${currency}`);
        return response.data.conversion_rates;
      } catch (error) {
        console.error('Failed to fetch exchange rate from first API:', error);

        if (error.response && error.response.status === 429) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Wait before retrying
        }

        try {
          const response = await axios.get(`${Exchange_Rate_Api.second}/${currency}`);
          return response.data.conversion_rates;
        } catch (error) {
          console.error('Failed to fetch exchange rate from second API:', error);

          if (error.response && error.response.status === 429) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
          }

          try {
            const response = await axios.get(`${Exchange_Rate_Api.third}/${currency}`);
            return response.data.conversion_rates;
          } catch (error) {
            console.error('Failed to fetch exchange rate from third API:', error);
            throw new Error('All exchange rate APIs failed.');
          }
        }
      }
    }
  } catch (error) {
    console.error('Unexpected error in fetching exchange rate:', error);
    throw error;
  }
};


export const getExchangeRateDuo = async (baseCurrency, quoteCurrency) => {
  const rate = await getExchangeRate(quoteCurrency);
  return rate[baseCurrency];
};

export const formatDate2 = (timestamp) => {
  const date = new Date(timestamp);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();

  // Return formatted date
  return `${day}/${month}/${year}`;
};


export function hexToRgb(hex) {
  hex = hex.replace('#', '');

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}


export function getIntegrationIcon(integrationId) {
  const integrationKey = Object.keys(integrations).find(
    key => integrations[key] === integrationId
  );
  
  if (!integrationKey) {
    console.warn(`No integration found for id ${integrationId}`);
    return null;
  }
  
  return  logoMap[integrationKey] || null;
}
