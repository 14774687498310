import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideBarIconsMap } from '../../assets/sidebar_icons/sideBarIconsMap';

const SideBarContentElement = ({ item }) => {
  const location = useLocation(); 

  const isActive = (route) => location.pathname === route; 

  const isParentActive = item.children?.some(child => isActive(child.route)); 

  return !item.children ? (
    <li className={isActive(item.route) ? "active" : ""}> 
      <Link to={item.route}>
        <div className="data-container">
          <div className="img-container">
            <img src={SideBarIconsMap[item.name]} alt="icon" />
          </div>
          <span>{item.name}</span>
        </div>
      </Link>
    </li>
  ) : (
    <li className={isParentActive ? "mm-active" : ""}> 
      <Link to="/#" className="has-arrow">
        <div className="data-container">
          <div className="img-container">
            <img src={SideBarIconsMap[item.name]} alt="icon" />
          </div>
        </div>
        <span>{item.name}</span>
      </Link>
      <ul className={`sub-menu ${isParentActive ? "mm-show" : ""}`}> 
        {item.children.map((child) => (
          <li key={child.route} className={isActive(child.route) ? "active" : ""}> 
            <Link to={child.route}>{child.name}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default SideBarContentElement;
