import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Table, Switch, message, ConfigProvider, Input } from "antd"; // Ant Design components
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {  ApolloClient, InMemoryCache, HttpLink  } from '@apollo/client';

import { getInstagramocialMediaAccounts, toggleInstaSocialMediaAccount, deletePlatformInstagramSocialMedia, getInstagramSocialMedia, getFromInstagramSocial } from '../../../queries/instagramSocialMedia/queries';
import urls from '../../../routes/apiUrls';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import {facebook} from '../../../config';
import PropTypes from "prop-types";
import InstagramLogo from '../../../assets/integrations_images/instagram-integration.svg';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.instagramSocialMedia,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

 
function InstagramSocialMediaCard({ t }) {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [inactiveAccounts, setInactiveAccounts] = useState([]);
  const [step, setStep] = useState(1);
  const [searchText, setSearchText] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getInstagramSocialMedia,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        errorPolicy: "all",
        fetchPolicy: "network-only"
      });

      if (data.one) {
        // setInfo(data.one);
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  const  filter =async (data) => {

    const active = data.filter(
      (account) => account.active
    );
    const inactive = data.filter(
      (account) => !account.active
    );
    setActiveAccounts(active);
    setInactiveAccounts(inactive);

    if (active.length) {
      setStep(3);
    } else {
      setStep(2);
    }
  };


  const fetchInstagramAccounts = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getInstagramocialMediaAccounts,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        errorPolicy: "all",
        fetchPolicy: "network-only"
      });

      if (data.instagramSocialMediaAccounts) {
        setInfo(data.instagramSocialMediaAccounts);
       await filter(data.instagramSocialMediaAccounts);

      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);


  useEffect(() => {
    fetchData();
    fetchInstagramAccounts();
  },[] );




  const integrateInstagramSocialMedia = async (instagram_code) => {
    setStep(2);
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: getFromInstagramSocial,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        variables: { code: instagram_code || "" },
        errorPolicy: "all",
      });
      if (data.integration) {
        setInfo(data.integration);
        filter(data.integration);
        setLoading(false);
       
      }
    } catch (error) {
      console.error(error);
    }
  };
  const addConnection = () => {
    const FACEBOOK_APP_ID = facebook.FACEBOOK_APP_ID;
    const FACEBOOK_APP_REDIRECT_STATIC = facebook.FACEBOOK_APP_REDIRECT_STATIC;
    const connectUrl = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${FACEBOOK_APP_REDIRECT_STATIC}&scope=pages_show_list,pages_read_engagement,pages_read_user_content,ads_management,business_management,instagram_basic,instagram_manage_insights&state=inst`;

    let popup = window.open(
      connectUrl,
      "fbAuthPopup",
      "width=600,height=700,top=100,left=100"
    );
  
    // Check every second if popup is closed or has redirected
    const interval = setInterval(() => {
      try {
        if (!popup || popup.closed) {
          clearInterval(interval);
        } else if (popup.location.href.includes(FACEBOOK_APP_REDIRECT_STATIC)) {
          clearInterval(interval);
          const params = new URL(popup.location.href).searchParams;
          const code = params.get("code");
  
          if (code) {
            // Call backend API to exchange code for access token
            integrateInstagramSocialMedia(code);
            // Close the popup
            popup.close();
          }
        }
      } catch (error) {
        // Cross-origin error handling (expected due to Facebook redirection)
      }
    }, 1000);
  };
  const deleteConnection = () => {
    ApploCLI.mutate({
      mutation: deletePlatformInstagramSocialMedia,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: 'all',
    }).then((data) => { 
      setStep(1);
      setInfo(null);
    });
  };

  // Handle toggling of accounts between active and inactive
  const handleToggle = async (instaAccountId,salesPlatform) => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: toggleInstaSocialMediaAccount,
        variables: {
          platform_id: salesPlatform,
          fb_page_id: instaAccountId,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
          }
        },
        errorPolicy: 'all',
      });

      if (data?.toggle) {
        const newState = data.toggle.active;

        // Display messages based on the new state
        if (newState) {
          message.success('Account activated successfully!');
    
        } else {
          message.success('Account deactivated successfully!');
        }

        // Update active and inactive accounts in state immediately
        if (newState) {
          setActiveAccounts([data.toggle]);
          setInactiveAccounts(prev => prev.filter(account => account.instagram_business_account_id !== instaAccountId));
        } else {
          setInactiveAccounts(prev => [...prev, data.toggle]);
          setActiveAccounts([]);
        }

        await fetchInstagramAccounts();
      } else {
        message.error('Failed to toggle the account. Please try again.');
      }
    } catch (err) {
      setLoading(false);
      message.error('An error occurred while toggling the account.');
      console.error('Toggle Error:', err);
    } finally {
      setLoading(false);
    }
  };


  const [filteredData, setFilteredData] = useState(inactiveAccounts);

useEffect(() => {
  setFilteredData(inactiveAccounts);
}, [inactiveAccounts]);

const handleSearchInputChange = (e) => {
  const value = e.target.value.toLowerCase();
  setSearchText(value);

  // Filter the inactiveAccounts array
  const filtered = (inactiveAccounts || []).filter((item) => {
    const username = item.instagram_username || ''; // Default to empty string if null/undefined
    return username.toLowerCase().includes(value);
  });

  // Update the filtered data state
  setFilteredData(filtered);
};
  // Define table columns with toggle action in the "Action" column
  const columns = [
    {
      title: 'INSTAGRAM PAGE NAME',
      dataIndex: 'instagram_username',
      key: 'instagram_username',
      render: (text, record) => (
        <div>
          <span className="table-cell-text">{text}</span>
          <br />
          <span className="table-cell-subtext" style={{color :"#6F6F6F"}}>{record.instagram_business_account_id}</span>
        </div>
      ),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: 140, // Set a fixed width for the ACTION column
      align: 'center', // Align the content of the ACTION column to the center
      render: (text, record) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#5CCF5C',
              colorTextQuaternary: '#6E6893',
              opacityLoading: 1,
            },
          }}
        >
          <Switch
            checked={record.active}
            disabled={!record.active && activeAccounts.length > 0}
            onChange={() => handleToggle(record.fb_page_id,record.salesPlatform)}
            loading={loading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </ConfigProvider>
      ),
    },
  ];

  const renderSections = () => {
    return (
      <div className='mt-5'>
        <Row>
          <Col>
            <Card className="border text-secondary">
              <CardHeader style={{ borderBottom: "none" }}>
                <img src={InstagramLogo} alt="Instagram Social Media" />
              </CardHeader>
              <CardBody>
                {step === 1 && (
                  <>
                    <p style={{ fontSize: '16px', fontWeight: '400' }}>
                      {t("Connect your Instagram account within 30 seconds")}
                    </p>
                    <button onClick={addConnection} className="btn btn-primary" style={{ width: '100%', backgroundColor: "#0080F9", borderRadius: '6px', fontSize: '17px', fontWeight: '500', border: 'none' }}>
                      {t("Connect")}
                    </button>
                  </>
                )}
                {step !== 1 && (
                  <>
                    <h5 className="" style={{ marginBottom: '20px', fontWeight: '600' }}>Choose Instagram Page</h5>
                    <div className="search-box" style={{ flex: 1, maxWidth: '523px', position: 'relative', backgroundColor: '#F4F2FF', borderRadius: '6px', padding: '10px 15px' , marginBottom:"10px"}}>
                      <Input
                        placeholder={i18next.t('Convergen - P')}
                        value={searchText}
                        className="custom-search-input"
                         onChange={handleSearchInputChange}
                      />
                      <i className="bx bx-search-alt search-icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#7a7a7a' }} />
                    </div>
                    <Spin spinning={loading}>
                    {activeAccounts.length > 0 && (
                      <Table
                        bordered
                        columns={columns}
                        dataSource={activeAccounts}
                        rowKey="fb_page_id"
                        pagination={false}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      />
                    )}
                      <Table
                        bordered
                        columns={columns}
                        dataSource={filteredData}
                        rowKey="fb_page_id"
                        pagination={{
                          pageSize: 5,
                          showSizeChanger: false,
                          style: {
                            backgroundColor: '#F4F2FF',
                            padding: '10px 15px',
                            marginTop: '2px',
                            borderRadius: '6px',
                            fontsize: '14px',
                            fontweight: '700',
                            color: '#6E6893',
                          },
                        }}
                        headerCellStyle={{
                          backgroundColor: '#FF0000', // Change this to your desired color
                          color: '#333333', // Text color
                        }}
                        // onRow={(record, index) => {
                        //   return {
                        //     style: {
                        //       backgroundColor: index % 2 === 0 ? 'white' : '#F4F2FF',
                        //     },
                        //   };
                        // }}
                      
                      />
                    </Spin>
                    {activeAccounts.length === 0 ? (
                      <div className='text-center'>
                        <button type="button" 
                         className="btn btn-danger waves-effect waves-light"
                         style={{
                           width: '100%',
                           backgroundColor: "#F4F2FF",
                           borderRadius: '6px',
                           fontSize: '17px',
                           fontWeight: '500',
                           border: 'none',
                           marginTop: '10px',
                           color: "#6E6893"
                         }} onClick={deleteConnection}>
                          {t("Delete Connection")}
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  return renderSections();
}

InstagramSocialMediaCard.propTypes = {
  t: PropTypes.any
};

export default (withTranslation()(InstagramSocialMediaCard));
