import gql from 'graphql-tag';

const getFromFacebook = gql`
  mutation ($code: String!) {
    integration(code: $code) {
    salesPlatform
        name
      fb_act_id
      active
      currency_code
      revenue_currency_code
    }
  }
`;

const getFacebook = gql`
  query {
    one {
      id
      name
    }
  }
`;

const getFacebookPixels = gql`
  query {
    adAccounts {
    salesPlatform
      name
      fb_act_id
      active
      currency_code
      revenue_currency_code
    }
  }
`;

const deletePlatformFacebook = gql`
  mutation {
    delete {
      isDeleted
    }
  }
`;

const toggleFbAccount = gql`
  mutation ($platform_id: String!, $fb_act_id: String!) {
    toggle(platform_id: $platform_id, fb_act_id: $fb_act_id) {
      active
      currency_code
      revenue_currency_code
    }
  }
`;
const getMADSCurrency = gql`
  query {
    getCurrency {
      spend
      revenue
    }
  }
`;
const addMetaCurrency = gql`
  mutation ($currency: String!) {
    addMetaCurrency(currency: $currency) {
      revenue
    }
  }
`;
const getfbCamapigns = gql`
  query {
    getfbCampaigns {
      campaign_id
      campaign_name
      impressions
      spend
      clicks
      reach
      ctr
      cpc
      date_start
      date_stop
      action_values
      actions
      addToCartAction
      initiateCheckoutAction
      landingPageViewAction
      onSiteWebViewAction
    }
  }
`;

const getfbCamapignsperdate = gql`
  query ($startDate: String!, $endDate: String!) {
    getfacebookcampaignsmetricsperday(
      startDate: $startDate
      endDate: $endDate
    ) {
      campaign_id
      campaign_name
      status
      cpa
      impressions
      spend
      clicks
      reach
      ctr
      cpc
      date_start
      date_stop
      action_values
      actions
      landingPageViewAction
    }
  }
`;
const getadsperdate = gql`
  query ($startDate: String!, $endDate: String!) {
    getadsperdate(startDate: $startDate, endDate: $endDate) {
      campaign_name
      impressions
      spend
      clicks
      reach
      ctr
      cpc
      action_values
      actions
      landing_page_views
      post_engagement
      post_reaction
      page_engagement
      ad_name
      image_url
    }
  }
`;
export {
  getFromFacebook,
  getFacebook,
  deletePlatformFacebook,
  getFacebookPixels,
  toggleFbAccount,
  getfbCamapigns,
  getfbCamapignsperdate,
  getadsperdate,
  addMetaCurrency,
  getMADSCurrency,
};
