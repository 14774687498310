import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMagentoOrdersRequest } from '../../../store/actions';
import GoogleAdsChart from '../../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { logoMap } from '../../../assets/integrations_images/logoMap';
import { Container, Row } from 'reactstrap';
import PrestaShopCards from '../../PrestaDashbaord/PrestaShopCards';
import MagentoOrdersTable from './MagentoOrdersTable';
import { formatDate } from '../../GoogleAnalytics/common/HelperFunctions/utils';
import ActiveAccountsModal from '../../OverviewDashboard/ActiveAccountsModal';

const MagentoDashboard = () => {
  
  const colors = useMemo(
    () => [
      {
        lineColor: '#E15600',
        fillColor: [
          {
            offset: 0,
            color: '#E15600',
          },
          {
            offset: 0.47,
            color: '#E1560080',
          },
          {
            offset: 1,
            color: '#E156001A',
          },
        ],
      },
      {
        lineColor: '#F6B43B',
        fillColor: [
          {
            offset: 0,
            color: ' #F6B43B',
          },

          {
            offset: 1,
            color: '#D9D9D9',
          },
        ],
      },
    ],

    []
  );

  const dispatch = useDispatch();
  const { orders, getProductsLoading } = useSelector(
    (state) => state.magentoReducer
  );
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const [metrics, setMetrics] = useState({
    sales: [],
    compareSales: [],
    GrossProfit: [],
    compareGrossProfit: [],
    NumberOrders: [],
    compareNumberOrders: [],
    AOV: [],
    compareAOV: [],
  });

  // Function to calculate metrics
  const calculateMetrics = (orders, startDate, endDate) => {

    const groupedMetrics = {};

    const startTimestamp = new Date(startDate).setHours(0, 0, 0, 0);
    const endTimestamp = new Date(endDate).setHours(23, 59, 59, 999);

    if (isNaN(startTimestamp) || isNaN(endTimestamp)) {
      console.error('Invalid startDate or endDate:', startDate, endDate);
      return {
        dates: [],
        sales: [],
        grossProfit: [],
        numberOfOrders: [],
        aov: [],
      };
    }

    orders.forEach((order) => {
      if (!order.date_add || isNaN(Number(order.date_add))) {
        console.warn('Skipping invalid date_add:', order.date_add);
        return;
      }

      const orderDate = new Date(Number(order.date_add));
      orderDate.setHours(0, 0, 0, 0);
      const date = orderDate.toLocaleDateString('en-CA');

      if (!groupedMetrics[date]) {
        groupedMetrics[date] = {
          sales: 0,
          grossProfit: 0,
          numberOfOrders: 0,
        };
      }

      groupedMetrics[date].sales += order.total_paid || 0;
      groupedMetrics[date].numberOfOrders += 1;
      groupedMetrics[date].grossProfit += order.sub_total || 0;
    });

    const sales = [];
    const grossProfit = [];
    const numberOfOrders = [];
    const aov = [];
    const allDates = [];

    let currentDate = new Date(startTimestamp);
    while (currentDate.getTime() <= endTimestamp) {
      const formattedDate = currentDate.toLocaleDateString('en-CA');
      allDates.push(formattedDate);

      if (!groupedMetrics[formattedDate]) {
        groupedMetrics[formattedDate] = {
          sales: 0,
          grossProfit: 0,
          numberOfOrders: 0,
        };
      }

      const dailyMetrics = groupedMetrics[formattedDate];
      sales.push(dailyMetrics.sales);
      grossProfit.push(dailyMetrics.grossProfit);
      numberOfOrders.push(dailyMetrics.numberOfOrders);
      aov.push(
        dailyMetrics.numberOfOrders
          ? dailyMetrics.sales / dailyMetrics.numberOfOrders
          : 0
      );

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return { dates: allDates, sales, grossProfit, numberOfOrders, aov };
  };

  useEffect(() => {
    if (orders.length > 0) {
      const { sales, grossProfit, numberOfOrders, aov } = calculateMetrics(
        orders[0],
        dateRange.selection1.startDate,
        dateRange.selection1.endDate
      );
      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        sales,
        GrossProfit: grossProfit,
        NumberOrders: numberOfOrders,
        AOV: aov,
      }));
      if (
        orders.length >= 2 &&
        dateRange.selection2?.startDate &&
        dateRange.selection2?.endDate
      ) {
        const { sales, grossProfit, numberOfOrders, aov } = calculateMetrics(
          orders[1],
          dateRange.selection2.startDate,
          dateRange.selection2.endDate
        );
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          compareSales: sales,
          compareGrossProfit: grossProfit,
          compareNumberOrders: numberOfOrders,
          compareAOV: aov,
        }));
      }
    }
  }, [orders, dateRange]);

  const series = useMemo(
    () => [
      {
        name: 'Sales',
        data: metrics.sales,
        value: 'Sales',
        compareData: metrics.compareSales,
      },
      {
        name: 'GrossProfit',
        data: metrics.GrossProfit,
        value: 'GrossProfit',
        compareData: metrics.compareGrossProfit,
      },
      {
        name: 'NumberOrders',
        data: metrics.NumberOrders,
        value: 'NumberOrders',
        compareData: metrics.compareNumberOrders,
      },
      {
        name: 'AOV',
        data: metrics.AOV,
        value: 'AOV',
        compareData: metrics.compareAOV,
      },
    ],
    [metrics]
  );

  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);

      if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
        const newCompareStartDate = formatDate(dateRange.selection2.startDate);
        const newCompareEndDate = formatDate(dateRange.selection2.endDate);

        dispatch(
          fetchMagentoOrdersRequest(
            newStartDate,
            newEndDate,
            newCompareStartDate,
            newCompareEndDate
          )
        );
      } else {
        dispatch(fetchMagentoOrdersRequest(newStartDate, newEndDate));
      }
    }
  }, [dateRange, dispatch]);


  return (
    <div className="page-content">
      <Container fluid>
        <div className="breadcrumbs-container">
          <Breadcrumb
            title={'Magento Dashboard'}
            breadcrumbItem={'Magento Dashboard'}
            pageHeading={' Dashboard'}
            image={logoMap['Magento']}
          />
        </div>
        
        <GoogleAdsChart series={series} colors={colors} />
        <Row className="justify-content-center">
          <PrestaShopCards data={series} colors={colors} />
        </Row>
        <Breadcrumb title={'Magento Orders'} image={logoMap['Magento']} />
        <MagentoOrdersTable orders={orders} loading={getProductsLoading} />
        <ActiveAccountsModal visible={true} />
      </Container>
    </div>
  );
};

export default MagentoDashboard;
