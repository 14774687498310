import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { Table } from 'antd';
import secureSection from '../../MangeSecurity/MemberSecurity';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFacebookCampaignsPerDate } from '../../store/facebookAds/actions';
import AdTableSingleSearch from '../GoogleDashboard/AdTableSingleSearch';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import i18next from 'i18next';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { logoMap } from '../../assets/integrations_images/logoMap';
function Campaignstable({ overview = false }) {
  secureSection();
  const dispatch = useDispatch();
  const { metrcisbyCompaign, isLoading } = useSelector(
    (state) => state.facebookAds
  );
  
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getFacebookCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [dateRange]);

  const [sortedInfo, setSortedInfo] = useState({});
 
const columns = [
  {
    title: i18next.t('STATUS'),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 100,
    render: (status) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: status === 'ACTIVE' ? '#007F00' : 'grey',
          backgroundColor: status === 'ACTIVE' ? '#CDFFCD' : '#fff0f6',
          fontWeight: status === 'ACTIVE' ? 'bold' : 'normal',
          opacity: status === 'ACTIVE' ? 1 : 0.6,
          borderRadius: '12.67px',
        }}
      >
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            display: 'inline-block',
            backgroundColor: status === 'ACTIVE' ? 'green' : 'grey',
            marginRight: '8px',
          }}
        ></span>
        {status === 'ACTIVE' ? 'Active' : 'Inactive'}
      </div>
    ),
  },
  {
    title: i18next.t('NAME'),
    dataIndex: 'campaign_name',
    key: 'campaign_name',
    align: 'center',
    width: 200,
    render: (text) => <span className="table-cell-text">{text}</span>,
  },
  {
    title: i18next.t('SPEND'),
    dataIndex: 'spend',
    align: 'center',
    key: 'spend',
    sorter: (a, b) => a.spend - b.spend,
    sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => (
      <strong className="table-cell-text">{text.toFixed(2)}$</strong>
    ),
  },
  {
    title: i18next.t('REVENUE'),
    dataIndex: 'action_values',
    key: 'action_values',
    sorter: (a, b) => a.action_values - b.action_values,
    sortOrder:
      sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
    ellipsis: true,
    align: 'center',
    render: (text) => (
      <strong className="table-cell-text">{text.toFixed(2)}$</strong>
    ),
  },
  {
    title: i18next.t('CONVERSIONS'),
    dataIndex: 'actions',
    align: 'center',
    key: 'actions',
    sorter: (a, b) => a.actions - b.actions,
    sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => <strong className="table-cell-text">{text}</strong>,
    width: 150,
  },
  {
    title: i18next.t('IMPRESSIONS'),
    dataIndex: 'impressions',
    key: 'impressions',
    sorter: (a, b) => a.impressions - b.impressions,
    sortOrder:
      sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
    ellipsis: true,
    align: 'center',
    render: (text) => (
      <strong className="table-cell-text">
        {formatNumberWithSpaces(text)}
      </strong>
    ),
    width: 150,
  },
  {
    title: i18next.t('CLICKS'),
    dataIndex: 'clicks',
    key: 'clicks',
    sorter: (a, b) => (parseFloat(a.clicks) || 0) - (parseFloat(b.clicks) || 0), // Convert properly
    sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
    ellipsis: true,
    align: 'center',
    render: (text) => (
      <strong className="table-cell-text">
        {formatNumberWithSpaces(parseFloat(text) || 0)}
      </strong>
    ),
  },
  {
    title: i18next.t('REACH'),
    dataIndex: 'reach',
    key: 'reach',
    align: 'center',
    sorter: (a, b) => a.reach - b.reach,
    sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => (
      <strong className="table-cell-text">
        {formatNumberWithSpaces(text)}
      </strong>
    ),
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    key: 'ctr',
    align: 'center',
    sorter: (a, b) => (parseFloat(a.ctr?.toString().replace('%', '')) || 0) - (parseFloat(b.ctr?.toString().replace('%', '')) || 0),
    sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => {
      const numericValue = parseFloat(text?.toString().replace('%', '')) || 0;
      return <strong className="table-cell-text">{numericValue.toFixed(2)}%</strong>;
    },
  },
  {
    title: 'CPA',
    dataIndex: 'cpa',
    align: 'center',
    key: 'cpa',
    sorter: (a, b) => a.cpa - b.cpa,
    sortOrder: sortedInfo.columnKey === 'cpa' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => (
      <strong className="table-cell-text">{text.toFixed(2)}$</strong>
    ),
  },
  {
    title: 'CPC',
    dataIndex: 'cpc',
    key: 'cpc',
    align: 'center',
    sorter: (a, b) => parseFloat(a.cpc?.toString().replace(/[^\d.-]/g, '')) - parseFloat(b.cpc?.toString().replace(/[^\d.-]/g, '')),
    sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
    ellipsis: true,
    render: (text) => {
      const numericValue = parseFloat(text?.toString().replace(/[^\d.-]/g, '')) || 0;
      return <strong className="table-cell-text">{numericValue.toFixed(2)}$</strong>;
    },
  },
];
// Calculate totals for all numeric columns
const calculateColumnTotals = () => {
  const totals = {};

  columns.forEach((column) => {
    if (metrcisbyCompaign.length > 0) {
      let total = metrcisbyCompaign.reduce((sum, row) => {
       
        let value = row[column.key];

        // Special handling for CPC and CTR
        if (column.key === 'cpc') {
          value = parseFloat(value?.toString().replace(/[^\d.-]/g, '')) || 0;
        } else if (column.key === 'ctr') {
          value = parseFloat(value?.toString().replace('%', '')) || 0;
        } else {
          value = parseFloat(value) || 0;
        }
        return sum + value;
      }, 0);

      totals[column.key] = total;
    }
  });

  return totals;
};
const totalRow = () => {
  const columnTotals = calculateColumnTotals();
  
  return (
    <Table.Summary fixed>
    <Table.Summary.Row style={{ backgroundColor: '#f0f0f0' }}>
   
      {columns.map((column, index) => {
        if (index === 0 || column.key === 'campaign_name' || column.key === 'status') {
          return (
            <Table.Summary.Cell
              key={`total-label-${index}`}
              index={index}
              colSpan={1}
              className='table-cell-text'
            >
              -
            </Table.Summary.Cell>
          );
        }
  
        let total = columnTotals[column.key];
        const isNumeric = typeof total === 'number' && !isNaN(total);
        
        console.log(`Column: ${column.key}, Type: ${typeof total}`);
  
        // Handle CPA Calculation Correctly
        if (column.key === 'cpa') {
          const totalSpend = columnTotals['spend'] || 0; 
          const totalConversions = columnTotals['actions'] || 1; // Avoid division by zero
          total = totalConversions > 0 ? totalSpend / totalConversions : 0;
        }
        if (column.key === 'cpc') {
          const totalSpend = columnTotals['spend'] || 0; 
          const totalConversions = columnTotals['clicks'] || 1; // Avoid division by zero
          total = totalConversions > 0 ? totalSpend / totalConversions : 0;
        }
        if (column.key === 'ctr') {
          const totalClicks = columnTotals['clicks'] || 0; 
          const totalImpressions = columnTotals['impressions'] || 1; // Avoid division by zero
          total = totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0;
        }
  
  
        return (
          <Table.Summary.Cell align='center'
            key={`total-${column.key}`}
            index={index}
            colSpan={1}
            className='table-cell-text'
          >
            {isNumeric ? `${total.toLocaleString()} Total` : '-'}
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  </Table.Summary>
  );
};
  
  return (
    <>
        <title>
          {i18next.t(' Meta Campaigns Data')} | Live Metrics - E-com SaaS
        </title>
      <div className="page-content">
        <Breadcrumb
          image={logoMap['Facebook Ads']}
          title="Facebook Ads"
          
        />
        <AdTableSingleSearch
          overview={overview}
          columns={columns}
          data={metrcisbyCompaign}
          setSortedInfo={setSortedInfo}
          sortedInfo={sortedInfo}
          loading={isLoading}
          statusMapping={{
            ENABLED: 'ACTIVE',
            PAUSED: 'PAUSED',
          }}
          Total={totalRow}
        />
      </div>
    </>
  );
}

Campaignstable.propTypes = {
  t: PropTypes.any,
};

export default (withTranslation()(Campaignstable));
