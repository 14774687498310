import React, { useState } from 'react';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch, useSelector } from 'react-redux';
import { setRecommandation } from '../../../store/printState/actions';

const Recommandation = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const dipatch = useDispatch();
  const onEditorStateChange = (state) => {
    setEditorState(state);
    let text = state.getCurrentContent().getPlainText('\u0001');
    dipatch(setRecommandation(text));
  };

  return (
    <Editor
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
};

export default Recommandation;
