import gql from "graphql-tag";

const getMagentoIntegration = gql`
  query {
    getIntegration {
      id
      storeName
      name
      storeCurrency
      url
      connectionstate
      storeCountryName
      type
    }
  }
`;

const getProducts = gql`
  query ($limit: Int!, $offset: Int!) {
    getProducts(limit: $limit, offset: $offset) {
      id
      name
      sku
      price
    }
  }
`;

const getProductsCount = gql`
  query {
    getProductsCount {
      count
    }
  }
`;

const getOrders = gql`
  query (
    $startDate: String!
    $endDate: String!
    $compareStartDate: String
    $compareEndDate: String
  ) {
    getOrders(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      account
      order_id
      sub_total
      total_paid
      total_products
      total_shipping
      total_discounts
      delivery_number
      shipping_number
      id_customer
      date_add
      status
      order_rows {
        item_id
        product_id
        product_attribute_id
        quantity
        product_name
        product_price
      }
    }
  }
`;

const fetchBestSellers = gql`
  query ($startDate: String!, $endDate: String!) {
    getBestSellerProducts(startDate: $startDate, endDate: $endDate) {
      id
      product_name
      total_quantity
      total_revenue
    }
  }
`;

const integrateMagento = gql`
  mutation ($token: String!, $domain: String!, $protocol: String!) {
    integration(token: $token, domain: $domain, protocol: $protocol) {
      name
    }
  }
`;

const deleteIntegration = gql`
  mutation mutation {
    delete {
      isDeleted
    }
  }
`;

export {
  integrateMagento,
  getProductsCount,
  getMagentoIntegration,
  deleteIntegration,
  getProducts,
  getOrders,
  fetchBestSellers,
};
