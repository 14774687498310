import { put, call, takeEvery } from 'redux-saga/effects';
import {
  updateUser,
  resetPass,
  newUser,
  getaccount,
  forgetPass,
  newUserWithGoogle,
} from '../../queries/Users/userQuery';

import {
  AUTHENTIFICATIONSTATE,
  ISVERIFIED,
  LOGIN,
  LOGINWITHGOOGLE,
  LOGOUT,
  REFRESH_TOKEN,
  VERIFYACCOUNT,
} from '../../queries/LoginQueries';
import Swal from 'sweetalert2';
import urls from '../../routes/apiUrls';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import {
  GET_USER_INFO_REQUEST,
  getUserInfoSuccess,
  getUserInfoFailure,
  EDIT_PROFILE_REQUEST,
  editProfileSuccess,
  editProfileFailure,
  RESET_PASSWORD_REQUEST,
  resetPasswordSuccess,
  resetPasswordFailure,
  LOGIN_REQUEST,
  loginSuccess,
  loginFailure,
  IS_CONNECTED_REQUEST,
  isConnectedSuccess,
  isConnectedFailure,
  REGISTER_REQUEST,
  registerSuccess,
  registerFailure,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  FORGET_PASSWORD_REQUEST,
  LOGIN_WITH_GOOGLE_REQUEST,
  REGISTER_WITH_GOOGLE_REQUEST,
  registerWithGoogleSuccess,
  registerWithGoogleFailure,
  loginWithGoogleSuccess,
  loginWithGoogleFailure,
  verifCodeFailure,
  verifCodeSuccess,
  VERIF_CODE_REQUEST,
  isVerifiedSuccess,
  isVerifiedFailure,
  IS_VERIFIED_REQUEST,
  REFRESH_TOKEN_REQUEST,
  refreshTokenSuccess,
  refreshTokenFailure,
  logoutSuccess,
  LOGOUT_REQUEST,
  logoutFailure,
} from './actions';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.user,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const ApploLogin = new ApolloClient({
  link: new HttpLink({
    uri: urls.login,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function* getuserinfo() {
  try {
    console.log('im in the user info ', window.localStorage.getItem('token'));
    const { data, error } = yield call(ApploCLI.query, {
      query: getaccount,
   
      errorPolicy: 'all',
    });
    console.log('data', data, 'error', error);
    if (data.get) {
      yield put(getUserInfoSuccess(data.get));
    } else if (error) {
      console.log('ahla');
      yield put(getUserInfoFailure('invalid Token'));
    } else {
      yield put(getUserInfoFailure('error if data is null'));
    }
  } catch (error) {
    yield put(getUserInfoFailure(error));
  }
}
function* editProfile(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: updateUser,
      variables: {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        mobile: action.payload.mobile,
        image:action.payload.imageUrl
      },
      errorPolicy: 'all',
    });
    console.log('***************data***************', data.update.data);
    if (data.update) {
      window.localStorage.setItem('token', data.update.token);
      console.log("data",data.update.token)
      yield put(editProfileSuccess(data.update.data));
      Swal.fire({
        title: 'Success!',
        text: 'Profile was updated',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        window.location.reload();
      });
    } else {
      yield put(editProfileFailure('error if data is null'));
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error appears!',
      });
    }
  } catch (error) {
    yield put(editProfileFailure(error));
  }
}

function* resetpassowrd(action) {
  console.log('***************action***************', action.payload);
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: resetPass,
      variables: {
        password: action.payload.toString(),
      },
      errorPolicy: 'all',
    });
    if (data.reset) {
      yield put(resetPasswordSuccess());
      Swal.fire({
        title: 'Success',
        text: 'Yourpassword',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        window.location.reload();
      });
    } else {
      yield put(resetPasswordFailure('error if data is null'));
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error appears!',
      }).then((result) => {
        window.location.reload();
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'An error appears!',
    }).then((result) => {
      window.location.reload();
    });
  }
}

function* forgetpassowrd(action) {
  console.log('***************action***************', action.payload);
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: forgetPass,
      variables: {
        email: action.payload.toString(),
      },
      errorPolicy: 'all',
    });
    if (data.forgotPassword) {
      yield put(forgetPasswordSuccess());
      Swal.fire({
        title: 'Success',
        text: 'Yourpassword',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        window.location.reload();
      });
    } else {
      yield put(forgetPasswordFailure('error if data is null'));
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error appears!',
      }).then((result) => {
        window.location.reload();
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'An error appears!',
    }).then((result) => {
      window.location.reload();
    });
  }
}

function* login(action) {
  try {
    const { data } = yield call(ApploLogin.query, {
      query: LOGIN,
      context: {
        credentials: 'include',
      },
      variables: {
        email: action.payload.email,
        password: action.payload.password,
      },
      errorPolicy: 'all',
    });
    console.log('***************data***************', data);
    if (data.login !== null) {
      if (data.login.accessToken === 'false') {
        Swal.fire({
          icon: 'Error',
          title: 'Oops...',
          text: ' wrong password !',
        });
        yield put(loginFailure('wrong password!'));
      } else {
        yield put(loginSuccess(data.login));
        window.localStorage.setItem('token', data.login.accessToken);
        window.localStorage.setItem('verif', 'true');
        window.location.replace('/integrations');
      }
    } else {
      if (data.errors) {
        Swal.fire({
          icon: 'Error',
          title: 'Oops...',
          text: ' something went wrong please try again!',
        });
        yield put(loginFailure('error if data is null'));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'verify your credential',
        });
      }
      yield put(loginFailure('error if verify your credential'));
    }
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* loginWithGoogle(action) {
  try {
    const { data, errors } = yield call(ApploLogin.query, {
      query: LOGINWITHGOOGLE,
      context: {
        credentials: 'include',
      },
      variables: {
        token: action.payload.token,
      },

      errorPolicy: 'all',
    });
    console.log('***************data***************', data);
    if (data.loginWithGoogle !== null) {
      if (data.loginWithGoogle.accessToken === 'false') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: ' wrong password !',
        });
        yield put(loginWithGoogleFailure('wrong password!'));
      } else {
        yield put(loginWithGoogleSuccess(data.loginWithGoogle));
        window.localStorage.setItem('token', data.loginWithGoogle.accessToken);
        window.localStorage.setItem('verif', 'true');
        window.location.replace('/integrations');
      }
    } else {
      if (errors)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errors[0].message,
        });
      yield put(loginWithGoogleFailure('error if data is null'));
    }
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'verify your credential',
    //     });
    //   }
    //   yield put(loginWithGoogleFailure('error if verify your credential'));
    // }
  } catch (error) {
    yield put(loginWithGoogleFailure(error));
  }
}
function* isConnected() {
  try {
    const { data } = yield call(ApploLogin.query, {
      query: AUTHENTIFICATIONSTATE,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    });
    if (
      data &&
      data.AuthentificationState &&
      data.AuthentificationState.isAuth
    ) {
      yield put(isConnectedSuccess());
    } else {
      yield put(isConnectedFailure());
    }
  } catch (error) {
    yield put(isConnectedFailure());
  }
}

function* logout() {
  try {
    const { data } = yield call(ApploLogin.query, {
      query: LOGOUT,
      context: {
        credentials: 'include',
      },
      errorPolicy: 'all',
    });
    if (data && data.logoutState && data.logoutState.isAuth) {
      yield put(logoutSuccess());
    } else {
      yield put(logoutFailure());
    }
  } catch (error) {
    yield put(logoutFailure());
  }
}

function* isVerified(action) {
  try {
    console.log('***************action***************', action.payload);

    const { data, errors } = yield call(ApploLogin.query, {
      query: ISVERIFIED,
      variables: {
        id: action.payload.id,
      },
      errorPolicy: 'all',
    });
    console.log(data, errors);
    if (data && data.isVerified && data.isVerified.isVerified) {
      yield put(isVerifiedSuccess());
    } else {
      yield put(isVerifiedFailure());
    }
  } catch (error) {
    yield put(isVerifiedFailure());
  }
}

function* register(action) {
  try {
    const { data, errors } = yield call(ApploCLI.mutate, {
      mutation: newUser,
      variables: {
        name: action.payload.name,
        email: action.payload.email,
      },
      errorPolicy: 'all',
    });
    if (errors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errors[0].message,
      });
      yield put(registerFailure('error if there is no data'));
    } else {
      yield put(registerSuccess(data));
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'You have successfully registered',
      });
    }
  } catch (error) {
    yield put(registerFailure(error));
  }
}
function* registerWithGoogle(action) {
  try {
    const { data, errors } = yield call(ApploCLI.mutate, {
      mutation: newUserWithGoogle,
      variables: {
        token: action.payload.token,
      },
      errorPolicy: 'all',
    });
    if (errors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errors[0].message,
      });
      yield put(registerWithGoogleFailure('error if there is no data'));
    } else {
      yield put(registerWithGoogleSuccess(data));
      Swal.fire({
        icon: 'info',
        html: `
    verify your account via <b>your email account </b>,
  check your emails !
  `,
        title: 'Verify Your Account',
      });
    }
  } catch (error) {
    yield put(registerWithGoogleFailure(error));
  }
}

function* VerifCode(action) {
  try {
    const { data } = yield call(ApploLogin.mutate, {
      mutation: VERIFYACCOUNT,
      context: {
        credentials: 'include',
      },
      variables: {
        id: action.payload.id,
        verifCode: action.payload.verifCode,
        realCode: action.payload.realCode,
      },
      errorPolicy: 'all',
    });
    console.log('***************data***************', data);
    console.log(data.verifyAccount);
    if (data.verifyAccount !== null) {
      if (data.verifyAccount.accessToken === 'false') {
        Swal.fire({
          icon: 'Error',
          title: 'Oops...',
          text: ' wrong password !',
        });
        yield put(verifCodeFailure('wrong password!'));
      } else {
        yield put(verifCodeSuccess(data.verifyAccount));
        window.localStorage.setItem('token', data.verifyAccount.accessToken);
        window.localStorage.setItem('verif', 'true');
        window.location.replace('/integrations');
      }
    } else {
      if (data.errors) {
        Swal.fire({
          icon: 'Error',
          title: 'Oops...',
          text: ' something went wrong please try again!',
        });
        yield put(verifCodeFailure('error if data is null'));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'verify your credential',
        });
      }
      yield put(verifCodeFailure('error if verify your credential'));
    }
  } catch (error) {
    yield put(verifCodeFailure(error));
  }
}
function* refreshToken(action) {
  try {
    const { data } = yield call(ApploLogin.query, {
      query: REFRESH_TOKEN,
      context: {
        credentials: 'include',
      },
      variables: {
        token: action.payload.token,
      },
      errorPolicy: 'all',
    });
    console.log('***************data***************', data);
    if (data.refreshToken !== null) {
      if (data.refreshToken.accessToken === null) {
      } else {
        yield put(refreshTokenSuccess(data.refreshToken));
        window.localStorage.setItem('token', data.refreshToken.accessToken);
        window.localStorage.setItem('verif', 'true');
        window.location.replace('/integrations');
      }
    } else {
      if (data.errors) {
        yield put(refreshTokenFailure('error if data is null'));
      }
    }
  } catch (error) {
    yield put(refreshTokenFailure(error));
  }
}

export default function* editProfileSaga() {
  yield takeEvery(GET_USER_INFO_REQUEST, getuserinfo);
  yield takeEvery(EDIT_PROFILE_REQUEST, editProfile);
  yield takeEvery(RESET_PASSWORD_REQUEST, resetpassowrd);
  yield takeEvery(LOGIN_REQUEST, login);
  yield takeEvery(IS_CONNECTED_REQUEST, isConnected);
  yield takeEvery(REGISTER_REQUEST, register);
  yield takeEvery(FORGET_PASSWORD_REQUEST, forgetpassowrd);
  yield takeEvery(LOGIN_WITH_GOOGLE_REQUEST, loginWithGoogle);
  yield takeEvery(REGISTER_WITH_GOOGLE_REQUEST, registerWithGoogle);
  yield takeEvery(VERIF_CODE_REQUEST, VerifCode);
  yield takeEvery(IS_VERIFIED_REQUEST, isVerified);
  yield takeEvery(REFRESH_TOKEN_REQUEST, refreshToken);
  yield takeEvery(LOGOUT_REQUEST, logout);
}
