export const facebook = {
  FACEBOOK_APP_ID: "1188499042109576",
  FACEBOOK_APP_REDIRECT_STATIC: "https://app.live-metrics.io/integrations",
  FACEBOOK_APP_REDIRECT_LOCAL: "http://localhost:3000/integrations",

};
export const google = {
  GOOGLE_CLIENT_ID: "109830448513-0uifdqhirlplbn24h27efitd4fkoap7m.apps.googleusercontent.com",
 
};
export const Exchange_Rate_Api={
  first: "https://v6.exchangerate-api.com/v6/9e91b95ab63f4e694ca8e453/latest/",
  second: "https://v6.exchangerate-api.com/v6/41f09c0c77ffa0a2ec9acbd2/latest/",
  third: "https://v6.exchangerate-api.com/v6/4b722e5c2ee772764bc9548c/latest/",
}

export const  URL_Setup ={
  URL: "app.live-metrics.io",
  IP:"149.202.92.140",
}