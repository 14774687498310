import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import avatar from '../../../assets/images/users/avatar-1.jpg';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfoRequest, logoutRequest } from '../../../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import {  Link } from 'react-router-dom';

export const logout = () => {
  localStorage.clear();
  window.location.replace('/');
};

const ProfileMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedOut = () => {
    dispatch(logoutRequest());
    logout();
  };

  const user = useSelector((state) => state.editProfile);

  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState('Admin');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      setUsername(obj.username);
    }
  }, [props.success]);

  useEffect(() => {
    dispatch(getUserInfoRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="avatar-md rounded-circle img-thumbnail"
            src={user.user.Image ? user.user.Image : avatar}
            alt="Header Avatar"
            style={{
              padding: '5px', // Space between border and image
              width: '49px', // Adjust width for responsiveness
              height: '49px', // Adjust height for responsiveness
              border: '2px solid #0080F9', // Blue border around the avatar
            }}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end" style={{ width: '250px' }}>
          {/* User Information */}
          <div className="d-flex align-items-center">
            <img
              className="rounded-circle"
              src={user.user.Image ? user.user.Image : avatar}
              alt="User Avatar"
              style={{
                border: '2px solid #0080F9', // Blue border around the avatar
                width: '64px', // Adjust width for responsiveness
                height: '64px', // Adjust height for responsiveness
                marginRight: '15px', // Space between avatar and user info
                padding: '3px', // Space between border and image
              }}
            />

            <div className="user-info">
              <p
                className="mb-0"
                style={{ fontSize: '14px', fontWeight: '600' }}
              >
                {username}
              </p>
              <p
                className="mb-1"
                style={{ fontSize: '12px', color: '#A5A5A5' }}
              >
                {user.user.email}
              </p>
              <Link
                to="/settings?tab=2"
                style={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#0064E0',
                  textDecoration: 'none',
                }}
              >
                {props.t('Edit profile')}
              </Link>
            </div>
          </div>

          {/* Plan Information */}
          <div
            className="d-flex justify-content-between align-items-center p-2 mb-2"
            style={{ backgroundColor: '#F5F5FF', marginTop: '10px' }}
          >
            <div>
              <span className="d-block" style={{ fontSize: '10px' }}>
                {props.t('Current plan')}
              </span>
              <span
                className="d-block"
                style={{ fontSize: '12px', fontWeight: '600' }}
              >
                {user.user.PlanType}
              </span>
            </div>
            <Link to="/settings?tab=1">
              <Button
                style={{
                  height: '35px', // Smaller height to match the compact design
                  padding: '0 15px', // Adjust padding for a compact button
                  fontSize: '14px', // Smaller font size
                  backgroundColor: '#0080F9',
                  border: 'none',
                }}
              >
                {props.t('Upgrade')}
              </Button>
            </Link>
          </div>

          {/* Menu Options */}
          <DropdownItem
            className="text-muted"
            onClick={() => navigate('/settings?tab=1')}
          >
            <i className="bx bx-receipt font-size-16 align-middle me-1" />
            <span>{props.t('Subscription')}</span>
          </DropdownItem>
          <DropdownItem
            className="text-muted"
            onClick={() => navigate('/settings?tab=2')}
          >
            <i className="bx bx-user-circle font-size-16 align-middle me-1" />
            <span>{props.t('Profile')}</span>
          </DropdownItem>
          <DropdownItem
            className="text-muted"
            onClick={() => navigate('/settings?tab=3')}
          >
            <i className="bx bx-credit-card font-size-16 align-middle me-1" />
            <span>{props.t('Pricing')}</span>
          </DropdownItem>
          <DropdownItem
            className="text-muted"
            onClick={() => navigate('/settings?tab=4')}
          >
            <i className="bx bx-group font-size-16 align-middle me-1" />
            <span>{props.t('Members')}</span>
          </DropdownItem>
          <DropdownItem
            className="text-muted border-bottom"
            onClick={() => navigate('/settings?tab=5')}
          >
            <i className="bx bx-cog font-size-16 align-middle me-1" />
            <span>{props.t('Settings')}</span>
          </DropdownItem>

          {/* Logout Option */}
          <DropdownItem onClick={isLoggedOut} className="text-muted">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Log out')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default (withTranslation()(ProfileMenu));
