import React from 'react';
import { Navigate } from 'react-router-dom';

// Utility Pages
import PageMaintenance from '../pages/Utility/PageMaintenance';
import Error404 from '../pages/Utility/Error404';
import Error500 from '../pages/Utility/Error500';

// Authentication
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import TwoStepVerification from '../pages/Authentication/TwoStepVerification';

// Accounts
import Settings from '../pages/Accounts/Settings';
import Members from '../pages/Accounts/Members/Members';

// E-Commerce & Dashboard Pages
import PrestaShopDashboard from '../pages/PrestaDashbaord';
import AllProducts from '../pages/PrestaDashbaord/Products/AllProducts';
import AllOrders from '../pages/PrestaDashbaord/Orders/AllOrders';
import WooDashboard from '../pages/WooDashbaord';
import WooCoupons from '../pages/WooDashbaord/WooCoupons/wooCoupons';
import WooTopSellers from '../pages/WooDashbaord/WooTopSellers/WooTopSellers';
import AramexTracking from '../pages/AramexDashboard/index';
import Integrationns from '../pages/Integrations';

// Ads & Analytics
import GoogleDashboard from '../pages/GoogleDashboard/Google Ads Overview/index';
import GoogleCampaignsDetails from '../pages/GoogleDashboard/googleCampaigns Details/index';
import Adstable from '../pages/GoogleDashboard/adTable';
import Campaignstable from '../pages/GoogleDashboard/campaignTable';
import Keywordstable from '../pages/GoogleDashboard/keywordsTable';
import FbSocialMediaDashboard from '../pages/facebookSocialMediaDashboard/fbSocialMediaDashboard';
import InstagramDashboard from '../pages/InstagramDashboard/InstagrammDashboard';
import MetaAds from '../pages/Socials/MetaAds';

// Reports & Analytics
import GaUsers from '../pages/GoogleAnalytics/gaUsers/GAUsers';
import GAAudience from '../pages/GoogleAnalytics/gaAudience';
import GaEcommerceAffiliations from '../pages/GoogleAnalytics/gaEcommerceAffiliations';
import GoogleAnalyticsReportDetail from '../pages/GoogleAnalytics/gaOverview/GoogleAnalyticsReportDetail';
import GAOverview from '../pages/GoogleAnalytics/gaOverview/index';

// Advisor
import Advisor from '../pages/Advisor/Advisor';
import Session from '../pages/Advisor/Session';

// Reports & Finance
import Operations from '../pages/Reports/Operations';
import Strategic from '../pages/Reports/Strategic';
import NProfitandLossTable from '../pages/Reports/NProfitAndLoss';
import Costs from '../pages/Costs/Costs';

// Socials & Ads
import Socials from '../pages/Socials/Socials';
import CampaignsDetails from '../pages/Socials/CampaignsDetails';
import Fbcampaigns from '../pages/Socials/campaignTable';

// Email Builder
import EmailBuilder from '../pages/EmailBuilder/EmailEditor';

// Overview
import OverviewDashboard from '../pages/OverviewDashboard';
import MagentoProducts from '../pages/Magento/MagentoProducts';
import MagentoDashboard from '../pages/Magento/Dashboard/MagentoDashboard';






const userRoutes = [
  { path: '/integrations', element: <Integrationns /> },
  { path: '/settings', element: <Settings /> },
  { path: '/settings-members', element: <Members /> },

  // Ads Dashboard
  { path: '/ads-dashboard', element: <Socials /> },

  // PrestaShop Dashboard
  { path: '/prestashop-dashboard', element: <PrestaShopDashboard /> },
  { path: '/prestashop-all-products', element: <AllProducts /> },
  { path: '/prestashop-all-orders', element: <AllOrders /> },

  // Advisor
  { path: '/advisor', element: <Advisor /> },
  { path: '/advisor/:sessionId', element: <Session /> },

  // WooCommerce Dashboard
  { path: '/woo_dashboard', element: <WooDashboard /> },
  { path: '/woo_coupons', element: <WooCoupons /> },
  { path: '/woo_top_sellers', element: <WooTopSellers /> },

  // Aramex Dashboard
  { path: '/Aramex-Dashboard', element: <AramexTracking /> },

  // Reports & Finance
  { path: '/reports-opertations', element: <Operations /> },
  { path: '/reports-strategic', element: <Strategic /> },
  { path: '/reports-pnl', element: <NProfitandLossTable /> },

  // Google Analytics
  { path: '/google-analytics-users', element: <GaUsers /> },
  { path: '/google-analytics-audience', element: <GAAudience /> },
  { path: '/GoogleAnalyticsReportDetail', element: <GoogleAnalyticsReportDetail /> },
  { path: '/google-analytics-ecommerce-affiliation', element: <GaEcommerceAffiliations /> },
  { path: '/google-analytics', element: <GAOverview /> },

  // Overview Dashboard
  { path: '/overview-dashboard', element: <OverviewDashboard /> },
  //magento 
  {path: '/magento-dashboard', element: <MagentoDashboard/>},
  { path: '/magento-products', element: <MagentoProducts />},

  // Costs
  { path: '/costs', element: <Costs /> },

  // Google Dashboard
  { path: '/google-dashboard', element: <GoogleDashboard /> },
  { path: '/GoogleCampaignsDetails', element: <GoogleCampaignsDetails /> },
  { path: '/google-campaigns-details', element: <Campaignstable /> },
  { path: '/google-ads-details', element: <Adstable /> },
  { path: '/google-keywords-details', element: <Keywordstable /> },

  // Facebook & Instagram Ads
  { path: '/fb-campaigns', element: <Fbcampaigns /> },
  { path: '/fb-campaigns-details', element: <CampaignsDetails /> },
  { path: '/fb-social-media-dashboard', element: <FbSocialMediaDashboard /> },
  { path: '/InstagramDashboard', element: <InstagramDashboard /> },
  { path: '/MetaAds', element: <MetaAds /> },

  // Email Builder
  { path: '/email-builder', element: <EmailBuilder /> },

  // Default Redirect
  { path: '/', element: <Navigate to="/integrations" replace /> }
];

const authRoutes = [
  { path: '/logout', element: <Logout /> },
  { path: '/login', element: <Login /> },
  { path: '/forgot-password', element: <ForgetPwd /> },
  { path: '/register', element: <Register /> },
  { path: '/verif/:id/:verifCode', element: <TwoStepVerification /> },

  // Utility Pages
  { path: '/pages-maintenance', element: <PageMaintenance /> },
  { path: '/pages-404', element: <Error404 /> },
  { path: '/pages-500', element: <Error500 /> }
];

export { userRoutes, authRoutes };
