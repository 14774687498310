/* eslint-disable array-callback-return */
import { takeLatest, put, call } from "redux-saga/effects";
import {
  GetTrackingStatus,
  GetTrackingNumbers,
  getall,
  checkSales,
  deleteAramexIntegration,
  addingAramexAccount,
} from "../../queries/Aramex/Queries";
import { alert, successAlert } from "../../pages/Alerts/NotLinkedAccountAlert";
import urls from "../../routes/apiUrls";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

import {
  CHECK_INTEGRATION_REQUEST,
  checkIntegrationSuccess,
  checkIntegrationFailure,
  FETCH_DATA_REQUEST,
  fetchDataSuccess,
  fetchDataFailure,

  DELETE_INTEGRATION_ARAMEX_REQUEST,
  deleteIntegrationAramexSuccess,
  deleteIntegrationAramexFailure,
  INTEGRATION_ARAMEX_REQUEST,
  integrationAramexSuccess,
  integrationAramexFailure,
} from "./action";

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.Aramex,
    headers: {
      Authorization: window.localStorage.getItem("token")
        ? `Bearer ${window.localStorage.getItem("token")}`
        : "",
    },
    credentials: "include", // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function* checkIntegration() {
  try {
    const { data } = yield call(ApploCLI.query, {
      query: checkSales,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });
    if (data.get == null) {
      alert().then((data) => {
        window.location.replace("/integrations");
      });
      yield put(checkIntegrationFailure());
    } else {
      yield put(checkIntegrationSuccess(data.get, "active"));
    }
  } catch (error) {
    yield put(checkIntegrationFailure(error));
  }
}

function* fetchData(action) {
  try {
    const { data } = yield call(ApploCLI.query, {
      query: getall,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    let ShipmentChargesPaid = 0;
    let SupportingDocumentReturnedtoShipper = 0;
    let ReturnedtoShipper = 0;
    let Delivered = 0;
    let emailofreturnedshipper = [];
    console.log(data, "data**********");
    if (data && data.getAramexData && data.getAramexData.length > 0) {
      data.getAramexData.forEach((item) => {
          if (item.state === "Shipment charges paid") {
            ShipmentChargesPaid++;
          } else if (
            item.state ===
            "Supporting Document Returned to Shipper"
          ) {
            SupportingDocumentReturnedtoShipper++;
          } else if (item.state === "Returned to Shipper") {
            // emailofreturnedshipper.push(item.email);
            ReturnedtoShipper++;
          } else if (item.state === "Delivered") {
            Delivered++;
          }
        });
    
    }
    yield put(
      fetchDataSuccess(
        data.getAramexData,
        ShipmentChargesPaid,
        SupportingDocumentReturnedtoShipper,
        ReturnedtoShipper,
        Delivered,
        emailofreturnedshipper
      )
    );

    // yield put(fetchDataSuccess(data.AramexAll));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}
function* deleteIntegrationAramex(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: deleteAramexIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    if (data) {
      yield put(deleteIntegrationAramexSuccess(data));
      successAlert().then(() => {
        window.location.replace("/integrations");
      });
    }
  } catch (error) {
    yield put(deleteIntegrationAramexFailure(error));
  }
}
function* integrationAramex(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: addingAramexAccount,
      variables: {
        UserName: action.payload.UserName,
        Password: action.payload.Password,
        AccountNumber: action.payload.AccountNumber,
        AccountPin: action.payload.AccountPin,
        AccountEntity: action.payload.AccountEntity,
        AccountCountryCode: action.payload.AccountCountryCode,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",

      fetchPolicy: "no-cache",
    });

    if (data) {
      yield put(integrationAramexSuccess(data));
      window.location.replace("/integrations");
    }
  } catch (error) {
    yield put(integrationAramexFailure(error));
  }
}

export default function* getAllTrackingStatussaga() {
  yield takeLatest(FETCH_DATA_REQUEST, fetchData);
  yield takeLatest(CHECK_INTEGRATION_REQUEST, checkIntegration);
  yield takeLatest(DELETE_INTEGRATION_ARAMEX_REQUEST, deleteIntegrationAramex);
  yield takeLatest(INTEGRATION_ARAMEX_REQUEST, integrationAramex);
}
