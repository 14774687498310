import React, { useState } from 'react';
import { setImage } from '../../../store/printState/actions';

import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useDispatch } from 'react-redux';
const { Dragger } = Upload;

const BrandImage = () => {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(null);
  const props = {
    maxCount: 1,
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('You can only upload image files!');
      }
      return isImage || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    onChange(info) {
      const { status, originFileObj } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        if (originFileObj) {
          // Convert file to a Base64 DataURL
          const reader = new FileReader();
          reader.onload = (e) => {
            dispatch(setImage(e.target.result));
          };
          reader.readAsDataURL(originFileObj);
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove() {
      setImageUrl(null);
    },
  };

  return (
    <div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Use Your Brand Image And Upload Here To Print Your Brand Image
        </p>
      </Dragger>
      {imageUrl && (
        <div style={{ marginTop: 16, textAlign: 'center' }}>
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{ maxWidth: '100%', maxHeight: 200 }}
          />
        </div>
      )}
    </div>
  );
};

export default BrandImage;
