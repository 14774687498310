import React, { useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { logoMap } from "../../assets/integrations_images/logoMap";
import BestItemsCard from "../../components/Widget/BestItemsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMagentoBestProductsRequest,
  fetchMagentoProductsRequest,
  getTotalProductsCountRequest,
} from "../../store/magento/actions";
import { Table } from "antd";
import { formatDate } from "../GoogleAnalytics/common/HelperFunctions/utils";

const MagentoProducts = () => {
  const dispatch = useDispatch();
  const { productsCount, products, getProductsLoading, bestSellers } =
    useSelector((state) => state.magentoReducer);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const transformedData = bestSellers.map((item) => {
    return Object.keys(item)
      .filter((key) => key !== "id" && key !== "__typename") // Exclude unwanted keys
      .map((key) => ({
        label: key,
        value: item[key],
      }));
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];
  useEffect(() => {
    dispatch(getTotalProductsCountRequest());
    dispatch(fetchMagentoProductsRequest(1, 10));
  }, []);

  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);

      dispatch(fetchMagentoBestProductsRequest(newStartDate, newEndDate));
    }
  },[dateRange]);

  const handlePaginationChange = (page) => {
    dispatch(fetchMagentoProductsRequest(page.current, page.pageSize));
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb
          image={logoMap["Magento"]}
          title="Products / BestSellers"
          breadcrumbItem="Products"
          pageHeading="Magento"
        />

        <div
          className="row justify-content-center"
          style={{ gap: "20px", marginBottom: "30px" }}
        >
          {transformedData.map((product, index) => (
            <BestItemsCard item={product} index={index} />
          ))}
        </div>

        <div
          className="row justify-content-center"
          style={{ gap: "20px", marginBottom: "30px" }}
        >
          <Table
            loading={getProductsLoading}
            dataSource={products}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              total: productsCount,
                style: {
                  backgroundColor: '#F4F2FF',
                  padding: '10px 15px',
                  marginTop: '2px',
                  borderRadius: '6px',
                  fontsize: '14px',
                  fontweight: '700',
                  color: '#6E6893',
                },
              }}
              headerCellStyle={{
                backgroundColor: '#FF0000', // Change this to your desired color
                color: '#333333', // Text color
              
            }}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MagentoProducts;
