import React, { useEffect, useRef, useState } from "react";
import {  Spin, Tooltip } from "antd";
import { Container, Row, Col,  } from "reactstrap";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages, sendMessage } from "../../store/AdvisorState/actions";
import RenderMessage from "./RenderMessage";
import chatboticon from "../../assets/images/chatbot-icon.png";
import MessageInputBar from "./MessageInputBar";

const Session = () => {
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { messages, loadingMessages, botTyping } = useSelector((state) => state.AdvisorReducer);

  const [message, setMessage] = useState("");
  const [renderKey, setRenderKey] = useState(0); // 🔥 Re-render key
  const [typingMessage, setTypingMessage] = useState(""); // Message being typed
  const [currentBotMessage, setCurrentBotMessage] = useState(null); // The latest bot response

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  // Fetch messages when session starts
  useEffect(() => {
    if (sessionId) {
      dispatch(fetchMessages(sessionId));
    }
  }, [sessionId, dispatch]);

  // Auto-scroll to bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, renderKey]);

  // Simulate chatbot typing effect
  useEffect(() => {
    if (currentBotMessage) {
      let index = 0;
      setTypingMessage(""); // Reset the typing message

      const interval = setInterval(() => {
        setTypingMessage((prev) => prev + currentBotMessage.charAt(index));
        index++;

        if (index >= currentBotMessage.length) {
          clearInterval(interval);
          setCurrentBotMessage(null); // Reset bot message state
          setRenderKey((prev) => prev + 1); // 🔥 Force re-render
        }
      }, 50);
    }
  }, [currentBotMessage]);

  // Handle sending user messages
  const handleSendMessage = () => {
    if (message.trim()) {
      dispatch(sendMessage(message, sessionId));
      setMessage(""); // Clear input
    }
  };

  return (
    <div className="page-content" style={{ padding: "20px 0", height: "100vh", display: "flex", flexDirection: "column" }}>
      <Container fluid className="d-flex flex-column flex-grow-1">
        <Row className="flex-grow-1">
          <Col>
            <Spin spinning={loadingMessages} tip="Loading messages...">
              <div ref={messagesContainerRef} style={{ overflowY: "auto", maxHeight: "85vh" }}>
                <ul className="list-unstyled" style={{ padding: "20px 209px", marginTop: "50px", fontWeight: "500" }}>
                  {messages.map((msg, index) => (
                    <li
                      key={`${msg.type}-${index}-${renderKey}`} // Ensure unique keys
                      className={`d-flex ${msg.type === "user" ? "justify-content-end" : "justify-content-start align-items-start"} mb-3`}
                    >
                      {msg.type !== "user" && <img src={chatboticon} alt="Chatbot Icon" style={{ marginTop: "13px" }} />}
                      <div
                        className={`${msg.type === "user" ? "bg-user-message" : "text-dark rounded"}`}
                        style={{
                          fontSize: "15px",
                          maxWidth: "80%",
                          lineHeight: "2",
                        }}
                      >
                        <RenderMessage msg={msg} />
                      </div>
                    </li>
                  ))}

                  {/* 🔹 Show Typing Animation While Bot is Responding */}
                  {botTyping && (
                    <li className="d-flex justify-content-start align-items-start mb-3">
                      <div style={{ marginRight: "10px" }}>
                        <img src={chatboticon} alt="Chatbot Icon" />
                      </div>
                      <div className="chat-bubble">
                        <div className="typing">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </div>
                    </li>
                  )}

                  {/* 🔹 Show the Typing Effect Letter-by-Letter */}
                  {typingMessage && (
                    <li className="d-flex justify-content-start align-items-start mb-3">
                      <img src={chatboticon} alt="Chatbot Icon" />
                      <div
                        className="text-dark"
                        style={{
                          fontSize: "14px",
                          maxWidth: "80%",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          lineHeight: "1.8",
                        }}
                      >
                        {typingMessage} {/* Shows animated bot message */}
                      </div>
                    </li>
                  )}

                  <div ref={messagesEndRef} />
                </ul>
              </div>
            </Spin>
          </Col>
        </Row>

        <MessageInputBar
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          botTyping={botTyping}
        />
      </Container>
    </div>
  );
};

export default Session;
