import React from 'react';
import './ActiveAccountBar.css';

import { useNavigate } from 'react-router-dom'
const ActiveAccountBar = ({ accountName, switchTabNumber=1 }) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center align-items-center mb-4 ">
      <div className="account-banner">
        <div className="account-info">
          <span className="active-dot"></span>
          <span className="account-text">Active Account: {accountName}</span>
        </div>
        <button
          className="switch-account-btn"
          onClick={() => {
            localStorage.setItem('activeTab', switchTabNumber);
            navigate('/integrations');
          }}
        >
          Switch account
        </button>
      </div>
    </div>
  );
};

export default ActiveAccountBar;
