import React, { useState } from 'react';
import {
    Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import SalesPlatformsCatalog from './SalesPlatformsCatalog';
import AdsPlatformsCatalog from './AdsPlatformsCatalog';
import TrackingPlatformsCatalog from './TrackingPlatormsCatalog';
import TrackshipmentsCatalog from './TrackshipmentsCatalog';
import secureSection from '../../MangeSecurity/MemberSecurity';

const Integration = (props) => {
    // Secure Section
    secureSection();

    // Manage Tabs with State
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            localStorage.setItem('activeTab', tab);
        }
    };

    // Tab Components Mapping
    const tabComponents = {
        "1": <SalesPlatformsCatalog />,
        "2": <AdsPlatformsCatalog />,
        "4": <TrackingPlatformsCatalog />,
        "5": <TrackshipmentsCatalog />
    };

    // Simplified Styles for Nav Links
    const navLinkStyle = (active) => ({
        backgroundColor: active ? "#0075FF" : "#F4F2FF",
        color: active ? "#fff" : "#6c757d",
        borderRadius: "8px",
        padding: "10px 20px",
        minWidth: "180px",
        textAlign: "center",
        whiteSpace: "nowrap",
        cursor: "pointer"
    });

    return (
        <React.Fragment>
            <div className="page-content">
                    <title>{props.t("Integrations")} |LiveMetrics - E-com SaaS</title>
                <Container fluid className='p-4'>
                    <Row>
                        <Col lg={12}>
                            <Card style={{ border: "none" }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h4 className="card-title mb-0"
                                        style={{
                                            color: "#000",
                                            fontSize: "15px",
                                            fontWeight: "700",
                                            lineHeight: "19.53px",
                                            marginBottom: '0'
                                        }}>
                                        {props.t("Integrations")}
                                    </h4>
                                    <Nav pills className="navtab-bg nav-justified custom-nav" style={{ marginLeft: 'auto' }}>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => toggleTab("1")}
                                                style={navLinkStyle(activeTab === "1")}
                                            >
                                                {props.t("Sales Platforms")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => toggleTab("2")}
                                                style={navLinkStyle(activeTab === "2")}
                                            >
                                                {props.t("Ads / Social Platforms")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => toggleTab("4")}
                                                style={navLinkStyle(activeTab === "4")}
                                            >
                                                {props.t("Tracking Platforms")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === "5" })}
                                                onClick={() => toggleTab("5")}
                                                style={navLinkStyle(activeTab === "5")}
                                            >
                                                {props.t("Cash On Delivery")}
                                                <span className="badge rounded-pill bg-danger float-end">New</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                
                                {/* Line between tabs and content */}
                                <hr /> 

                                <div className='mt-4'>
                                    <TabContent activeTab={activeTab}>
                                        {tabComponents[activeTab]}
                                    </TabContent>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default (withTranslation()(Integration));
