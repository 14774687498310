import React from "react";
import { logoMap } from "../../assets/integrations_images/logoMap";
import { Table } from "antd";

const AramexTable = ({ data ,isLoading}) => {
  console.log("data", data);
 const columns = [
    { title: "Order Id", dataIndex: "orderId", key: "orderId" },

    {
      title: "Tracking Number",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },

    {
      title: "Update Code",
      dataIndex: "updateCode",
      key: "updateCode",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
    },
  ];
  return (
    <div className="mb-3">
      <div className="d-flex justify-content-start align-content-center gap-2 px-3">
        <img src={logoMap["Aramex Integration"]} alt="Aramex" width={100} height={20} />
        <p>Tracking Order </p>
      </div>
      <Table columns={columns} dataSource={data} loading={isLoading}    pagination={{
           
            
            style: {
              backgroundColor: '#F4F2FF',
              padding: '10px 15px',
              marginTop: '2px',
              borderRadius: '6px',
              fontSize: '14px',
              fontWeight: '700',
              color: '#6E6893',
            },
          }}/>
    </div>
  );
};

export default AramexTable;
