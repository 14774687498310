import { SET_IMAGE, SET_INTEGRATIONS, SET_RECOMMANDATION } from './actions';

const initialState = {
  recommandation: '',
  image: '',
  integrations: [
    { title: 'store', checked: true },
    { title: 'Google Ads', checked: true },
    { title: 'Meta Ads', checked: true },
    { title: 'Facebook Social Media', checked: true },
    { title: 'Instagram Social Media', checked: true },
  ],
};
const printReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
      };
    case SET_RECOMMANDATION:
      return {
        ...state,
        recommandation: action.payload,
      };
    default:
      return state;
  }
};

export default printReducer;
