import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChartVertical = ({ dataSeries }) => {
  const series = dataSeries.map((dataSet) => ({
    name: dataSet.name,
    data: dataSet.data,
  }));
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '50%',
        borderRadiusApplication: 'top',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: 'middle',
      formatter: function (val, opts) {
        // Access retention rate from the correct dataset
        const retentionRate =
          dataSeries[opts.seriesIndex].retentionRate[opts.dataPointIndex];
        return `${retentionRate} %`;
      },

      offsetY: -40,
      style: {
        fontSize: '24px',
        colors: ['#FF925B'],
        fontFamily: 'DM Sans, sans-serif',
      },
    },
    colors: ['#FF7F3E', '#0044BD'],

    xaxis: {
      categories: dataSeries[0].categories,
      position: 'bottom',
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: 'black',
          fontWeight: 300,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
      crosshairs: {
        // when we hover over the bar
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },

    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        align: 'center',
        style: {
          colors: 'black',
          fontWeight: 300,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default BarChartVertical;
