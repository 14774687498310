
import React, { useState, useEffect } from 'react';
import { Button, Table, Popconfirm, Input, DatePicker, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import { ApolloClient, InMemoryCache, HttpLink  } from '@apollo/client';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getCosts,
  addCost,
  editCost,
  deleteSingleCost,
} from '../../queries/Costs/Costs';
import urls from '../../routes/apiUrls';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CostsIcon from '../../assets/images/CostsIcon.svg';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.cost,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});
const Costs = (props) => {
  const [costs, setCosts] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [originalCostData, setOriginalCostData] = useState(null); // State to track original cost data

  useEffect(() => {
    fetchCosts();
  }, []);

  const fetchCosts = () => {
    ApploCLI.query({
      query: getCosts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    }).then((data) => {
      if (data.data.all.length > 0) {
        setCosts(data.data.all);
      }
    });
  };

  const handleAddNewCost = () => {
    setIsAdding(true);
    const newCost = {
      id: `${costs.length + 1}`,
      name: '',
      category: '',
      frequency: '',
      activeDate: null,
      endedDate: null,
      price: '',
      isNew: true,
    };
    setCosts([newCost, ...costs]);
    setEditingKey(newCost.id);
  };

  const handleSaveNewCost = (newCost) => {
    setIsSaving(true);
    ApploCLI.mutate({
      mutation: addCost,
      variables: {
        title: newCost.name,
        category: newCost.category,
        frequency: newCost.frequency,
        startedDate: newCost.activeDate || null,
        endedDate: newCost.endedDate || null,
        price: parseFloat(newCost.price),
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    }).then(() => {
      fetchCosts();
      setIsAdding(false);
      setIsSaving(false);
      setEditingKey('');
      toast.success('Cost added successfully!');
    }).catch((error) => {
      setIsSaving(false);
      console.error('Error adding cost:', error);
      toast.error('Failed to add cost!');
    });
  };

  const handleDelete = (id) => {
    ApploCLI.mutate({
      mutation: deleteSingleCost,
      variables: { id },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    }).then(() => {
      setCosts(costs.filter((cost) => cost.id !== id));
      toast.success('Cost deleted successfully!');
    });
  };

  const handleEditSave = (updatedCost) => {
    const oldTitle = costs.find((item) => item.id === updatedCost.id)?.name;

    setIsSaving(true);
    ApploCLI.mutate({
      mutation: editCost,
      variables: {
        oldTitle,
        title: updatedCost.name,
        category: updatedCost.category,
        frequency: updatedCost.frequency,
        startedDate: updatedCost.activeDate
          ? moment(updatedCost.activeDate, 'YYYY-MM-DD').isValid()
            ? moment(updatedCost.activeDate).format('YYYY-MM-DD')
            : null
          : null,
        endedDate: updatedCost.endedDate
          ? moment(updatedCost.endedDate, 'YYYY-MM-DD').isValid()
            ? moment(updatedCost.endedDate).format('YYYY-MM-DD')
            : null
          : null,
        price: parseFloat(updatedCost.price),
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    })
    .then(() => {
      fetchCosts();
      setEditingKey('');
      setIsSaving(false);
      toast.success('Cost updated successfully!');
    })
    .catch((error) => {
      setIsSaving(false);
      console.error('Error updating cost:', error);
      toast.error('Failed to update cost!');
    });
  };

  const handleCancel = () => {
    if (isAdding) {
      setCosts((prevCosts) => prevCosts.filter((cost) => !cost.isNew));
      setIsAdding(false);
    } else if (originalCostData) {
      setCosts((prevCosts) => 
        prevCosts.map((cost) => 
          cost.id === originalCostData.id ? originalCostData : cost
        )
      );
    }
    setEditingKey('');
    setOriginalCostData(null);
  };

  const handleEdit = (record) => {
    setOriginalCostData({ ...record }); // Store the original data
    setEditingKey(record.id);
  };

  const isEditing = (record) => record.id === editingKey;

  const handleInputChange = (e, record, key) => {
    const updatedCosts = costs.map((item) => {
      if (item.id === record.id) {
        return { ...item, [key]: e.target.value };
      }
      return item;
    });
    setCosts(updatedCosts);
  };

  const handleDateChange = (date, record, key) => {
    const updatedCosts = costs.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [key]: date && date.isValid() ? date.format('YYYY-MM-DD') : null,
        };
      }
      return item;
    });
    setCosts(updatedCosts);
  };

  const handleSelectChange = (value, record, key) => {
    const updatedCosts = costs.map((item) => {
      if (item.id === record.id) {
        return { ...item, [key]: value };
      }
      return item;
    });
    setCosts(updatedCosts);
  };

  const columns = [
    {
      title: props.t('TITLE'),
      dataIndex: 'name',
      width: '20%',
      editable: true,
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <Input
            value={record.name}
            onChange={(e) => handleInputChange(e, record, 'name')}
            style={{ width: '200px', height: '45px' }}
            placeholder="Enter title"
            className='table-cell-text'
          />
        ) : (
          text
        ),
    },
    {
      title: props.t('CATEGORY'),
      dataIndex: 'category',
      width: '10%',
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <Select
            value={record.category || undefined}
            onChange={(value) => handleSelectChange(value, record, 'category')}
            options={[
              { label: 'Marketing', value: 'Marketing' },
              { label: 'Advertising', value: 'Advertising' },
              { label: 'Salaries', value: 'Salaries' },
              { label: 'Outsourcing', value: 'Outsourcing' },
              { label: 'Subscription', value: 'Subscription' },
            ]}
            style={{ width: '150px', height: '45px' }}
            placeholder="Example: Marketing"
          />
        ) : (
          text
        ),
    },
    {
      title: props.t('FREQUENCY'),
      dataIndex: 'frequency',
      width: '10%',
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <Select
            value={record.frequency || undefined}
            onChange={(value) => handleSelectChange(value, record, 'frequency')}
            options={[
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Bi-weekly', value: 'Bi-weekly' },
            ]}
            style={{ width: '150px', height: '45px' }}
            placeholder="Example: Daily"
          />
        ) : (
          text
        ),
    },
    {
      title: props.t('ACTIVE DATE'),
      dataIndex: 'activeDate',
      width: '15%',
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <DatePicker
            value={
              record.activeDate
                ? moment(record.activeDate, 'YYYY-MM-DD')
                : null
            }
            onChange={(date) => handleDateChange(date, record, 'activeDate')}
            style={{ width: '150px', height: '45px' }}
          />
        ) : record.activeDate ? (
          moment(parseInt(record.activeDate)).format('YYYY-MM-DD')
        ) : (
          ''
        ),
    },
    {
      title: props.t('ENDED DATE'),
      dataIndex: 'endedDate',
      width: '15%',
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <DatePicker
            value={
              record.endedDate
                ? moment(record.endedDate, 'YYYY-MM-DD')
                : null
            }
            onChange={(date) => handleDateChange(date, record, 'endedDate')}
            style={{ width: '150px', height: '45px' }}
          />
        ) : record.endedDate ? (
          moment(parseInt(record.endedDate)).format('YYYY-MM-DD')
        ) : (
          ''
        ),
    },
    {
      title: props.t('PRICE'),
      dataIndex: 'price',
      width: '15%',
      className:'table-cell-text',
      render: (text, record) =>
        isEditing(record) ? (
          <Input
            type="number"
            value={record.price}
            onChange={(e) => handleInputChange(e, record, 'price')}
            style={{ width: '130px', height: '45px' }}
            placeholder="Example: 100"
          />
        ) : (
          text
        ),
    },
    {
      title: props.t('ACTION'),
      dataIndex: 'action',
      width: '15%',
      className:'table-cell-text',
      render: (_, record) =>
        isEditing(record) ? (
          <>
            <Button
              className="savebutton"
              onClick={() =>
                record.isNew
                  ? handleSaveNewCost(record)
                  : handleEditSave(record)
              }
              disabled={isSaving}
            >
              {record.isNew ? 'Save' : 'Update'}
            </Button>
            <Button className="cancelbutton" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button className="editbutton" onClick={() => handleEdit(record)}>
              Edit
            </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button className="deletebutton">Delete</Button>
            </Popconfirm>
          </>
        ),
    },
  ];

  return (
    <div className="page-content">
      <Breadcrumbs
        title={props.t('Costs')}
        breadcrumbItem={props.t('Miscellaneous Costs')}
        pageHeading={props.t('Reports')}
        image={CostsIcon}
      />
      <Button
        type="primary"
        onClick={handleAddNewCost}
        style={{ marginBottom: '16px' }}
        disabled={isAdding || isSaving}
      >
        {props.t('+ Add Cost')}
      </Button>
      <Table
        columns={columns}
        dataSource={costs}
        rowKey={(record) => record.id}
        rowClassName={(record) => (isEditing(record) ? 'editable-row' : '')}
        pagination={false}
        style={{ borderRadius: '10px' }}
      />
      <ToastContainer />
    </div>
  );
};

export default (withTranslation()(Costs));
