import React from 'react';
import BestItemsCard from '../../../components/Widget/BestItemsCard';

const CouponSummary = ({ coupons, t }) => {
  console.log('Coupons in summary:', coupons);

  // If there are no coupons, show a message
  if (!coupons || coupons.length === 0) {
    return <p>No coupons available</p>;
  }

  // Extract the coupon data from each item
  const extractedCoupons = coupons.map((couponWrapper) => couponWrapper.coupon);

  // Sort the coupons by usage count to get the top 6
  const topCoupons = extractedCoupons
    .sort((a, b) => b.usage_count - a.usage_count) // Sort by usage_count in descending order
    .slice(0, 6); // Get the top 6

  const transformedData = topCoupons.map((coupon) => [
    { label: 'Code', value: coupon.code },
    { label: 'Amount', value: coupon.amount },
    { label: 'Usage', value: coupon.usage_count },
  ]);

  return (
    <div
      className="row justify-content-center"
      style={{ gap: '20px', marginBottom: '30px' }}
    >
      {transformedData.map((coupon, index) => (
        <BestItemsCard item={coupon} index={index} />
      ))}
    </div>
  );
};

export default CouponSummary;
