import { Table } from 'antd';
import React from 'react';
import moment from 'moment';


const MagentoOrdersTable = ({ orders, loading }) => {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'order_id',
      key: 'id',
      render: (text, record) => (
        <strong className="table-cell-text">{text}</strong>
      ),
    },
    {
      title: 'SubTotal',
      dataIndex: 'sub_total',
      sorter: (a, b) => a.sub_total - b.sub_total,
      key: 'sub_total',
      render: (text, record) => (
        <strong className="table-cell-text">{text}</strong>
      ),
    },
    {
      title: 'Total Paid',
      dataIndex: 'total_paid',
      sorter: (a, b) => a.total_paid - b.total_paid,
      key: 'toal_paid',
      render: (text, record) => (
        <strong className="table-cell-text">{text}</strong>
      ),
    },
    {
      title: 'Total Discount',
      dataIndex: 'total_discounts',
      sorter: (a, b) => a.total_discounts - b.total_discounts,
      key: 'total_discounts',
      render: (text, record) => (
        <strong className="table-cell-text">{-text}</strong>
      ),
    },
    {
      title: 'Total Shipping',
      dataIndex: 'total_shipping',
      sorter: (a, b) => a.total_shipping - b.total_shipping,
      key: 'total_shipping',
      render: (text, record) => (
        <strong className="table-cell-text">{text}</strong>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: 'Created At',
      dataIndex: 'date_add',
      key: 'date_add',
      render: (text) => (
        <strong className="table-cell-text">
          {moment(+text).format('YYYY-MM-DD')}
        </strong>
      ),
    },
  ];
  return (
    <Table
      loading={loading}
      dataSource={orders[0]}
      columns={columns}
      pagination={{
        showSizeChanger: true,
        style: {
          backgroundColor: '#F4F2FF',
          padding: '10px 15px',
          marginTop: '2px',
          borderRadius: '6px',
          fontsize: '14px',
          fontweight: '700',
          color: '#6E6893',
        },
      }}
      headerCellStyle={{
        backgroundColor: '#FF0000', // Change this to your desired color
        color: '#333333', // Text color
      }}
          />
  );
};

export default MagentoOrdersTable;
