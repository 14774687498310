import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { withTranslation } from 'react-i18next';
import SideBarContentElement from './SideBarContentElement';

const SidebarContent = (props) => {
  const ref = useRef();
  const location = useLocation();
  const metisMenuRef = useRef(null);

  const sideBarContent = [
    { name: 'Overview', route: '/overview-dashboard' },
    {
      name: 'Reports',
      children: [
        { name: 'Miscellaneous Costs', route: '/costs' },
        { name: 'Operations', route: '/reports-operations' },
        { name: 'Strategic', route: '/reports-strategic' },
        { name: 'Profit & Loss', route: '/reports-pnl' },
      ],
    },
    {
      name: 'PrestaShop',
      children: [
        { name: 'Presta Dashboard', route: '/prestashop-dashboard'},
        { name: 'Products', route: '/prestashop-all-products' },
      ],
    },
    {
      name: 'WooCommerce',
      children: [
        { name: 'Woo Dashboard', route: '/woo_dashboard' },
        { name: 'Coupons Codes', route: '/woo_coupons' },
        { name: 'Top Sellers', route: '/woo_top_sellers' },
      ],
    },
    {
      name: 'Magento',
      children: [
        {
          name: 'Magento Dashboard',
          route: '/magento-dashboard',
        },
        {
          name: 'Products',
          route: '/magento-products',
        },
      ],
    },
    {
      name: 'Google Ads',
      children: [
        { name: 'Google Overview', route: '/google-dashboard' },
        { name: 'Campaigns', route: '/google-campaigns-details' },
        { name: 'Ads', route: '/google-ads-details' },
        { name: 'Keywords Performance', route: '/google-keywords-details' },
      ],
    },
    {
      name: 'Google Analytics',
      children: [
        {
          name: 'Google Analytics Overview',
          route: '/google-analytics',
        },
        { name: 'Users', route: '/google-analytics-users' },
        {
          name: 'Ecommerce Affiliation',
          route: '/google-analytics-ecommerce-affiliation',
        },
      ],
    },
    {
      name: 'Meta Ads',
      children: [
        { name: 'Meta Overview', route: '/ads-dashboard' },
        { name: 'Campaigns', route: '/fb-campaigns' },
        { name: 'Ads', route: '/MetaAds' },
      ],
    },
    {
      name: 'Social Media',
      children: [
        { name: 'Facebook Social Media Overview', route: '/fb-social-media-dashboard' },
        { name: 'Instagram Social Media Overview', route: '/InstagramDashboard' },
      ],
    },

    {
      name: 'Aramex',
      route: '/Aramex-Dashboard',
    },
    {
      name: 'Email Builder',
      route: '/email-builder',
    },
    {
      name: 'Integrations',
      route: '/integrations',
    },
    {
      name: 'Advisor',
      route: '/advisor',
    },
  ];
  useEffect(() => {
    if (metisMenuRef.current) {
      metisMenuRef.current.dispose(); // ✅ Prevent conflicts when re-rendering
    }
    
    metisMenuRef.current = new MetisMenu("#side-menu", {
      toggle: false, // ✅ Ensure smooth dropdown behavior
    });

    // ✅ Ensure the active class is applied correctly
    document.querySelectorAll("#side-menu .mm-active, #side-menu .active").forEach(el => {
      el.classList.remove("mm-active", "active", "mm-show");
    });

    let matchingMenuItem = null;
    const items = document.querySelectorAll("#side-menu a");

    items.forEach(item => {
      if (location.pathname === item.pathname) {
        matchingMenuItem = item;
      }
    });

    if (matchingMenuItem) {
      let parent = matchingMenuItem.parentElement;
      while (parent) {
        if (parent.tagName === "LI") {
          parent.classList.add("mm-active");
        }
        if (parent.tagName === "UL") {
          parent.classList.add("mm-show");
        }
        parent = parent.parentElement;
      }
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sideBarContent.map((item, index) => (
              <SideBarContentElement key={index} item={item} />
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SidebarContent);
