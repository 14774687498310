import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import urls from "../../routes/apiUrls";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  checkThirdStepMagentoFailure,
  checkThirdStepMagentoSuccess,
  deleteIntegrationMagentoFailure,
  deleteIntegrationMagentoSuccess,
  fetchMagentoBestProductsFailure,
  fetchMagentoBestProductsSuccess,
  fetchMagentoOrdersFailure,
  fetchMagentoOrdersSuccess,
  fetchMagentoProductsFailure,
  fetchMagentoProductsSuccess,
  fetchMagentoStoreFailure,
  fetchMagentoStoreSuccess,
  getTotalProductsCountFailure,
  getTotalProductsCountSuccess,
  integrationMagentoFailure,
  integrationMagentoSuccess,
} from "./actions";
import {
  deleteIntegration,
  fetchBestSellers,
  getMagentoIntegration,
  getOrders,
  getProducts,
  getProductsCount,
  integrateMagento,
} from "../../queries/Magento/queries";
import {
  CHECK_THIRD_STEP_MAGENTO_REQUEST,
  DELETE_INTEGRATION_MAGENTO_REQUEST,
  FETCH_MAGENTO_BEST_PRODUCTS_REQUEST,
  FETCH_MAGENTO_ORDERS_REQUEST,
  FETCH_MAGENTO_PRODUCTS_REQUEST,
  FETCH_MAGENTO_STORE_REQUEST,
  GET_TOTAL_PRODUCTS_COUNT_REQUEST,
  INTEGRATION_MAGENTO_REQUEST,
} from "./actionsTypes";

import Swal from "sweetalert2";
import { checkSales } from "../../queries/Shopify/queries";
import { successAlert } from "../../pages/Alerts/NotLinkedAccountAlert";

const magentoApolloCli = new ApolloClient({
  link: new HttpLink({
    uri: urls.magento,
    headers: {
      Authorization: window.localStorage.getItem("token")
        ? `Bearer ${window.localStorage.getItem("token")}`
        : "",
    },
    credentials: "include", // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const AppoloCliCheckSales = new ApolloClient({
  link: new HttpLink({
    uri: urls.shopify,
    headers: {
      Authorization: window.localStorage.getItem("token")
        ? `Bearer ${window.localStorage.getItem("token")}`
        : "",
    },
    credentials: "include", // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function* integrationMagento(action) {
  try {
    const { data } = yield call(magentoApolloCli.mutate, {
      mutation: integrateMagento,
      variables: {
        token: action.payload.token,
        domain: action.payload.domain,
        protocol: action.payload.protocol,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
    });
    if (data.integration) {
      yield put(integrationMagentoSuccess(data.integration));
    } else {
      yield put("data  of store is not found");
    }
  } catch (error) {
    yield put(integrationMagentoFailure(error.message));
  }
}

function* checkThirdStepMagento() {
  try {
    const { data } = yield call(AppoloCliCheckSales.query, {
      query: checkSales,
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
    console.log("third step magento ", data);

    if (data.getIntegration) {
      if (data.getIntegration.type !== 12) {
        yield put(checkThirdStepMagentoSuccess());
      }
    }
  } catch (error) {
    yield put(checkThirdStepMagentoFailure(error.message));
  }
}

function* fetchProducts(action) {
  try {
    console.log("fetching products");
    const { data } = yield call(magentoApolloCli.query, {
      query: getProducts,
      variables: {
        limit: action.payload.pagesize,
        offset: action.payload.page,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    console.log("fetching products", data);
    if (data?.getProducts) {
      yield put(fetchMagentoProductsSuccess(data?.getProducts));
    }
  } catch (error) {
    yield put(fetchMagentoProductsFailure(error.message));
  }
}

function* fetchOrders(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(magentoApolloCli.query, {
      query: getOrders,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    console.log("fetching orders", data);
    if (data?.getOrders) {
      yield put(fetchMagentoOrdersSuccess(data.getOrders));
    }
  } catch (error) {
    yield put(fetchMagentoOrdersFailure(error.message));
  }
}

function* fetchStore(action) {
  try {
    const { data } = yield call(magentoApolloCli.query, {
      query: getMagentoIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
    if (data.getIntegration) {
      yield put(fetchMagentoStoreSuccess(data.getIntegration));
      if (data.getIntegration.connectionstate === false) {
        Swal.fire({
          title: "oops",
          text: "please check your check your Magento credentials or contact  our support team ",
          icon: "warning",
          showCancelButton: false,
        }).then(function (e) {
          window.location.replace("/integrations");
        });
      }
    } else {
      yield put(
        fetchMagentoStoreFailure("data  of  single store is not found")
      );
    }
  } catch (error) {
    yield put(fetchMagentoStoreFailure(error.message));
  }
}

function* getMagentoProductsCount() {
  try {
    const { data } = yield call(magentoApolloCli.query, {
      query: getProductsCount,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    if (data?.getProductsCount) {
      yield put(getTotalProductsCountSuccess(data.getProductsCount.count));
    }
  } catch (error) {
    yield put(getTotalProductsCountFailure(error.message));
  }
}

function* deleteIntegrationMagento() {
  try {
    const { data } = yield call(magentoApolloCli.mutate, {
      mutation: deleteIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    yield put(deleteIntegrationMagentoSuccess("deleted done successfully"));
    successAlert();
  } catch (error) {
    yield put(deleteIntegrationMagentoFailure(error.message));
  }
}

function* fetchBestProducts(action) {
  try {
    const { data } = yield call(magentoApolloCli.query, {
      query: fetchBestSellers,
      variables: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    console.log("fetching orders", data);
    if (data?.getBestSellerProducts) {
      yield put(fetchMagentoBestProductsSuccess(data.getBestSellerProducts));
    }
  } catch (error) {
    yield put(fetchMagentoBestProductsFailure(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(DELETE_INTEGRATION_MAGENTO_REQUEST, deleteIntegrationMagento);
  yield takeEvery(INTEGRATION_MAGENTO_REQUEST, integrationMagento);
  yield takeEvery(CHECK_THIRD_STEP_MAGENTO_REQUEST, checkThirdStepMagento);
  yield takeEvery(FETCH_MAGENTO_STORE_REQUEST, fetchStore);
  yield takeEvery(GET_TOTAL_PRODUCTS_COUNT_REQUEST, getMagentoProductsCount);
  yield takeEvery(FETCH_MAGENTO_PRODUCTS_REQUEST, fetchProducts);
  yield takeEvery(FETCH_MAGENTO_ORDERS_REQUEST, fetchOrders);
  yield takeEvery(FETCH_MAGENTO_BEST_PRODUCTS_REQUEST, fetchBestProducts);
}
