import {URL_Setup} from '../config.js';
let baseUrl = '';
if (window.location.hostname.includes(URL_Setup.URL) || window.location.hostname.includes(URL_Setup.IP)) {
    baseUrl = `https://${window.location.hostname}` // need to change it back to https when getting the domain name and ssl cert
} else {
    baseUrl = `http://${window.location.hostname}:4000`;
}
console.log(baseUrl);
const urls = {
    member: `${baseUrl}/member/`,
    account: `${baseUrl}/account/`,
    login: `${baseUrl}/login/`,
    shopify: `${baseUrl}/shopify/`,
    dashboard: `${baseUrl}/dashboard/`,
    facebook: `${baseUrl}/facebook/`,
    report: `${baseUrl}/report/`,
    user: `${baseUrl}/user/`,
    google: `${baseUrl}/google/`,
    product: `${baseUrl}/product/`,
    orders: `${baseUrl}/orders/`,
    prestarefund:`${baseUrl}/prestarefunds/`,
    snap: `${baseUrl}/snap/`,
    analytics: `${baseUrl}/analytics/`,
    cost: `${baseUrl}/cost/`,
    track: `${baseUrl}/track/`,
    upload: `${baseUrl}/upload/`,
    fees: `${baseUrl}/fees/`,
    customers: `${baseUrl}/customers/`,
    prestashop: `${baseUrl}/presta/`,
    woo: `${baseUrl}/woo/`,
    cm: `${baseUrl}/Cm/`,
    facebookdashboard: `${baseUrl}/facebookdashboard/`,
    woodashboard: `${baseUrl}/woodashboard/`,
    woocoupons: `${baseUrl}/woocoupons`,
    wooproductcategory: `${baseUrl}/wooproductcategory`,
    wootopsellers: `${baseUrl}/wootopsellers`,
    wooordersstatus: `${baseUrl}/wooordersstatus`,
    gabrowsers : `${baseUrl}/gabrowsers`,
    klaviyo: `${baseUrl}/klaviyo`,
    Aramex: `${baseUrl}/Aramex`,
    EmailBuilder : `${baseUrl}/emailBuilder`, 
    googleAdsDashboard : `${baseUrl}/googleadsdashboard`, 
    facebookSocialMedia : `${baseUrl}/facebookSocialMedia`, 
    instagramSocialMedia : `${baseUrl}/instagramSocialMedia`,
    prestadashboard : `${baseUrl}/prestadashboard`,
    MetaAds : `${baseUrl}/MetaAds`,
    magento : `${baseUrl}/magento`,
    
}
export default urls;
