import React, { useEffect } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CardBody, Progress, Alert } from 'reactstrap';
import { Table } from "antd"; // Using Ant Design for the table
import { useDispatch, useSelector } from 'react-redux';
import { fetchCouponsCodesRequest } from '../../../store/woocommerce/actions';
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';
import CouponSummary from "./CouponSummary"; // Import the summary component

const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooCoupons(props) {
  secureSection();
  const { t } = props;
  const dispatch = useDispatch();
  const { coupons } = useSelector((state) => state.woocommerce);

  useEffect(() => {
    const savedCouponsCodes = localStorage.getItem('woocoupons');
    if (savedCouponsCodes) {
      dispatch({ type: 'FETCH_COUPONS_CODES_SUCCESS', payload: JSON.parse(savedCouponsCodes) });
    } else {
      dispatch(fetchCouponsCodesRequest(50, 1));
    }
  }, [dispatch]);

  // Define columns for Ant Design table with sorting functionality
  const columns = [
    {
      title: t("COUPON ID"), // Changed to uppercase
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id, // Sort by numerical ID
    },
    {
      title: t("CODE"), // Changed to uppercase
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code), // Sort alphabetically
    },
    {
      title: t("AMOUNT"), // Changed to uppercase
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount, // Sort numerically by amount
    },
    {
      title: t("USAGE COUNT"), // Changed to uppercase
      dataIndex: 'usage_count',
      key: 'usage_count',
      sorter: (a, b) => a.usage_count - b.usage_count, // Sort numerically by usage count
    },
    {
      title: t("FREE SHIPPING"), // Changed to uppercase
      dataIndex: 'free_shipping',
      key: 'free_shipping',
      render: (free_shipping) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: free_shipping ? '#007F00' : '#D30000',
            backgroundColor: free_shipping ? '#CDFFCD' : '#FFE0E0',
            borderRadius: '12.67px',
            width: '72px',
            height: '25px',
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: free_shipping ? '#007F00' : '#D30000',
              marginRight: '8px',
            }}
          ></span>
          {free_shipping ? 'True' : 'False'}
        </div>
      ),
      sorter: (a, b) => a.free_shipping - b.free_shipping, // Sort by boolean (true/false)
    },
    {
      title: t("INDIVIDUAL USE"), // Changed to uppercase
      dataIndex: 'individual_use',
      key: 'individual_use',
      render: (individual_use) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: individual_use ? '#007F00' : '#D30000',
            backgroundColor: individual_use ? '#CDFFCD' : '#FFE0E0',
            borderRadius: '12.67px',
            width: '72px',
            height: '25px',
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: individual_use ? '#007F00' : '#D30000',
              marginRight: '8px',
            }}
          ></span>
          {individual_use ? 'True' : 'False'}
        </div>
      ),
      sorter: (a, b) => a.individual_use - b.individual_use, // Sort by boolean (true/false)
    },
  ];
  

  // Pagination options
  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
  };

  // Handle table change (pagination, filters, sorting)
  const handleChange = (pagination, filters, sorter) => {
    console.log("Table changed", sorter);
  };

  return (
    <React.Fragment>
      <div className="page-content">
          <title>{props.t("Woo Coupons Codes")} | Live-Metrics - E-com SaaS</title>
        <div className="container-fluid">
         
           
            <Breadcrumbs
              title={props.t("WooCommerce")}
              breadcrumbItem={props.t("Coupons Codes")}
              pageHeading={props.t("Coupons Codes")}
              style={{ fontSize: '15px', fontWeight: '700' }}
              image={WooCommercepIcon}
            />
          <div style={{ marginTop: '40px' }}>
            <CardBody className="px-0">
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                <CouponSummary coupons={coupons} t={t} />
              </div>

              {coupons && coupons.length > 0 ? (
                <Table
                  columns={columns}
                  bordered={true} // Disable the default borders
                  dataSource={coupons.map(coupon => ({ ...coupon.coupon, key: coupon.coupon.id }))}
                  onChange={handleChange}
                  pagination={{
                    ...paginationOptions,
                    showSizeChanger: true,
                    style: {
                      backgroundColor: '#F4F2FF',
                      padding: '10px 15px',
                      marginTop: '2px',
                      borderRadius: '6px',
                      fontsize: '14px',
                      fontweight: '700',
                      color: '#6E6893',
                    },
                  }}
                  headerCellStyle={{
                    backgroundColor: '#FF0000', // Change this to your desired color
                    color: '#333333', // Text color
                  }}
                  className="custom-table" // Custom class for removing column borders
                />
              ) : (
                <div className="text-center">
                  <CardBody>
                    <Progress
                      value={80}
                      color="success"
                      style={{ width: '75%' }}
                      animated
                    />
                    <Alert color="success" className="mt-3">
                      {props.t("This might take a few minutes!")}
                    </Alert>
                  </CardBody>
                </div>
              )}
            </CardBody>
          </div>
        </div>
      </div>

      <style jsx>{`
        /* Remove borders between columns */
        .custom-table .ant-table-cell {
          border-right: none !important; /* Remove right border */
        }

        .custom-table .ant-table-cell:last-child {
          border-right: none !important; /* Ensure no right border for the last cell */
        }

        .custom-table .ant-table-thead > tr > th {
          border-bottom: none !important; /* Remove bottom border from the header */
        }
      `}</style>
    </React.Fragment>
  );
}

WooCoupons.propTypes = {
  t: PropTypes.any,
};

export default (withTranslation()(WooCoupons));
