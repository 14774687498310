import React from "react";
import { Sender } from '@ant-design/x';

const MessageInputBar = ({ message, setMessage, handleSendMessage, botTyping }) => {
  return (
<div
  style={{
    backgroundColor: "#f2f2fc",
    borderRadius: "20px",
   
    // padding: "10px 20px",
    position: "fixed",
    bottom: "90px",
    left: "58%",
    transform: "translateX(-50%)",
    width: "60%",
  }}
>
<Sender
  loading={botTyping}
  value={message}
  placeholder="Type message"
  onChange={(val) => {
    console.log("Sender value:", val);
    setMessage(val);
  }}
  onSubmit={() => handleSendMessage()}
/>
</div>


  );
};

export default MessageInputBar;
