import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ReactCodeInput from 'react-code-input';
//Verification code package
// import AuthCode from 'react-auth-code-input';

//import images
import CarouselLogin from '../AuthenticationInner/CarouselLogin';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  isConnectedRequest,
  isVerifiedRequest,
  verifCodeRequest,
} from '../../store/actions';

const TwoStepVerification = () => {
  const [result, setResult] = useState();
  const dispatch = useDispatch();
  const { isConnected, isVerified, loading } = useSelector(
    (state) => state.editProfile
  );
  const handleOnChange = (res) => {
    setResult(res);
  };
  const { id ,verifCode} = useParams();
  
  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(verifCodeRequest(id, result,verifCode));
  };

  useEffect(() => {

    if (isConnected) window.location.replace('/integrations');
  }, [isConnected]);

  useEffect(() => {
    dispatch(isConnectedRequest());
  }, []);

  useEffect(() => {
    const verifyUser = async () => {
      await dispatch(isVerifiedRequest(id));
    };

    verifyUser();
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && isVerified) {
      window.location.replace('/404');
    }
  }, [isVerified, loading]);


  if (isVerified || loading ) return null;

  return (
    <React.Fragment>
        <title>Two Step Verification</title>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-6 d-flex justify-content-center align-items-center" style={{width:'50%'}}>
          <div className="auth-full-page-content align-items-center d-flex" style={{ maxWidth: '560px', width: '100%', padding: '20px' }}>
              <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1712_2237)">
<path d="M0 18L23.112 32.709C23.376 32.904 23.688 33 24 33C24.312 33 24.624 32.904 24.888 32.709L48 18L24.9 0.299982C24.366 -0.0990176 23.634 -0.0990176 23.1 0.299982L0 18Z" fill="#64B5F6"/>
<path d="M39 0H9C7.347 0 6 1.347 6 3V36C6 36.828 6.672 37.5 7.5 37.5H40.5C41.328 37.5 42 36.828 42 36V3C42 1.347 40.656 0 39 0Z" fill="#ECEFF1"/>
<path d="M13.5 9H34.5C35.328 9 36 8.328 36 7.5C36 6.672 35.328 6 34.5 6H13.5C12.672 6 12 6.672 12 7.5C12 8.328 12.672 9 13.5 9Z" fill="#90A4AE"/>
<path d="M34.5 12H13.5C12.672 12 12 12.672 12 13.5C12 14.328 12.672 15 13.5 15H34.5C35.328 15 36 14.328 36 13.5C36 12.672 35.328 12 34.5 12Z" fill="#90A4AE"/>
<path d="M25.5 18H13.5C12.672 18 12 18.672 12 19.5C12 20.328 12.672 21 13.5 21H25.5C26.328 21 27 20.328 27 19.5C27 18.672 26.328 18 25.5 18Z" fill="#90A4AE"/>
<path d="M24.888 32.709C24.624 32.904 24.312 33 24 33C23.688 33 23.376 32.904 23.112 32.709L0 18V45C0 46.656 1.344 48 3 48H45C46.656 48 48 46.656 48 45V18L24.888 32.709Z" fill="#1E88E5"/>
<path d="M45 48H3C1.317 48 0 46.683 0 45C0 44.523 0.228 44.073 0.612 43.791L23.112 28.791C23.376 28.596 23.688 28.5 24 28.5C24.312 28.5 24.624 28.596 24.888 28.791L47.388 43.791C47.772 44.073 48 44.523 48 45C48 46.683 46.683 48 45 48Z" fill="#2196F3"/>
</g>
<defs>
<clipPath id="clip0_1712_2237">
<rect width="48" height="48" fill="white"/>
</clipPath>
</defs>
                                </svg>
                          </div>
                        </div>
                        <div>
                          <h5 style={{fontSize:'28px', fontWeight:'800',color:'black'}}>Verify your email</h5>
                          <p className="mb-5" style={{fontSize:'16px', fontWeight:'400'}}>
                            Please Enter The 4 Digit Code Sent To{' '}
                            <span className="fw-bold">example@abc.com</span>
                          </p>

                          <form onSubmit={onSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3 verification">
                                  <label
                                    htmlFor="digit1-input"
                                    className="visually-hidden"
                                  >
                                    Digit 1
                                  </label>

                                  <ReactCodeInput
                                    type="text"
                                    fields={4}
                                    onChange={handleOnChange}
                                    inputStyle={{
                                      width: '76px',
                                      height: '76px',
                                      padding: '8px',
                                      borderRadius: '8px',
                                      fontSize: '24px',
                                      textAlign: 'center',
                                      marginRight: '25px',
                                      border: '1px solid #ced4da',
                                    }}
                                  />
                                </div>
                                <div className="mt-5">
                                  <button
                                    className="btn btn-auth w-100"
                                    type="submit"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="mt-5 text-center">
                        <p className=" mb-0" style={{fontSize:'16px',color:'gray'}}>
                          Didn't receive an email?{' '}
                          <Link to="#" className="fw-semibold" style={{fontSize:'16px',color:'#4285F4'}}
                          >
                            Resend
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="text-center" style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)',color:'gray' }}>
                      <p className="mb-0" style={{fontSize:'16px'}}>
                        © {new Date().getFullYear()} LiveMetrics. Crafted with
                        by Convergen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselLogin/>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoStepVerification;
