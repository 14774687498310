// actions.js

// Action Types
export const FETCH_SESSIONS = "FETCH_SESSIONS";
export const SET_SESSIONS = "SET_SESSIONS";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const BOT_TYPING = "BOT_TYPING";
export const CLEAR_MESSAGES="CLEAR_MESSAGES";
export const DELETE_SESSION="DELTE_SESSION";

// Action Creators
export const fetchSessions = () => ({ type: FETCH_SESSIONS });
export const setSessions = (sessions) => ({ type: SET_SESSIONS, payload: sessions });

export const fetchMessages = (sessionId) => ({ type: FETCH_MESSAGES, payload: sessionId });
export const setMessages = (messages) => ({ type: SET_MESSAGES, payload: messages });

export const sendMessage = (message, sessionId,history) => ({ type: SEND_MESSAGE, payload: { message, sessionId,history } });
export const addMessage = (message) => ({ type: ADD_MESSAGE, payload: message });



export const setBotTyping = (isTyping) => ({ type: BOT_TYPING, payload: isTyping });
export const clearMessages=()=>({type:CLEAR_MESSAGES,payload:[]});



export const deleteSession=(sessionId)=>({type:DELETE_SESSION,payload:sessionId});
