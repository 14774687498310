import React, { useRef, useState, useEffect } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmailEditor from "react-email-editor";
import GalleryModal from "./GalleryModal";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { uploadPhotoFiles, getImageDimensions } from "./lib";
import SendMailModal from "./SendMailModal";
import { MDBBtn } from "mdb-react-ui-kit";
import secureSection from '../../MangeSecurity/MemberSecurity';
import axios from "axios";

import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Index = (props) => {
  secureSection();
  const emailEditorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMailModal, setIsOpenMailModal] = useState(false);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [rawFiles, setRawFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const photosRef = useRef([]);
  const [mailContent, setMailContent] = useState();
  const [templates, setTemplates] = useState([]);

  const toggleModal = () => {
    setIsOpen(() => !isOpen);
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: 'contain' },
  };

  const toggleMailModal = () => {
    setIsOpenMailModal(() => !isOpenMailModal);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesResponse = await axios.get('https://api.unlayer.com/v2/templates', {
          params: {
            page: 1,
            perPage: 10,
            includeDesign: 0,
          },
          headers: {
            accept: 'application/json',
            authorization: 'Basic VkszRUx3NTU2NUE1QXpqM3J6UDBOV3BiUGJSN0NPTmVia0VRTFExWXN3anBHTlRPSUVUUE1xRWp6V1NTYVdRVjo=',
          },
        });

        const templateIds = templatesResponse.data.data.map(template => template.id);
        const fetchedTemplates = await Promise.all(templateIds.map(id => getJsonTemplate(id)));
        setTemplates(fetchedTemplates);
      } catch (error) {
        console.error(error);
      }
    };

    const getJsonTemplate = async (templateId) => {
      try {
        const templateResponse = await axios.get(`https://api.unlayer.com/v2/templates/${templateId}`, {
          headers: {
            accept: 'application/json',
            authorization: 'Basic VkszRUx3NTU2NUE1QXpqM3J6UDBOV3BiUGJSN0NPTmVia0VRTFExWXN3anBHTlRPSUVUUE1xRWp6V1NTYVdRVjo=',
          },
        });

        const templateJSON = templateResponse.data.data.design;
        // const imageUrl = await exportPreviewImage(templateJSON);
        return {
          id: templateId,
          imageUrl:"https://cdn.tools.unlayer.com/image/placeholder.png",
        };
      } catch (error) {
        console.error(error);
      }
    };

    // const exportPreviewImage = async (templateJSON) => {
    //   const url = 'https://api.unlayer.com/v2/export/image';
    //   const authToken = 'VkszRUx3NTU2NUE1QXpqM3J6UDBOV3BiUGJSN0NPTmVia0VRTFExWXN3anBHTlRPSUVUUE1xRWp6V1NTYVdRVjo=';
    //   const headers = {
    //     Accept: 'application/json',
    //     Authorization: `Basic ${authToken}`,
    //     'Content-Type': 'application/json',
    //   };

    //   const data = {
    //     displayMode: 'email',
    //     design: templateJSON,
    //     mergeTags: {
    //       first_name: 'John',
    //       last_name: 'Doe',
    //     },
    //     fullPage: true,
    //   };

    //   try {
    //     const response = await axios.post(url, data, { headers });
    //     return response.data.data.url;
    //   } catch (error) {
    //     console.error(error);
    //     return '';
    //   }
    // };

    fetchTemplates();
  }, []);

  const exportHtml = () => {
    if (emailEditorRef.current !== null) {
      emailEditorRef.current.editor.exportHtml((data) => {
        localStorage.setItem("newsletter", JSON.stringify(data));
        if (data.html) {
          setMailContent(data.html);
          toggleMailModal();
        }
      });
    }
  };

  const onLoad = () => {
    // Editor instance is created
  };

  const onReady = () => {
    const editorInstance = emailEditorRef.current?.editor;
  
    if (editorInstance) {
      editorInstance.registerCallback('image', (_data, done) => {
        setIsOpen(true);
        done({
          height: 20,
          width: 10,
          size: 400,
          url: "https://cdn.tools.unlayer.com/image/placeholder.png",
        });
      });
    } else {
      console.error('Editor instance is not available.');
    }
  };
  const loadTemplate = async (templateId) => {
    try {
      const templateResponse = await axios.get(`https://api.unlayer.com/v2/templates/${templateId}`, {
        headers: {
          accept: 'application/json',
          authorization: 'Basic VkszRUx3NTU2NUE1QXpqM3J6UDBOV3BiUGJSN0NPTmVia0VRTFExWXN3anBHTlRPSUVUUE1xRWp6V1NTYVdRVjo=',
        },
      });

      const templateJSON = templateResponse.data.data.design;

      if (emailEditorRef.current !== null) {
        emailEditorRef.current.editor.loadDesign(templateJSON);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileInputChange = async (e) => {
    const _files = e.target.files;
    if (_files) {
      const files = Array.from(_files);
      const _results = [];
      for (const file of files) {
        const result = await getImageDimensions(file);
        _results.push(result);
      }
      setRawFiles(_results);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    try {
      if (rawFiles.length < 1) return null;
      setLoading(true);
      let i = 0;
      const formData = new FormData();
      for (const item of rawFiles) {
        let file = item.file;
        let width = item.height;
        let height = item.height;
        formData.append(`info_${i}`, JSON.stringify({ width, height }));
        formData.append("mediaFile", file);
        i++;
      }
      const res = await uploadPhotoFiles(formData);
      setLoading(false);
      if (res.error) {
        toggleModal();
        return alert(res.message);
      }
      setRawFiles([]);
      photosRef.current = [...res.data, ...photoFiles];
      setPhotoFiles((prev) => [...res.data, ...prev]);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <title>{props.t("Email Builder")} |Convergen-Metrics - E-com SaaS</title>
        <Container fluid>
          <Breadcrumbs title={props.t("Email Builder")} breadcrumbItem={props.t("Email Builder")} />
        </Container>

        <div>
          <MDBBtn onClick={() => exportHtml()}>{props.t("Send Newsletter")}</MDBBtn>
        </div>
        <AliceCarousel
          mouseTracking
          items={templates.map((template) => (
            <div className="item" data-value="1" key={template.id}>
              <img
                src={template.imageUrl}
                alt={template.id}
                onClick={() => loadTemplate(template.id)}
                style={{ width: "60%", height: "60%" }}
              />
            </div>
          ))}
          responsive={responsive}
          controlsStrategy="alternate"
          disableButtonsControls
        />

<EmailEditor
  editorId="editor_container"
  ref={emailEditorRef}
  onLoad={onLoad}
  onReady={onReady}
/>

        <GalleryModal
          isOpen={isOpen}
          photoFiles={photoFiles}
          toggleModal={toggleModal}
          handleFileUpload={handleFileUpload}
          handleFileInputChange={handleFileInputChange}
          rawFiles={rawFiles}
          loading={loading}
        />

        <SendMailModal {...{ toggleMailModal, mailContent, isOpenMailModal }} />
      </div>
    </React.Fragment>
  );
};

Index.propTypes = {
  t: PropTypes.any,
};

export default (withTranslation()(Index));
