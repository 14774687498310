import React, { useState } from "react";
import { MathpixMarkdown } from "mathpix-markdown-it"; // Import Markdown + LaTeX Renderer
import { Tooltip } from "antd"; // Ant Design Components
import { SoundOutlined, CopyOutlined } from "@ant-design/icons"; // Icons

const RenderMessage = ({ msg }) => {
  // ✅ Move useState to the top to follow React Hook rules
  const [copied, setCopied] = useState(false);
  const [speaking, setSpeaking] = useState(false);

  if (!msg || typeof msg.text !== "string") {
    return <pre style={{ color: "red" }}>Invalid message format</pre>;
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copy status after 2s
    });
  };

  const handleTextToSpeech = (text) => {
    if (!speaking) {
      setSpeaking(true);
      window.speechSynthesis.cancel();
      const speech = new SpeechSynthesisUtterance(text);
      speech.lang = "en-US";
      speech.rate = 1;
      speech.pitch = 1;
      speech.onend = () => setSpeaking(false); // Reset speaking state when done
      window.speechSynthesis.speak(speech);
    }
  };

  return (
    <div>
      <MathpixMarkdown text={msg.text} />

      {msg.type !== "user" && (
        <div className="d-flex align-items-center mt-2" style={{ gap: "10px" }}>
          {/* Copy Text Popover */}
          <Tooltip title={copied ? "Copied!" : "Copy"}>
            <CopyOutlined
              style={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: copied ? "#59527A" : "#59527A",
                transition: "color 0.3s ease",
              }}
              onClick={() => handleCopy(msg.text)}
            />
          </Tooltip>
          {/* Text-to-Speech Popover */}
          <Tooltip title={speaking ? "Speaking..." : "Listen"}>
            <SoundOutlined
              style={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: speaking ? "#59527A" : "#59527A",
                transition: "color 0.3s ease",
              }}
              onClick={() => handleTextToSpeech(msg.text)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default RenderMessage;
