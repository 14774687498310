import {
  CHECK_THIRD_STEP_MAGENTO_FAILURE,
  CHECK_THIRD_STEP_MAGENTO_REQUEST,
  CHECK_THIRD_STEP_MAGENTO_SUCCESS,
  DELETE_INTEGRATION_MAGENTO_FAILURE,
  DELETE_INTEGRATION_MAGENTO_REQUEST,
  DELETE_INTEGRATION_MAGENTO_SUCCESS,
  FETCH_MAGENTO_PRODUCTS_FAILURE,
  FETCH_MAGENTO_PRODUCTS_REQUEST,
  FETCH_MAGENTO_PRODUCTS_SUCCESS,
  FETCH_MAGENTO_STORE_FAILURE,
  FETCH_MAGENTO_STORE_REQUEST,
  FETCH_MAGENTO_STORE_SUCCESS,
  GET_TOTAL_PRODUCTS_COUNT_FAILURE,
  GET_TOTAL_PRODUCTS_COUNT_REQUEST,
  GET_TOTAL_PRODUCTS_COUNT_SUCCESS,
  INTEGRATION_MAGENTO_FAILURE,
  INTEGRATION_MAGENTO_REQUEST,
  INTEGRATION_MAGENTO_SUCCESS,
  FETCH_MAGENTO_ORDERS_REQUEST,
  FETCH_MAGENTO_ORDERS_SUCCESS,
  FETCH_MAGENTO_ORDERS_FAILURE,
  FETCH_MAGENTO_BEST_PRODUCTS_REQUEST,
  FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS,
  FETCH_MAGENTO_BEST_PRODUCTS_FAILURE,
} from './actionsTypes';

// get single prestashop store
export const fetchMagentoStoreRequest = () => ({
  type: FETCH_MAGENTO_STORE_REQUEST,
});

export const fetchMagentoStoreSuccess = (firststore) => ({
  type: FETCH_MAGENTO_STORE_SUCCESS,
  payload: firststore,
});

export const fetchMagentoStoreFailure = (error) => ({
  type: FETCH_MAGENTO_STORE_FAILURE,
  payload: error,
});

export const checkThirdStepMagentoRequest = () => ({
  type: CHECK_THIRD_STEP_MAGENTO_REQUEST,
});

export const checkThirdStepMagentoSuccess = (data) => ({
  type: CHECK_THIRD_STEP_MAGENTO_SUCCESS,
  payload: data,
});

export const checkThirdStepMagentoFailure = (error) => ({
  type: CHECK_THIRD_STEP_MAGENTO_FAILURE,
  payload: error,
});

//delete integration prestaShop
export const deleteIntegrationMagentoRequest = () => ({
  type: DELETE_INTEGRATION_MAGENTO_REQUEST,
});

export const deleteIntegrationMagentoSuccess = (data) => ({
  type: DELETE_INTEGRATION_MAGENTO_SUCCESS,
  payload: data,
});

export const deleteIntegrationMagentoFailure = (error) => ({
  type: DELETE_INTEGRATION_MAGENTO_FAILURE,
  payload: error,
});

// integration prestaShop
export const integrationMagentoRequest = (token, domain, protocol) => ({
  type: INTEGRATION_MAGENTO_REQUEST,
  payload: { token, domain, protocol },
});

export const integrationMagentoSuccess = (Store) => ({
  type: INTEGRATION_MAGENTO_SUCCESS,
  payload: Store,
});

export const integrationMagentoFailure = (error) => ({
  type: INTEGRATION_MAGENTO_FAILURE,
  payload: error,
});

export const getTotalProductsCountRequest = () => ({
  type: GET_TOTAL_PRODUCTS_COUNT_REQUEST,
});

export const getTotalProductsCountSuccess = (firststore) => ({
  type: GET_TOTAL_PRODUCTS_COUNT_SUCCESS,
  payload: firststore,
});

export const getTotalProductsCountFailure = (error) => ({
  type: GET_TOTAL_PRODUCTS_COUNT_FAILURE,
  payload: error,
});

export const fetchMagentoProductsRequest = (page, pagesize) => ({
  type: FETCH_MAGENTO_PRODUCTS_REQUEST,
  payload: { page, pagesize },
});

export const fetchMagentoProductsSuccess = (products) => ({
  type: FETCH_MAGENTO_PRODUCTS_SUCCESS,
  payload: products,
});

export const fetchMagentoProductsFailure = (error) => ({
  type: FETCH_MAGENTO_PRODUCTS_FAILURE,
  payload: error,
});

export const fetchMagentoOrdersRequest = (
  startDate,
  endDate,
  compareStartDate = null,
  compareEndDate = null
) => ({
  type: FETCH_MAGENTO_ORDERS_REQUEST,
  payload: {
    startDate,
    endDate,
    ...(compareStartDate && compareEndDate
      ? { compareStartDate, compareEndDate }
      : {}),
  },
});

export const fetchMagentoOrdersSuccess = (orders) => ({
  type: FETCH_MAGENTO_ORDERS_SUCCESS,
  payload: orders,
});

export const fetchMagentoOrdersFailure = (error) => ({
  type: FETCH_MAGENTO_ORDERS_FAILURE,
  payload: error,
});

export const fetchMagentoBestProductsRequest = (startDate, endDate) => ({
  type: FETCH_MAGENTO_BEST_PRODUCTS_REQUEST,
  payload: { startDate, endDate },
});

export const fetchMagentoBestProductsSuccess = (bestSellers) => ({
  type: FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS,
  payload: bestSellers,
});

export const fetchMagentoBestProductsFailure = (error) => ({
  type: FETCH_MAGENTO_BEST_PRODUCTS_FAILURE,
  payload: error,
});
