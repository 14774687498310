import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import avatar from '../../../assets/images/users/avatar-1.jpg';
import { withTranslation } from 'react-i18next';
import {  Link } from 'react-router-dom';
import axios from 'axios';
import { editProfileRequest } from '../../../store/auth/actions';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import proptypes from 'prop-types';

function UserProfile(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.editProfile);
  const { handleSubmit } = useForm();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [imageFile, setImageFile] = useState(null); // To store the uploaded image file
  const [previewImage, setPreviewImage] = useState(''); // To store image preview URL

  // Track edit mode state
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (user.user) {
      setEmail(user.user.email);
      setFirstName(user.user.firstname);
      setMobile(user.user.mobile);
      setLastName(user.user.lastname);
      setPreviewImage(user.user.Image || avatar); // Set initial image
    }
  }, [user.user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setPreviewImage(URL.createObjectURL(file)); // Set the preview URL for the image
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null); // Clear the uploaded image file
    setPreviewImage(user.user.Image || avatar); // Revert to the original image (avatar)
  };

  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'livemetrics'); // Replace with your Cloudinary upload preset

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/ducork8ep/image/upload',
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error('Error uploading image to Cloudinary', error);
      return null;
    }
  };

  const editUserAccount = async () => {
    let imageUrl = previewImage;
    if (imageFile) {
      imageUrl = await uploadImageToCloudinary(imageFile);
    }

    dispatch(
      editProfileRequest({
        firstName,
        lastName,
        email,
        mobile,
        imageUrl,
      })
    );

    // Toggle edit mode off after saving
    setIsEditMode(false);
  };

  // Function to toggle between edit and save modes
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      handleSubmit(editUserAccount)();
    }
  };

  return (
    <React.Fragment>
        <title>Profile | LiveMetrics - E-com SaaS</title>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card style={{ border: 'none' }}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 style={{ fontWeight: 'bold', fontSize: '20px' }}>{i18next.t('Profile')}</h4>
              </div>
              <div className="d-flex align-items-center mb-4">
                <div>
                  <img
                    src={previewImage}
                    alt="profile"
                    className="avatar-md rounded-circle img-thumbnail"
                    style={{
                      padding: '7px', // Space between border and image
                      width: '100px', // Adjust width for responsiveness
                      height: '100px', // Adjust height for responsiveness
                      border: '2px solid #0080F9',  // Blue border around the avatar
                    }}
                  />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <label
                    htmlFor="change-photo"
                    className="btn btn-outline-primary"
                    style={{ marginLeft: '15px', borderRadius: '8px', fontSize: '16px', backgroundColor: 'transparent', color: '#0080F9', border: '1px solid #0080F9', fontWeight: '500' }}
                  >
                    {imageFile ? i18next.t('Change Photo') : i18next.t('Upload Photo')}
                  </label>
                  <input
                    type="file"
                    id="change-photo"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                    disabled={!isEditMode} // Disable if not in edit mode
                  />

                  {/* Show "Remove" link if an image is uploaded */}
                  {imageFile && (
                    <a
                      href="#"
                      style={{ fontSize: '16px', color: '#000', display: 'inline-block', marginLeft: '20px', fontWeight: '500' }}
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </a>
                  )}
                </div>
              </div>

              {/* Form Section */}
              <form onSubmit={handleSubmit(editUserAccount)}>
                <div className="mb-2">
                  <label style={{ fontWeight: '600', fontSize: '16px' }}>{i18next.t('Firstname')}</label>
                </div>
                <div className="align-items-center">
                  <input
                    name="firstName"
                    value={firstName}
                    type="text"
                    id="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    className="input-style"
                    style={{ width: '415px' }}
                    disabled={!isEditMode} // Disable if not in edit mode
                  />
                  <Button className="buttonProfile" onClick={toggleEditMode}>
                    {isEditMode ? i18next.t('Save') : i18next.t('Edit')}
                  </Button>
                </div>
                <div className="mb-2">
                  <label style={{ fontWeight: '600', fontSize: '16px' }}>{i18next.t('Lastname')}</label>
                </div>
                <div className="align-items-center">
                  <input
                    name="LastName"
                    value={lastName}
                    type="text"
                    id="LastName"
                    onChange={(e) => setLastName(e.target.value)}
                    className="input-style"
                    style={{ width: '415px' }}
                    disabled={!isEditMode} // Disable if not in edit mode
                  />
                  <Button className="buttonProfile" onClick={toggleEditMode}>
                    {isEditMode ? i18next.t('Save') : i18next.t('Edit')}
                  </Button>
                </div>

                <div className="mb-2">
                  <label style={{ fontWeight: '600', fontSize: '16px' }}>{i18next.t('Email')}</label>
                </div>
                <div className="align-items-center">
                  <input
                    name="email"
                    type="text"
                    value={email}
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                    disabled={!isEditMode} // Disable if not in edit mode
                    style={{ width: '415px' }}
                    className="input-style"
                  />
                  <Button className="buttonProfile" onClick={toggleEditMode}>
                    {isEditMode ? i18next.t('Save') : i18next.t('Edit')}
                  </Button>
                </div>

                <div className="mb-2">
                  <label style={{ fontWeight: '600', fontSize: '16px' }}>{i18next.t('Phone')}</label>
                </div>
                <div className="align-items-center">
                  <select
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #344054',
                      fontSize: '14px',
                      padding: '9px',
                      marginRight: '10px',
                    }}
                    disabled={!isEditMode} // Disable if not in edit mode
                  >
                    <option value="+216">+216</option>
                    <option value="+44">+44</option>
                    <option value="+1">+1</option>
                    <option value="+86">+86</option>
                  </select>

                  <input
                    name="mobile"
                    type="tel"
                    value={mobile}
                    id="mobile"
                    onChange={(e) => setMobile(e.target.value)}
                    className="input-style"
                    style={{ width: '335px' }}
                    placeholder="99 999 999"
                    disabled={!isEditMode} // Disable if not in edit mode
                  />

                  <Button className="buttonProfile" onClick={toggleEditMode}>
                    {isEditMode ? i18next.t('Save') : i18next.t('Edit')}
                  </Button>
                </div>

                <div className="mb-4 mt-5">
                  <label style={{ fontWeight: 'bold', fontSize: '20px' }}>{i18next.t('Password')}</label>
                  <p style={{ color: '#000', fontSize: '17px', marginBottom: '10px' }}>
                    <a href="#" style={{ color: '#0080F9' }}>{i18next.t('Update Your Password Through The Button Below.')}</a> {i18next.t('You Will Be Redirected To A New Page And Must Follow The Instructions.')}
                  </p>
                  <Link to="/forgot-password" className="buttonProfile">{i18next.t('Set New Password')}</Link>
                </div>

                <div className="mb-3">
                  <label style={{ fontWeight: 'bold', fontSize: '20px' }}>{i18next.t('Notifications')}</label>
                  <div style={{ fontSize: '17px', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ color: '#0080F9' }}>{i18next.t('Receive Newsletters, Promotions And News From LiveMetrics')}</span>
                    <Switch 
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </div>
                  <p style={{ fontSize: '12px', color: '#4A4A4A' }}>
                    {i18next.t('LiveMetrics will process your data to send you information about our products and services, promotions, surveys, raffles,based on our legitimate interest, and updates from the creators you follow, if you have consented to this. Your data will not be disclosed to third parties. They will be communicated outside the EU under the terms of the privacy policy. You can opt out of our notifications with the first slider.')} <a href="#" style={{ textDecoration: 'underline', color: '#0080F9' }}>{i18next.t('More informations')}</a>
                  </p>
                </div>
              </form>
            </Card>
          </Col>
        </Row>

        <div className="mt-4">
          <p style={{ fontSize: '12px', color: '#4A4A4A' }}>
            {i18next.t('For More Information Or A Bug Report Please Contact')} <a href="mailto:support@livemetrics.io" style={{ color: '#0080F9', fontWeight: 'bold' }}>support@livemetrics.io</a>
          </p>
        </div>
      </Container>
    </React.Fragment>
  );
}

UserProfile.propTypes = {
  t: proptypes.func.isRequired,
};

export default (withTranslation()(UserProfile));
