import React from 'react';
import { Col } from 'reactstrap';
import PercentageWithCompare from './PercentageWithCompare';
import Tooltip from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/Tooltip';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const StoreOverviewCard = ({ title, data, percentage,deliveredOrders }) => {
  console.log('StoreOverviewCard:', title, data, percentage);console.log(Number.isNaN(data) );
  return (
    <Col xl={2} className="mb-4">
      <div
        className="h-100"
        style={{ border: '1px solid #D9D5EC', borderRadius: '3px' }}
      >
        <div className="p-3">
          <div className="d-flex flex-column align-items-start justify-content-between gap-3">
            <Tooltip
              displayed={
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#0044BD',
                    fontFamily: 'DM Sans, sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  {title}
                </span>
              }
              tooltip={tooltipMap[title]?.tooltip}
              formule={tooltipMap[title]?.formula}
            />
          <div className="d-flex flex-column align-items-start">
  <div className="d-flex align-items-center">
    <span
      style={{
        fontWeight: '700',
        color: '#000000',
        fontSize: '21px', // Adjust size for emphasis
        fontFamily: 'DM Sans, sans-serif',
      }}
    >
      {Number.isNaN(data) ? 0 : data}
    </span>

    {title === 'Total Orders' && (
      <span
        style={{
          fontWeight: '600',
          fontSize: '16px',
          color: '#0044BD',
          marginLeft: '10px',
          whiteSpace: 'nowrap', // Ensures no line break
        }}
      >
        {deliveredOrders} Delivered
      </span>
    )}
  </div>

  {percentage && <PercentageWithCompare percentage={percentage} />}
</div>

          </div>
        </div>
      </div>
    </Col>
  );
};

export default StoreOverviewCard;
