import React, { useState } from 'react';
import GoogleAdsChartCheckbox from './GoogleAdsChartCheckbox';
import ReactEcharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate)); 
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const GoogleAdsChart = ({ series, colors }) => {
  const [selectedSeries, setSelectedSeries] = useState(series[0].name);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const getSelectedSeriesData = (selectedSeries) => {
    const selectedSeriesData = series.find(
      (item) => item.name === selectedSeries
    );

    return selectedSeriesData ? selectedSeriesData : {};
  };

  const getOption = () => {
    const selectedData = getSelectedSeriesData(selectedSeries);

    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff',
        borderColor: colors[0].lineColor,
        borderWidth: 1,
        textStyle: {
          color: '#000',
        },
      },
      grid: {
        left: '2%',
        right: '6%',
        top: '10%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false, // Ensures that the line starts at the edge
        data:
          generateDateRange(
            dateRange.selection1.startDate,
            dateRange.selection1.endDate
          ).map((item) =>
            item.toLocaleDateString('en-US', {
              day: '2-digit',
              month: 'short', // Change format to "Sep"
            })
          ) || [],
        axisTick: { show: false },
        axisLabel: {
          color: '#000000',
          align: 'center',
          padding: [7, 0, 0, 0], // Add padding to move the labels up/down as needed
          fontSize: 14,
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 400,
        },
        axisLine: {
          lineStyle: {
            color: '#33343E',
          },
        },
      },
      yAxis: {
        type: 'value',

        axisLine: {
          lineStyle: {
            color: '#74788d',
          },
        },
        axisLabel: {
          color: '#000000',
          align: 'right',
          fontSize: 14,
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 400,
        },
      },
      series: [
        {
          name: selectedSeries,
          data: selectedData?.data.map((item) => item.toFixed(2)) || [],
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 10,
          showSymbol: false,
          itemStyle: {
            color: colors[0].lineColor, // Use primary color for the line symbol
            borderColor: '#FFFFFF',
            borderWidth: 2,
          },
          lineStyle: {
            width: 2,
            color: colors[0].lineColor, // Use primary color for the line
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: colors[0].fillColor,
            },
          },
          z: 3,
          emphasis: {
            focus: 'series', // Highlight the line when hovering
            itemStyle: {
              showSymbol: true, // Show symbol on hover
            },
          },
        },
        {
          name: `${selectedSeries} (compared)`,
          data: selectedData?.compareData.map((item) => item.toFixed(2)) ?? [],
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 10,
          showSymbol: false,
          itemStyle: {
            color: colors[1].lineColor, // Color for comparison line
            borderColor: '#FFFFFF',
            borderWidth: 2,
          },
          lineStyle: {
            width: 2,
            color: colors[1].lineColor, // Comparison line color
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: colors[1].fillColor,
            },
          },
          z: 1,

          emphasis: {
            focus: 'series',
            itemStyle: {
              showSymbol: true,
            },
          },
        },
      ],
    };
  };

  return (
    <>
      <div className="checkbox-group mt-4" style={{ display: 'flex' }}>
        {series.map((item) => (
          <GoogleAdsChartCheckbox
            key={item.name}
            selectedSeries={selectedSeries}
            setSelectedSeries={setSelectedSeries}
            value={item.value}
            id={item.name}
          />
        ))}
      </div>

      <div>
        <ReactEcharts
          option={getOption()}
          style={{ height: '400px', width: '100%' }}
        />
      </div>
    </>
  );
};

export default GoogleAdsChart;
