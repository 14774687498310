import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { REFRESH_TOKEN } from '../../queries/LoginQueries';
import { checkExpiration } from '../../queries/Users/userQuery';
import urls from '../apiUrls';
import { logoutRequest } from '../../store/actions';
import { jwtDecode } from 'jwt-decode';

const getToken = () => localStorage.getItem('token');

const loginClient = new ApolloClient({
  link: new HttpLink({
    uri: urls.login,
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
});

const userClient = new ApolloClient({
  link: new HttpLink({
    uri: urls.user,
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
});

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Date.now() / 1000;
  } catch {
    return true; // Assume expired if decoding fails
  }
};

const AuthExpirationMiddleware = ({ element, layout: Layout, isAuthProtected }) => {
  const [loading, setLoading] = useState(true);
  const [planExpired, setPlanExpired] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    console.log("Logging out...");
    dispatch(logoutRequest());
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    let isMounted = true;

    const authenticateUser = async () => {
      try {
        const token = getToken();

        if (!token || isTokenExpired(token)) {
          console.log("Token expired, attempting refresh...");
          const res = await loginClient.query({
            query: REFRESH_TOKEN,
            context: { credentials: 'include' },
            errorPolicy: 'all',
          });

          if (res.data?.refreshToken?.accessToken) {
            localStorage.setItem('token', res.data.refreshToken.accessToken);
            if (isMounted) {
              setIsAuthenticated(true);
            }
          } else {
            throw new Error('Token refresh failed');
          }
        } else {
          if (isMounted) setIsAuthenticated(true);
        }

        // ✅ Check subscription status
        const res = await userClient.query({
          query: checkExpiration,
          errorPolicy: 'all',
        });

        if (isMounted) setPlanExpired(res.data?.checkExpiration || false);
      } catch (error) {
        console.error('Authentication error:', error);
        if (isMounted) {
          handleLogout();
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    authenticateUser();

    return () => {
      isMounted = false;
    };
  }, []);

  // ✅ Prevent infinite loops
  if (loading) return null;
  if (isAuthenticated === false && isAuthProtected) {
    console.log("Redirecting to login...");
    return <Navigate to="/login" replace />;
  }
  if (planExpired) {
    console.log("Subscription expired, redirecting to settings...");
    return <Navigate to="/settings" replace />;
  }

  return <Layout>{element}</Layout>;
};

AuthExpirationMiddleware.propTypes = {
  element: PropTypes.node.isRequired,
  layout: PropTypes.elementType.isRequired,
  isAuthProtected: PropTypes.bool,
};

export default AuthExpirationMiddleware;
