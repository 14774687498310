import chargesIcon from "../../assets/images/icons/charges_icon_aramex.svg";
import deliveredIcon from "../../assets/images/icons/delivered_icon_aramex.svg";
import dangerIcon from "../../assets/images/icons/danger_icon_aramex.svg";
import stopIcon from "../../assets/images/icons/stop_icon_aramex.svg";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIntegrationRequest,
  fetchDataRequest,
} from "../../store/aramexState/action";
import AramexInsights from "./insights/AramexInsights";
import AramexTable from "./AramexTable";

const Index = () => {
  const dispatch = useDispatch();
  const {
    trackingNStatus,
    ShipmentChargesPaid,
    SupportingDocumentReturnedtoShipper,
    ReturnedtoShipper,
    Delivered,
    checkIntegrationdata,
    isLoading,
  } = useSelector((state) => state.aramex);
  useEffect(() => {
    dispatch(checkIntegrationRequest());
  }, [dispatch]);
  useEffect(() => {
    if (checkIntegrationdata === "active") dispatch(fetchDataRequest());
  }, [dispatch, checkIntegrationdata]);
  const data = [
    {
      title: "Delivered",
      value: Delivered,
      iconPath: deliveredIcon,
      color: "#007F00",
      fillColor: "#CDFFCD",
    },
    {
      title: "shipment charges paid",
      value: ShipmentChargesPaid,
      iconPath: chargesIcon,
      color: "#0064E0",
      fillColor: "#B4DAFF",
    },
    {
      title: "Supporting document returned to shipper",
      value: SupportingDocumentReturnedtoShipper,
      iconPath: dangerIcon,
      color: "#FFB300",
      fillColor: "#FFF7CD",
    },
    {
      title: "Returned to shipper",
      value: ReturnedtoShipper,
      iconPath: stopIcon,
      color: "#D30000",
      fillColor: "#FFE0E0",
    },
  ];

  return (
    <div className="page-content">
      <AramexTable data={trackingNStatus} isLoading={isLoading} />

      <AramexInsights data={data} />
    </div>
  );
};

export default Index;
