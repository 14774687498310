const integrations = {
  "Google Ads": 3,
  "Facebook Ads": 2,
  "Google Analytics": 4,
  "Facebook Social Media": 10,
  "Instagram Social Media": 11,
  "Magento": 12,
  "Aramex": 9,
  "PrestaShop": 6,
};

export default integrations;
