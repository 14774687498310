import{
    GET_OVERVIEW_REPORT,
    GET_OVERVIEW_REPORT_SUCCESS,
    GET_OVERVIEW_REPORT_FAILURE,
    GET_ACTIVE_ACCOUNTS_REQUEST,
    GET_ACTIVE_ACCOUNTS_SUCCESS,
    GET_ACTIVE_ACCOUNTS_FAILRE,
} from './action';

const initialState = {
data : [],
activeAccounts:[],
loading: false,
activeAccountsLoading: false,
error: null,

}

export default function overview(state = initialState, action) {

    switch (action.type) {
        case GET_OVERVIEW_REPORT:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_OVERVIEW_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            };
        case GET_OVERVIEW_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_ACTIVE_ACCOUNTS_REQUEST:
            return {
                ...state,
                activeAccountsLoading: true,
                error: null,
            };
        case GET_ACTIVE_ACCOUNTS_SUCCESS:
            return {
                ...state,
                activeAccountsLoading: false,
                activeAccounts: action.payload.data,
            };
        case GET_ACTIVE_ACCOUNTS_FAILRE:
            return {
                ...state,
                activeAccountsLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}