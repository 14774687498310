import {
  DELETE_INTEGRATION_MAGENTO_FAILURE,
  INTEGRATION_MAGENTO_FAILURE,
  INTEGRATION_MAGENTO_REQUEST,
  DELETE_INTEGRATION_MAGENTO_REQUEST,
  DELETE_INTEGRATION_MAGENTO_SUCCESS,
  INTEGRATION_MAGENTO_SUCCESS,
  CHECK_THIRD_STEP_MAGENTO_REQUEST,
  CHECK_THIRD_STEP_MAGENTO_SUCCESS,
  CHECK_THIRD_STEP_MAGENTO_FAILURE,
  FETCH_MAGENTO_STORE_REQUEST,
  FETCH_MAGENTO_STORE_SUCCESS,
  FETCH_MAGENTO_STORE_FAILURE,
  GET_TOTAL_PRODUCTS_COUNT_REQUEST,
  GET_TOTAL_PRODUCTS_COUNT_SUCCESS,
  GET_TOTAL_PRODUCTS_COUNT_FAILURE,
  FETCH_MAGENTO_PRODUCTS_REQUEST,
  FETCH_MAGENTO_PRODUCTS_SUCCESS,
  FETCH_MAGENTO_PRODUCTS_FAILURE,
  FETCH_MAGENTO_ORDERS_REQUEST,
  FETCH_MAGENTO_ORDERS_SUCCESS,
  FETCH_MAGENTO_ORDERS_FAILURE,
  FETCH_MAGENTO_BEST_PRODUCTS_REQUEST,
  FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS,
  FETCH_MAGENTO_BEST_PRODUCTS_FAILURE,
} from './actionsTypes';

const initialState = {
  error: null,
  firststore: null,
  stores: [],
  step: null,
  reachedFinalStep: false,
  productsCount: 0,
  getProductsLoading: false,
  products: [],
  orders: [],
  bestSellers: [],
};

const magentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAGENTO_STORE_REQUEST:
      return {
        ...state,
      };
    case FETCH_MAGENTO_STORE_SUCCESS:
      return {
        ...state,
        step: 2,
        reachedFinalStep: true,
        firststore: action.payload,
      };
    case FETCH_MAGENTO_STORE_FAILURE:
      return {
        ...state,
        error: action.payload,
        reachedFinalStep: false,
      };

    case DELETE_INTEGRATION_MAGENTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_INTEGRATION_MAGENTO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        step: 1,
      };
    case DELETE_INTEGRATION_MAGENTO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case INTEGRATION_MAGENTO_REQUEST:
      return {
        ...state,
        error: null,
        intergrationLoading: true,
      };
    case INTEGRATION_MAGENTO_SUCCESS:
      return {
        ...state,
        Store: action.payload,
        step: 2,
        reachedFinalStep: true,
        intergrationLoading: false,
      };
    case INTEGRATION_MAGENTO_FAILURE:
      return {
        ...state,
        error: action.payload,
        intergrationLoading: false,
      };

    case CHECK_THIRD_STEP_MAGENTO_REQUEST:
      return {
        ...state,
      };
    case CHECK_THIRD_STEP_MAGENTO_SUCCESS:
      return {
        ...state,
        step: 3,
        reachedFinalStep: true,
      };
    case CHECK_THIRD_STEP_MAGENTO_FAILURE:
      return {
        ...state,
        error: action.payload,
        reachedFinalStep: false,
      };

    case GET_TOTAL_PRODUCTS_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TOTAL_PRODUCTS_COUNT_SUCCESS:
      return {
        ...state,
        productsCount: action.payload,
        loading: false,
      };
    case GET_TOTAL_PRODUCTS_COUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_MAGENTO_PRODUCTS_REQUEST:
      return {
        ...state,
        getProductsLoading: true,
      };
    case FETCH_MAGENTO_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        getProductsLoading: false,
      };
    case FETCH_MAGENTO_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        getProductsLoading: false,
      };
    case FETCH_MAGENTO_ORDERS_REQUEST:
      return {
        ...state,
        getProductsLoading: true,
      };
    case FETCH_MAGENTO_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        getProductsLoading: false,
      };
    case FETCH_MAGENTO_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        getProductsLoading: false,
      };
    case FETCH_MAGENTO_BEST_PRODUCTS_REQUEST:
      return {
        ...state,
      };
    case FETCH_MAGENTO_BEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        bestSellers: action.payload,
      };
    case FETCH_MAGENTO_BEST_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magentoReducer;
